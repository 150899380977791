import { FreeTaxActionTypes } from './actionType';
import * as API from '../../../api/freeTaxApi'
import { fetchDocuSignURL } from '../../../api/servicesAPI';
import { DOC_SIGNING_REDIRECT_URI } from '../../../config';

export const getChangePasswordStatus = (request) => dispatch => dispatch({
    type: FreeTaxActionTypes.IS_CHANGE_PASSWORD,
    payload: API.getChangePasswordStatus(request)
        .then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const getPhoneVerifiedStatus = (request) => dispatch => dispatch({
    type: FreeTaxActionTypes.IS_CHANGE_PASSWORD,
    payload: API.getPhoneVerifiedStatus(request)
        .then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const form8821Status = (request) => dispatch => dispatch({
    type: FreeTaxActionTypes.IS_CHANGE_PASSWORD,
    payload: API.form8821Status(request)
        .then(response => {
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const form8821EnvelopeId = (request) => dispatch => dispatch({    
    type: FreeTaxActionTypes.GET_8821_FORM_ENVELOPE_ID,
    payload: API.form8821EnvelopeId(request)
        .then(response => {
            if (response.data.result === "Success" || response.data.result === "Success!") {
                if (response.data.envelopeid) {
                    let request = {
                        envid: response.data.envelopeid,
                        returnurl: `${DOC_SIGNING_REDIRECT_URI()}/home`
                    }
                    fetchDocuSignURL(request)
                        .then(res => {
                            let url = res.data.url.trim();
                            window.location.replace(url)
                            // const win = window.open(url, 'blank');
                            // win.focus();
                        }).catch(error => {
                            console.log(error);
                        });
                }
            }
            console.log("line 60",response.data);
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})
