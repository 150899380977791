import jwtDecode from 'jwt-decode';

export const PASSWORD_MIN_LEN = 6;
export const URL_REGEXP = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
export const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
// export const PhNoPattern = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const URL = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
export const PhNoPattern = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;


export const routes = {
    ROOT: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/reset_password',
    HOME: '/home',
    LOGOUT: '/logout',
    DOCUMENTS: '/documents',
    BILLING: '/billing',
    CONTACT_US: '/contact_us',
    NOTIFICATIONS: '/notifications',
    DASHBOARD: '/dashboard',
    MYACCOUNT: "/my_account",
    SERVICE_DETAIL: "/service/:id",

    CHANGE_PASSWORD: "#change-password",
    BILLING_INFORMATION: "#billing-information",
    BUSINESS_INFORMATION: "#business-information",
    PERSONAL_INFORMATION: "#personal-information",
    PROFILE: '#profile',
    VERIFY_MOBILE: '#verify-mobile',
    VERIFY_OLD_MOBILE: '#verify-old-mobile',
    PHNO_NOT_LINKED: '#phno-not-linked',
    VERIFY_OTP: '#verify-otp',
    VALIDATE_ACCOUNT: '#validate-account',
    SUBMIT_TICKET: '#submit-ticket',
    TICKET_SUBMITTED: '#ticket-submitted',
    CHAT: "#chat",
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',

    ADD_PASSWORD: '/add-password',
    MOBILE_VERIFICATION: '/mobile-verification'

};

export const APIType = {
    AvailableServices: "AvailableServices",
    OptServiceStatus: "OptServiceStatus",
    MyServices: "MyServices",
    OptService: "OptService",
    ReviewSign: "ReviewSign",
    DocRequest: "DocRequest",
    PayRequest: "PayRequest",
    VerificationRequest: "VerificationRequest",
    ConcernPerson: "ConcernPerson"
}


export const address_attributes = {
    city: '',
    state: '',
    zip: '',
    country: '',
    latitude: '',
    longitude: '',
    formatted_address: ''
}

export const isTokenValid = (token) => {
    if (token) {
        const data = jwtDecode(token);
        let Valid = true;
        if (new Date() < new Date(parseInt(data.exp * 1000))) {
            Valid = true;
        } else {
            Valid = false
        }
        return Valid;
    }
    else {
        return false
    }
}


export const UserRoles = {
    user: "user",
    service_provider: "service_provider",
    admin: "admin"
};

export const HomeTabs = {
    PERSONAL_SERVICES: 'personal_services',
    BUSINESS_SERVICES: 'business_services',
    GET_PERSONAL_FINANCE_HELP: 'get_personal_finance_help',
    FIND_BUSINESS_SOLUTION: 'find_business_solution',
    AWESOME_TOOLS: 'awesome_tools'
}


export const ServiceAction = {
    signDoc: {
        text: "signDoc",
        title: "Sign and review Engagement Contract",
        buttonTitle: "Open and Sign",
        icon: "/images/customs/icon_sign.png",
        type: APIType.ReviewSign,
    },
    pay: {
        text: "pay",
        title: "Pay Enrollment Fee: Payment Due",
        buttonTitle: "Make Payment",
        icon: "/images/customs/icon_card.png",
        type: APIType.PayRequest,
    },
    providerInfo: {
        text: "providerInfo",
        title: "Review Financial Profile",
        buttonTitle: "Profile info",
        icon: "/images/customs/icon_profile.png",
        type: APIType.VerificationRequest,
    },
    upload: {
        text: "upload",
        title: "Upload Bank Statements",
        buttonTitle: "Upload Document",
        icon: "/images/icons/icn_down_white.png",
        type: APIType.DocRequest,
    },
    Announcement: {
        text: "Announcement",
        title: "Announcement - Account Update",
        buttonTitle: "Click Here",
        icon: "/images/customs/icon_profile.png",
    }
}

export const dateFormat = (date, formatType) => {
    let year = date.getFullYear()
    let month = (1 + date.getMonth()).toString()
    month = month.length > 1 ? month : '0' + month
    let day = date.getDate().toString()
    day = day.length > 1 ? day : '0' + day
    console.log(month + '/' + day + '/' + year)
    if (formatType === 'YYYY-MM-DD') {
        return year + '-' + month + '-' + day
    }
    if (formatType === 'MMYYYY') {
        return month + year
    }
    if (formatType === 'MM-DD-YYYY') {
        return month + '/' + day + '/' + year
    }
    else {
        return month + '/' + day + '/' + year
    }
}