import React from 'react'
import { Form, Field, Formik } from 'formik'
import { mobile_number_validate, country_code_validate } from '../../FormValidation/FormValidation'
import Spinner from '../../UI/Spinner/Spinner'
import CountryCodes from '../../UI/CountryCodes/CountryCodes'
import { routes } from '../../../utility/constants/constants'
import Oux from '../../../hoc/Oux/Oux'
import { connect } from 'react-redux'
import * as actionTypes from '../../../redux/actions/index'
import storage from '../../../utility/storage'

const phoneVerification = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    let currentUrl = `${props.history.location.pathname}${props.history.location.hash}`

    console.log('phoneapi', props.apiError)

    let apiErrorMsg = null
    if (props.apiError) {
        apiErrorMsg = props.apiError.message ? props.apiError.message : props.apiError
    }
    let form = <Oux>
        <div class="clearfix"></div>
        <div className="container-login100 no_hero_full">
            <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                <div className="theme_form_outer">
                    <article className="theme_login_txt">
                        {
                            currentUrl === routes.REGISTER + routes.VERIFY_MOBILE
                                ? <h1>Enter Phone Number</h1>
                                : null
                        }
                        {
                            currentUrl === routes.REGISTER + routes.VERIFY_OLD_MOBILE
                                ? <h1>Enter Old Phone Number</h1>
                                : null
                        }
                        {
                            props.isFreeTaxRedirected
                                ? <h1>Verify Your Number</h1>
                                : null
                        }
                        {
                            props.isFreeTaxRedirected
                                ? null
                                : <p>Your phone number will help locate an existing account in our database.</p>
                        }
                    </article>
                    <Formik
                        enableReinitialize={true}
                        initialValues={props.mobile_number && props.country_code ? { mobile_number: props.mobile_number, country_code: props.country_code } : props.isFreeTaxRedirected ? { mobile_number: props.mobile_phone_number, country_code: props.country_dial_code } : {}}
                        onSubmit={(values) => {
                            let phNo = values.mobile_number.replace(/-/g, '')
                            values = {
                                ...values,
                                mobile_number: phNo
                            }
                            // props.setMobileFormData(values)
                            // props.sendOTP({ phoneno: values.country_code+values.mobile_number, type: 'SendSMS' }, values)
                            if (props.isFreeTaxRedirected) {
                                props.sendOTP({ phoneno: "+" + values.country_code + values.mobile_number, type: 'SendSMS' }, values)
                                    .then(response => {
                                        if (response.value.result === "Success!" || response.value.result === "Success") {
                                            storage.set('isOTPSent', true)
                                            props.history.push(routes.HOME, { country_code: values.country_code })
                                        } else {
                                            //error sending otp
                                        }
                                    })
                            }
                            else {
                                props.checkUser({ type: 'CheckOld', phone: values.mobile_number, email: props.history.location.state.registerFormData.email }, values)
                            }
                        }}>
                        {(formik_props) => {
                            const errors = formik_props.errors;
                            const touched = formik_props.touched;
                            console.log(errors)
                            console.log(!formik_props.dirty)
                            console.log(!formik_props.isValid)
                            return (
                                <Form className="theme_form" action="signup3.html">
                                    <div className="form-group">
                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.country_code ? errors.country_code : null}</span>
                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{apiErrorMsg === 'Invalid parameter: To' ? 'Mismatch in country code & phone number.' : apiErrorMsg}</span>
                                        {/* <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.history.location.state.sendOTPError && props.history.location.state.sendOTPError.invalidParameterTo ? 'Country Code Mismatching Phone Number' : null}</span> */}
                                        <Field name="country_code" validate={country_code_validate} component={CountryCodes} {...props} />
                                        <br />
                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.mobile_number && touched.mobile_number ? errors.mobile_number : null}</span>
                                        <Field type="text" disabled={props.isFreeTaxRedirected} validate={mobile_number_validate} name="mobile_number" style={errors.mobile_number && touched.mobile_number ? errorStyle : null} className="form-control theme_input" placeholder="Mobile Phone Number" />
                                    </div>
                                    {
                                        props.isFreeTaxRedirected && props.isLoading
                                            ? <Spinner />
                                            : <button disabled={!formik_props.values.country_code && !formik_props.values.mobile_number} type="submit" className="btn btn-primary btn-block btn_submit globel_btn">Continue</button>
                                    }
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    </Oux>
    if (props.isLoading && !props.isFreeTaxRedirected) {
        form = <Oux>
            <div class="clearfix"></div>
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <article className="theme_login_txt">
                            <p>We are searching for your account. <br /><br /> After locating your account, we will <br />automatically send an SMS messaging containing your secure login code.</p>
                            <Spinner />
                        </article>
                    </div>
                </div>
            </div>
        </Oux>
    }
    return form
}

const mapState = state => {
    return {
        mobile_number: state.authReducer.mobile_number,
        country_code: state.authReducer.country_code
    }
}

const mapDispatch = dispatch => {
    return {
        setMobileFormData: (values) => dispatch(actionTypes.setMobileFormData(values))
    }
}

export default connect(mapState, mapDispatch)(phoneVerification)