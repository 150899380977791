
import { ServiceActionType } from './actionType';
import * as API from '../../../api/servicesAPI';
import { toastMsg } from '../../../utility/utility';

export const fetchAvailableServices = (request) => dispatch => dispatch({
    type: ServiceActionType.FETCH_ALLSERVICE,
    payload: API.servicesApi(request)
        .then(response => {
            if (response.data.error) {
                return response.data
            } else {
                return response.data.data ? response.data.data : [];
            }
        })
        .catch(error => {
            if (error && error === "Network Error") {
                return { error: error };
            }
            console.log(error);
            return error;
        })
});


export const fetchMyServices = (request) => dispatch => dispatch({
    type: ServiceActionType.FETCH_MYSERVICE,
    payload: API.servicesApi(request)
        .then(response => {
            if (response.data.error) {
                return response.data
            } else {
                return response.data.data;
            }
        })
        .catch(error => {
            if (error && error === "Network Error") {
                return { error: error };
            }
            console.log(error);
            return error;
        })
});