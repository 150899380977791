import React from 'react'
import { Field, Form, Formik } from 'formik'
import Spinner from '../../UI/Spinner/Spinner'
import { withRouter } from 'react-router'
import Oux from '../../../hoc/Oux/Oux'
import { routes } from '../../../utility/constants/constants'
import { connect } from 'react-redux'
import * as actionTypes from '../../../redux/actions/index'

const phoneNumNotLinked = props => {

    let validateAccountSSNError = null
    let validateAccountDOBError = null

    if(props.history.location.state.validateAccountErrors)
    {
        if(props.history.location.state.validateAccountErrors.ssn)
        {
            validateAccountSSNError = props.history.location.state.validateAccountErrors.ssn
        }
        if(props.history.location.state.validateAccountErrors.dob)
        {
            validateAccountDOBError = props.history.location.state.validateAccountErrors.dob
        }
    }

    return (
        <Oux>
            <div class="clearfix"></div>
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">

                        {
                            props.history.location.state.prevPath === routes.REGISTER + routes.VERIFY_OLD_MOBILE && props.history.location.state.apiResponse.error !== "User mobile already exists!"
                                ? <article style={{ textAlign: 'center' }} className="theme_login_txt">
                                    <img src="/images/customs/exclaimation_danger.png" width="40%" height="40%" alt="" />
                                    <br />
                                    <br />
                                    <p className="text-danger">We couldn't find your account <br /> in our system!</p>
                                    <p>Please try again or contact us to get your <br /> account verified.</p>
                                </article>
                                : props.history.location.state.prevPath === routes.REGISTER + routes.VALIDATE_ACCOUNT
                                    ? <article className="theme_login_txt">
                                        <p className="text-danger text-center">{validateAccountSSNError ? validateAccountSSNError : null}</p>
                                        <p className="text-danger text-center">{validateAccountDOBError ? validateAccountDOBError : null}</p>
                                        <p>Please select one of the following to proceed with the sign up process</p>
                                    </article>
                                    : props.history.location.state.apiResponse.error === "User already exist!" || props.history.location.state.apiResponse.error === "User already exist" || props.history.location.state.apiResponse.error === "User mobile already exists!" || props.history.location.state.apiResponse.error === "User mobile already exists"
                                        ? <article className="theme_login_txt">
                                            <p className="text-danger text-center">User Mobile Already Exist!</p>
                                            <p>Please select one of the following to proceed with the sign up process</p>
                                        </article>
                                        : <article className="theme_login_txt">
                                            <p className="text-danger">We are unable to locate your phone number in an existing account.</p>
                                            <p>Please select one of the following to proceed with the sign up process</p>
                                        </article>
                        }

                        {
                            props.history.location.state.prevPath === routes.REGISTER + routes.VALIDATE_ACCOUNT || props.history.location.state.prevPath === routes.REGISTER + routes.VERIFY_OLD_MOBILE || props.history.location.state.apiResponse.error === "User already exist!" || props.history.location.state.apiResponse.error === "User already exist" || props.history.location.state.apiResponse.error === "User mobile already exists!" || props.history.location.state.apiResponse.error === "User mobile already exists"
                                ? <article style={{ textAlign: 'center' }} className="theme_login_txt">
                                    <a href="javascript:void(0)" onClick={() => {
                                        if (props.history.location.state.prevPath === routes.REGISTER + routes.VERIFY_OLD_MOBILE) {
                                            props.history.push(routes.REGISTER + routes.VERIFY_OLD_MOBILE, { ...props.history.location.state })
                                        }
                                        else if (props.history.location.state.prevPath === routes.REGISTER + routes.VALIDATE_ACCOUNT) {
                                            props.history.push(routes.REGISTER + routes.VALIDATE_ACCOUNT, { ...props.history.location.state })
                                        }
                                        else {
                                            props.history.push(routes.REGISTER + routes.VERIFY_MOBILE, { ...props.history.location.state })
                                        }
                                    }
                                    }>TRY AGAIN</a>
                                    <br />
                                    <br />
                                    ...
                                    <br />
                                    <br />
                                    <a href="javascript:void(0)" onClick={() => props.history.push(routes.REGISTER + routes.SUBMIT_TICKET, { ...props.history.location.state })}>SUBMIT A TICKET</a>
                                </article>
                                : <Formik
                                    initialValues={{}}
                                    onSubmit={(values) => props.onSubmitPhoneNumberNotLinked(values)}>
                                    {(formik_props) => {
                                        const errors = formik_props.errors;
                                        return (
                                            <Form className="theme_form" action="signup5.html">
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <Field
                                                            type="checkbox"
                                                            id="checkbox1"
                                                            onChange={(event) => formik_props.setFieldValue("checkbox1", event.target.checked)}
                                                            className="custom-control-input"
                                                            disabled={formik_props.values.checkbox2 ? true : false}
                                                            checked={formik_props.values.checkbox1} />
                                                        <label className={errors.checkbox1 ? "custom-control-label checkbox-error-class" : "custom-control-label"} htmlFor="checkbox1">I already have an account that might be linked to an old phone number</label>
                                                        <br /><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.checkbox1 && errors.checkbox1}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <Field
                                                            type="checkbox"
                                                            id="checkbox2" onChange={(event) => formik_props.setFieldValue("checkbox2", event.target.checked)}
                                                            className="custom-control-input"
                                                            disabled={formik_props.values.checkbox1 ? true : false}
                                                            checked={formik_props.values.checkbox2} />
                                                        <label className={errors.checkbox2 ? "custom-control-label checkbox-error-class" : "custom-control-label"} htmlFor="checkbox2">I have never spoken to a representative</label>
                                                        <br /><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.checkbox2 && errors.checkbox2}</span>
                                                    </div>
                                                </div>
                                                {props.isLoading || props.isApiLoading ? <Spinner width='25px' height='25px' margin='0 auto' /> : <button type="submit" disabled={!formik_props.values.checkbox1 && !formik_props.values.checkbox2} className="btn btn-primary btn-block btn_submit globel_btn">Continue</button>}
                                            </Form>
                                        )
                                    }}
                                </Formik>
                        }


                    </div>
                </div>
            </div>
        </Oux>
    )
}

const mapState = state => {
    return {
        mobile_number: state.authReducer.mobile_number,
        country_code: state.authReducer.country_code
    }
}

const mapDispatch = dispatch => {
    return {
        resetMobileFormData: (values) => dispatch(actionTypes.resetMobileFormData(values))
    }
}

export default connect(mapState, mapDispatch)(withRouter(phoneNumNotLinked))


// Form Rendering Array Test

{/* {props.values.num_not_linked_checkbox.map((checkbox, index) => {
    return (
        <div key={index} className="form-group">
            <div className="custom-control custom-checkbox">
                <Field
                    type="checkbox"
                    onClick={() => {
                        props.values.num_not_linked_checkbox[index].value = !props.values.num_not_linked_checkbox[index].value
                    }}
                    id={checkbox.name}
                    className="custom-control-input"
                    checked={checkbox.value}
                    value={checkbox.value} />
                <label className={errors.num_not_linked_checkbox ? "custom-control-label checkbox-error-class" : "custom-control-label"} htmlFor={checkbox.name}>{checkbox.label}</label>
                <br /><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.num_not_linked_checkbox && errors.num_not_linked_checkbox}</span>
            </div>
        </div>
    )
})
} */}