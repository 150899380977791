import React, { useState } from 'react'
import { Form, Field, Formik } from 'formik'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Spinner from '../../UI/Spinner/Spinner'
import './ValidateAccount.css'
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import CryptoJS from 'crypto-js'
import { dateFormat, routes } from '../../../utility/constants/constants';
import { accountValidationForm } from '../../FormValidation/FormValidation';
import $ from 'jquery'
import Oux from '../../../hoc/Oux/Oux'


const validateAccount = props => {

    let ssn_error = null
    let dob_error = null

    const formDataValues = { ...props.history.location.state.registerFormData, ...props.history.location.state.mobileFormData }

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    const onAccountValidate = (values) => {
        let errors = {}
        if (routerState.ssn && routerState.dob) {
            if (!values.ssn_number) {
                errors.ssn_number = "This Field Is Required"
                ssn_error = null
            }
            else if (values.ssn_number) {
                values.ssn_number = values.ssn_number.replace(/-/g, '')
                if (!values.ssn_number.match(/^[0-9-\s]+$/)) {
                    errors.ssn_number = "Please Enter Valid 9 Digit SSN Number"
                    ssn_error = null
                }
                else if (values.ssn_number.length > 9 || values.ssn_number.length < 9) {
                    ssn_error = null
                    errors.ssn_number = "SSN Should be of 9 Digits"
                }
            }
            if (!values.date_of_birth) {
                dob_error = null
                errors.date_of_birth = "This Field Is Required"
            }
            else{
                dob_error = null
            }
        }
        else if (routerState.ssn) {
            if (!values.ssn_number) {
                ssn_error = null
                errors.ssn_number = "This Field Is Required"
            }
            else if (values.ssn_number) {
                values.ssn_number = values.ssn_number.replace(/-/g, '')
                if (!values.ssn_number.match(/^[0-9-\s]+$/)) {
                    ssn_error = null
                    errors.ssn_number = "Please Enter Valid 9 Digit SSN Number"
                }
                else if (values.ssn_number.length > 9 || values.ssn_number.length < 9) {
                    ssn_error = null
                    errors.ssn_number = "SSN Should be of 9 Digits"
                }
            }
        }
        else if (routerState.dob && !values.date_of_birth) {
            errors.date_of_birth = "This Field Is Required"
            dob_error = null
        }
        return errors
    }

    let initialFormValues = {}

    let routerState = props.history.location.state.apiResponse ? props.history.location.state.apiResponse : null
    let routerStateValidationErrors = props.history.location.state.validateAccountErrors ? props.history.location.state.validateAccountErrors : null
    let routerStateValidationErrorsValue = props.history.location.state.validateAccountErrorsValue ? props.history.location.state.validateAccountErrorsValue : null

    if (routerStateValidationErrors && routerStateValidationErrors.ssn) {
        ssn_error = routerStateValidationErrors.ssn
    }
    if (routerStateValidationErrors && routerStateValidationErrors.dob) {
        dob_error = routerStateValidationErrors.dob
    }

    if (routerState.ssn && routerState.dob) {
        if (routerStateValidationErrorsValue && routerStateValidationErrorsValue.ssn && routerStateValidationErrorsValue.dob) {
            initialFormValues = {
                ssn_number: routerStateValidationErrorsValue.ssn,
                date_of_birth: routerStateValidationErrorsValue.dob
            }
        }
        else {
            initialFormValues = {
                ssn_number: null,
                date_of_birth: new Date()
            }
        }

    }
    else if (routerState.ssn && !routerState.dob) {
        if (routerStateValidationErrorsValue && routerStateValidationErrorsValue.ssn) {
            initialFormValues = {
                ssn_number: routerStateValidationErrorsValue.ssn,
            }
        }
        else {
            initialFormValues = {
                ssn_number: null
            }
        }
    }
    else if (!routerState.ssn && routerState.dob) {
        if (routerStateValidationErrorsValue && routerStateValidationErrorsValue.dob) {
            initialFormValues = {
                date_of_birth: routerStateValidationErrorsValue.dob,
            }
        }
        else {
            initialFormValues = {
                date_of_birth: new Date()
            }
        }
    }

    return (
        <Oux>
            <div class="clearfix"></div>
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <article className="theme_login_txt">
                            <h1>Validate Account</h1>
                            {
                                routerState && routerState.ssn || routerState.dob
                                    ? <p>Your account requires additional verification.</p>
                                    : <div>
                                        <p style={{ color: 'rgb(221, 39, 38)' }}>We could not locate your Social Security Number in our system.</p>
                                        <p>Please try again or contact us to get your account verified.</p>
                                    </div>
                            }
                        </article>
                        <Formik
                            initialValues={initialFormValues}
                            validate={(values) => onAccountValidate(values)}
                            onSubmit={(values, { setFieldError }) => {
                                let encrypted_ssn = null
                                let encrypted_dob = null
                                if (values.ssn_number) {
                                    values.ssn_number = values.ssn_number.replace(/-/g, '')
                                    values.ssn_number = values.ssn_number.substring(0, 3) + "-" + values.ssn_number.substring(3, 5) + "-" + values.ssn_number.substring(5, values.ssn_number.length)
                                    encrypted_ssn = CryptoJS.HmacSHA1(values.ssn_number, '4b5a01355c7144e6a47296faefb0b658');
                                    console.log("encrypted SSN", encrypted_ssn.toString());
                                    if (encrypted_ssn.toString() !== routerState.ssn) {
                                        // ssn_error = 'Social Security Number Is Invalid'
                                        // setErrors({ssn_number:"Social Security Number Is Invalid"})
                                        setFieldError('ssn_number', "Social Security Number Is Invalid")
                                        values.ssn_number = values.ssn_number.replace(/-/g, '')
                                    }
                                }
                                if (values.date_of_birth) {
                                    let formatted_date = dateFormat(values.date_of_birth, 'YYYY-MM-DD')
                                    encrypted_dob = CryptoJS.HmacSHA1(formatted_date, '4b5a01355c7144e6a47296faefb0b658');
                                    console.log("encrypted DOB", encrypted_dob.toString(), 'router dob', routerState.dob);
                                    if (encrypted_dob.toString() !== routerState.dob) {
                                        // dob_error = 'Date Of Birth Is Invalid'
                                        setFieldError('date_of_birth', 'Date Of Birth Is Invalid')
                                    }
                                }
                                if((encrypted_ssn && encrypted_ssn.toString() !== routerState.ssn) || (encrypted_dob && encrypted_dob.toString() !== routerState.dob)){
                                    props.history.push(routes.REGISTER+routes.PHNO_NOT_LINKED, {
                                        ...props.history.location.state,
                                        prevPath: routes.REGISTER+routes.VALIDATE_ACCOUNT,
                                        validateAccountErrors: {
                                            ssn: encrypted_ssn && encrypted_ssn.toString() !== routerState.ssn ? "Social Security Number Is Invalid" : null,
                                            dob: encrypted_dob && encrypted_dob.toString() !== routerState.dob ? "Date Of Birth Is Invalid" : null
                                        },
                                        validateAccountErrorsValue: {
                                            ssn: values.ssn_number,
                                            dob: values.date_of_birth
                                        }
                                    })
                                }
                                else{
                                    if (routerState.ssn && routerState.dob) {
                                        if (encrypted_ssn.toString() === routerState.ssn && encrypted_dob.toString() === routerState.dob) {
                                            props.signUpUser({
                                                phone: formDataValues.mobile_number,
                                                caseid: routerState.caseid,
                                                passcode: '123456',
                                                email: formDataValues.email,
                                                password: formDataValues.password,
                                                type: 'MobileAccount'
                                            })
                                        }
                                    }
                                    else if (routerState.ssn) {
                                        if (encrypted_ssn.toString() === routerState.ssn) {
                                            props.signUpUser({
                                                phone: formDataValues.mobile_number,
                                                caseid: routerState.caseid,
                                                passcode: '123456',
                                                email: formDataValues.email,
                                                password: formDataValues.password,
                                                type: 'MobileAccount'
                                            })
                                        }
                                    }
                                    else if (routerState.dob) {
                                        if (encrypted_dob.toString() === routerState.dob) {
                                            props.signUpUser({
                                                phone: formDataValues.mobile_number,
                                                caseid: routerState.caseid,
                                                passcode: '123456',
                                                email: formDataValues.email,
                                                password: formDataValues.password,
                                                type: 'MobileAccount'
                                            })
                                        }
                                    }
                                }
                            }}>
                            {(formik_props) => {
                                const errors = formik_props.errors;
                                const touched = formik_props.touched;

                                if(routerStateValidationErrorsValue && routerStateValidationErrorsValue.ssn !== formik_props.values.ssn_number)
                                {
                                    ssn_error = null
                                }
                                if(routerStateValidationErrorsValue && routerStateValidationErrorsValue.dob !== formik_props.values.date_of_birth)
                                {
                                    dob_error = null
                                }
                                
                                console.log(formik_props.values)
                                console.log(formik_props.errors)
                                console.log(!formik_props.values.ssn_number && !formik_props.values.date_of_birth)

                                return (
                                    <Form className="theme_form" action="signup6_submit_ticket.html">
                                        {routerState.ssn ? <div className="form-group">
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.ssn_number && touched.ssn_number ? errors.ssn_number : null}</span>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{ssn_error ? ssn_error : null}</span>
                                            <Field type="text" name="ssn_number" id="ssn_number" disabled={routerState.ssn ? false : true} className="form-control theme_input" placeholder="Social Security Number" />
                                        </div> : null}
                                        {routerState.dob ? <div className="form-group">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    variant="inline"
                                                    name="date_of_birth"
                                                    inputVariant="outlined"
                                                    label="Date Of Birth"
                                                    id="custom_datepicker"
                                                    disabled={routerState.dob ? false : true}
                                                    className="form-control theme_input validate-account"
                                                    value={formik_props.values.date_of_birth}
                                                    format="MM/dd/yyyy"
                                                    maxDate={new Date()}
                                                    InputAdornmentProps={{ position: "start" }}
                                                    onChange={date => {
                                                        formik_props.setFieldValue("date_of_birth", date)
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.date_of_birth && touched.date_of_birth ? errors.date_of_birth : null}</span>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{dob_error ? dob_error : null}</span>
                                        </div> : null}
                                        {
                                            props.isLoading
                                                ? <Spinner width='25px' height='25px' margin='0 auto' />
                                                : routerState.ssn && routerState.dob
                                                    ? <button type="submit" disabled={!formik_props.values.ssn_number || !formik_props.values.date_of_birth || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Continue</button>
                                                    : routerState.dob
                                                        ? <button type="submit" disabled={!formik_props.values.date_of_birth || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Continue</button>
                                                        : routerState.ssn
                                                            ? <button type="submit" disabled={!formik_props.values.ssn_number || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Continue</button>
                                                            : false

                                        }
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </Oux>
    )
}


const mapState = state => {
    return {
        ssn_number: state.authReducer.ssn_number,
        dob: state.authReducer.dob
    }
}

export default connect(mapState)(withRouter(validateAccount))