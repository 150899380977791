export const AuthActionTypes = {

    LOGIN: "LOGIN",
    LOGIN_PENDING: "LOGIN_PENDING",
    LOGIN_FULFILLED: "LOGIN_FULFILLED",

    SIGNUP: "SIGNUP",
    SIGNUP_PENDING: "SIGNUP_PENDING",
    SIGNUP_FULFILLED: "SIGNUP_FULFILLED",

    CHECK_USER: "CHECK_USER",
    CHECK_USER_PENDING: "CHECK_USER_PENDING",
    CHECK_USER_FULFILLED: "CHECK_USER_FULFILLED",

    CHECK_EMAIL: "CHECK_EMAIL",
    CHECK_EMAIL_PENDING: "CHECK_EMAIL_PENDING",
    CHECK_EMAIL_FULFILLED: "CHECK_EMAIL_FULFILLED",

    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_PENDING: "FORGOT_PASSWORD_PENDING",
    FORGOT_PASSWORD_FULFILLED: "FORGOT_PASSWORD_FULFILLED",

    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
    RESET_PASSWORD_FULFILLED: "RESET_PASSWORD_FULFILLED",

    AUTHORIZE: "AUTHORIZE",
    LOGOUT: "LOGOUT",
    LOGOUT_PENDING: "LOGOUT_PENDING",
    LOGOUT_FULFILLED: "LOGOUT_FULFILLED",

    GET_USER: "GET_USER",
    GET_USER_PENDING: "GET_USER_PENDING",
    GET_USER_FULFILLED: "GET_USER_FULFILLED",

    SEND_OTP: "SEND_OTP",
    SEND_OTP_PENDING: "SEND_OTP_PENDING",
    SEND_OTP_FULFILLED: "SEND_OTP_FULFILLED",

    SUBMIT_OTP: "SUBMIT_OTP",
    SUBMIT_OTP_PENDING: "SUBMIT_OTP_PENDING",
    SUBMIT_OTP_FULFILLED: "SUBMIT_OTP_FULFILLED",

    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",

    SET_MOBILE_FORM_DATA: 'SET_MOBILE_FORM_DATA',
    SET_MOBILE_FORM_DATA_PENDING: 'SET_MOBILE_FORM_DATA_PENDING',
    SET_MOBILE_FORM_DATA_FULFILLED: 'SET_MOBILE_FORM_DATA_FULFILLED',
    RESET_MOBILE_FORM_DATA: 'RESET_MOBILE_FORM_DATA',

    REFRESH_TOKEN: 'REFRESH_TOKEN',
    REFRESH_TOKEN_PENDING: 'REFRESH_TOKEN_PENDING',
    REFRESH_TOKEN_FULFILLED: 'REFRESH_TOKEN_FULFILLED',

    IS_FREETAX_REDIRECTED: "IS_FREETAX_REDIRECTED"
}


export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}

export const FreeTaxActionTypes = {
    IS_CHANGE_PASSWORD: "IS_CHANGE_PASSWORD",
    IS_CHANGE_PASSWORD_PENDING: "IS_CHANGE_PASSWORD_PENDING",
    IS_CHANGE_PASSWORD_FULFILLED: "IS_CHANGE_PASSWORD_FULFILLED",

    IS_PHONE_VERIFICATION: "IS_PHONE_VERIFICATION",
    IS_PHONE_VERIFICATION_PENDING: "IS_PHONE_VERIFICATION_PENDING",
    IS_PHONE_VERIFICATION_FULFILLED: "IS_PHONE_VERIFICATION_FULFILLED",

    IS_8821_FORM: "IS_8821_FORM",
    IS_8821_FORM_PENDING: "IS_8821_FORM_PENDING",
    IS_8821_FORM_FULFILLED: "IS_8821_FORM_FULFILLED",

    GET_8821_FORM_ENVELOPE_ID: "GET_8821_FORM_ENVELOPE_ID",
    GET_8821_FORM_ENVELOPE_ID_PENDING: "GET_8821_FORM_ENVELOPE_ID_PENDING",
    GET_8821_FORM_ENVELOPE_ID_FULFILLED: "GET_8821_FORM_ENVELOPE_ID_FULFILLED",
}

export const SubmitTicketTypes = {
    SUBMIT_TICKET: "SUBMIT_TICKET",
    SUBMIT_TICKET_PENDING: "SUBMIT_TICKET_PENDING",
    SUBMIT_TICKET_FULFILLED: "SUBMIT_TICKET_FULFILLED"
}


export const ServiceActionType = {
    FETCH_MYSERVICE: "FETCH_MYSERVICE",
    FETCH_MYSERVICE_PENDING: "FETCH_MYSERVICE_PENDING",
    FETCH_MYSERVICE_FULFILLED: "FETCH_MYSERVICE_FULFILLED",

    FETCH_ALLSERVICE: "FETCH_ALLSERVICE",
    FETCH_ALLSERVICE_PENDING: "FETCH_ALLSERVICE_PENDING",
    FETCH_ALLSERVICE_FULFILLED: "FETCH_ALLSERVICE_FULFILLED",

    SERVICE_RESET_DATA: "SERVICE_RESET_DATA",

}

export const DocumentsActionType = {

    FETCH_ALLDOCUMENT: "FETCH_ALLDOCUMENT",
    FETCH_ALLDOCUMENT_PENDING: "FETCH_ALLDOCUMENT_PENDING",
    FETCH_ALLDOCUMENT_FULFILLED: "FETCH_ALLDOCUMENT_FULFILLED",

    FETCH_ALLDOCUMENT_CATEGORY: "FETCH_ALLDOCUMENT_CATEGORY",
    FETCH_ALLDOCUMENT_CATEGORY_PENDING: "FETCH_ALLDOCUMENT_CATEGORY_PENDING",
    FETCH_ALLDOCUMENT_CATEGORY_FULFILLED: "FETCH_ALLDOCUMENT_CATEGORY_FULFILLED",

    UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
    UPLOAD_DOCUMENT_PENDING: "UPLOAD_DOCUMENT_PENDING",
    UPLOAD_DOCUMENT_FULFILLED: "UPLOAD_DOCUMENT_FULFILLED",

    DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
    DOWNLOAD_DOCUMENT_PENDING: "DOWNLOAD_DOCUMENT_PENDING",
    DOWNLOAD_DOCUMENT_FULFILLED: "DOWNLOAD_DOCUMENT_FULFILLED",

    FETCH_ALLDOCUMENT_FILTERED: "FETCH_ALLDOCUMENT_FILTERED",
    FETCH_ALLDOCUMENT_FILTERED_PENDING: "FETCH_ALLDOCUMENT_FILTERED_PENDING",
    FETCH_ALLDOCUMENT_FILTERED_FULFILLED: "FETCH_ALLDOCUMENT_FILTERED_FULFILLED",

}

export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE"
