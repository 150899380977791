import React from 'react'
import './ContactUs.css'
import ChatSupport from './ChatSupport/Chat'
import ContactYourTeam from './ContactYourTeam/ContactYourTeam'
import { routes } from '../../utility/constants/constants'
import SubmitTicket from '../SubmitTicket/SubmitTicket'
import TicketSubmitted from '../SubmitTicket/TicketSubmitted'
import Oux from '../../hoc/Oux/Oux'

const contactUs = props => {

    let step = null;
    let currentUrl = `${props.history.location.pathname}${props.history.location.hash}`

    if (currentUrl === routes.CONTACT_US) {
        step = <ContactYourTeam caseid={props.caseid} isLoading={props.isLoading} contactUsPage={props.contactUsPage} />
    }

    if (currentUrl === routes.CONTACT_US + "#chat") {
        step = <ChatSupport isLoggedIn={props.isLoggedIn} />
    }

    else if (currentUrl === routes.CONTACT_US + routes.SUBMIT_TICKET) {
        step = <SubmitTicket email={props.email} submitTicket={props.submitTicket} history={props.history} caseid={props.caseid} />
    }

    else if (currentUrl === routes.CONTACT_US + routes.TICKET_SUBMITTED) {
        step = <TicketSubmitted />
    }

    return (

        <Oux>
            <div id="fp_sub_sidenav_mob" className="fp_sub_sidenav fp_sub_sidenav_mob document_nav">
                {/* <h4 className="fb_sm_title">CONTACT YOUR TEAM</h4> */}
                <div className="nav flex-column nav-pills fp_sub_sidenav_vTabs contact_us">
                    <a className="nav-link"
                        onClick={() => {
                            document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                            props.history.push(routes.CONTACT_US)
                        }} aria-selected="false">
                        <img style={{ color: '#1E7995' }} src="images/customs/icn_contact_phone_new.svg" alt="Icon" className="mr-2" />Contact Your Team
                    </a>
                    <a className="nav-link"
                        onClick={() => {
                            document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                            props.history.push(routes.CONTACT_US + routes.CHAT)
                        }} aria-selected="false">
                        <img src="images/icons/icn_chat.svg" alt="Icon" className="mr-2" />Chat To Us
                    </a>
                    <h4 className="fb_sm_title mt-3">HOW CAN WE IMPROVE?</h4>
                    <a className="nav-link" href="javascript:void(0)"
                        onClick={() => {
                            document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                            props.history.push('#submit-ticket')
                        }} aria-selected="false">
                        <img src="images/icons/icn_info.svg" alt="Icon" className="mr-2" />Give Feedback
                    </a>
                </div>
            </div>
            {step}
        </Oux>
    )
}

export default contactUs