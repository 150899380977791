import React from 'react'
import PhoneCode from 'react-phone-code';
import $ from 'jquery'
import { countries } from 'countries-list'


const countryCodes = props => {

    let country_name = 'United States'
    let country_short_code = null
    let country_code = props.country_code
        ? props.country_code
            : '+1'
    country_code = country_code.replace("+", "")

    Object.keys(countries).map((country, index) => {
        if (country_code === countries[country].phone) {
            console.log(countries[country])
            country_short_code = country
        }
    })

    return (
        <PhoneCode
            onSelect={code => {
                props.form.setFieldValue('country_code', code)
            }} // required
            showFirst={[country_short_code]}
            // defaultValue={country_name}
            className='form-control theme_input'
            id='select_country_code'
            optionClassName='country_options'
        />
    )
}

export default countryCodes