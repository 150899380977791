import axios from '../config';
import { API_ADMIN as adminAxios } from '../config';
import { API_VERSION, NS_TOKEN } from '../config'
import directAxios from 'axios'

export const updatePersonalInfo = (credentials) => {
    const formData = new FormData()

    // formData.append('caseid', credentials.caseid)
    // formData.append('id', credentials.id)

    // formData.append('name', credentials.first_name+' '+credentials.last_name)
    // formData.append('first_name', credentials.first_name)
    // formData.append('last_name', credentials.last_name)
    // formData.append('birthdate', credentials.date_of_birth)
    // formData.append('phone_home', credentials.phone_number)
    // formData.append('phone', credentials.phone_number)
    // formData.append('primary_address_street', credentials.address)
    // formData.append('primary_address_city', credentials.city)
    // formData.append('primary_address_state', credentials.state)
    // formData.append('primary_address_postalcode', credentials.zip_code)
    // formData.append('primary_address_country', credentials.country)

    // formData.append('spouse_first_name', credentials.spouse_first_name)
    // formData.append('spouse_last_name', credentials.spouse_last_name)
    // formData.append('spouse_birthdate', credentials.spouse_date_of_birth)
    // formData.append('spouse_primary_cell_phone', credentials.spouse_phone_number)

    // formData.append('type', credentials.type)
    Object.keys(credentials).map(key => {
        console.log(key + ":" + credentials[key])
        if (credentials[key] === null || credentials[key] === undefined) {
            console.log('null')

        }
        else {
            if (key === 'email') {
                console.log('email')
            }
            else if (key === 'phone_number') {
                console.log('phone number')
            }
            else {
                formData.append(key, credentials[key])
            }
        }
    })

    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const fetchBillingPaymentSchedule = (credentials) => {
    const formData = new FormData()

    formData.append('caseid', credentials.caseid)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileBilling.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const createBillingPayment = (credentials) => {
    const formData = new FormData()

    formData.append('caseid', credentials.caseid)
    formData.append('pay_type', credentials.pay_type)
    formData.append('amount', credentials.amount)
    formData.append('comment', credentials.comment)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileBilling.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const getUserBusiness = (credentials) => {
    const formData = new FormData()
    for (let key in credentials) {
        formData.append(key, credentials[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const updateBusinessInfo = (credentials) => {
    // const formData = new FormData()

    // formData.append('caseid', credentials.caseid)
    // // formData.append('caseid', '5027864')
    // if (credentials.indexid) {
    //     formData.append('indexid', credentials.indexid)
    // }

    // formData.append('businessname', credentials.business_name)
    // formData.append('phonenumber', credentials.phone_number)
    // formData.append('street', credentials.address)
    // formData.append('city', credentials.city)
    // formData.append('state', credentials.state)
    // formData.append('postalcode', credentials.zip_code)
    // formData.append('country', credentials.country)

    // formData.append('type', credentials.type)
    const formData = new FormData()
    for (let key in credentials) {
        formData.append(key, credentials[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const deleteBusinessInfo = (credentials) => {
    const formData = new FormData()

    formData.append('indexid', credentials.indexid)

    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const getBillingDetailsInfo = (credentials) => {
    const formData = new FormData()

    formData.append('caseid', credentials.caseid)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileBilling.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const saveUserBillingDetails = (credentials) => {
    const formData = new FormData()

    formData.append('caseid', credentials.caseid)

    if (!credentials.primary_account) {
        formData.append('primary_account', 0)
    } else {
        formData.append('primary_account', credentials.primary_account === true ? 1 : 0);
    }

    if (credentials.formType === 'add_card') {
        formData.append('acc_type', credentials.pay_type)
        formData.append('name', credentials.name_on_card)
        formData.append('cc_no', credentials.card_number)
        formData.append('cc_exp_date', credentials.expiry_date)
        if (credentials.address) {
            formData.append('address', credentials.address)
        }
        if (credentials.city) {
            formData.append('city', credentials.city)
        }
        if (credentials.state) {
            formData.append('state', credentials.state)
        }
        if (credentials.zip_code) {
            formData.append('zip', credentials.zip_code)
        }
    }
    else {
        formData.append('bank_name', credentials.bank_name)
        formData.append('name', credentials.account_holder_name)
        formData.append('acc_no', credentials.account_number)
        formData.append('route_no', credentials.route_number)
        formData.append('acc_type', credentials.pay_type)
        if (credentials.address) {
            formData.append('address', credentials.address)
        }
        if (credentials.city) {
            formData.append('city', credentials.city)
        }
        if (credentials.state) {
            formData.append('state', credentials.state)
        }
        if (credentials.zip_code) {
            formData.append('zip', credentials.zip_code)
        }
    }

    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileBilling.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const getUserNotifications = (credentials) => {
    const formData = new FormData()

    formData.append('caseid', credentials.caseid)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileMisc.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};