import axios from '../config';
import { API_ADMIN as adminAxios } from '../config';
import { API_VERSION, NS_TOKEN } from '../config'

export const fetchAllDocuments = (credentials) => {
    const formData = new FormData()
    
    formData.append('caseid', credentials.caseid)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileDocstar.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const fetchDocumentCategories = (credentials) => {
    const formData = new FormData()

    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileMisc.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const fetchFilteredDocuments = (credentials) => {
    const formData = new FormData()
    
    formData.append('caseid', credentials.caseid)
    formData.append('type', credentials.type)

    if(credentials.category)
    {
        formData.append('category', credentials.category)
    }

    if(credentials.startdate)
    {
        formData.append('startdate', credentials.startdate)
    }

    if(credentials.enddate)
    {
        formData.append('enddate', credentials.enddate)
    }

    return axios.post(API_VERSION() + 'CTaxMobileDocstar.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const uploadDocument = (credentials) => {
    const formData = new FormData()

    formData.append('type', credentials.type)
    formData.append('caseid', credentials.caseid)
    formData.append('file', credentials.file)
    formData.append('category', credentials.category)

    return axios.post(API_VERSION() + 'CTaxMobileDocstar.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const downloadDocument = (credentials) => {
    const formData = new FormData()

    formData.append('type', credentials.type)
    formData.append('caseid', credentials.caseid)
    formData.append('versionid', credentials.versionid)

    return axios.post(API_VERSION() + 'CTaxMobileDocstar.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};