import React, {Component} from 'react'
import { connect } from 'react-redux'
import axios from '../../config'
import { API_VERSION, NS_TOKEN } from '../../config'
import ReactHtmlParser from 'react-html-parser';
import Oux from '../../hoc/Oux/Oux'

class TermsAndConditions extends Component{
    state = {
        terms: null
    }

    componentDidMount() {
        axios.get(API_VERSION() + 'CTaxMobileTC.php', {
            headers: {
                'ns-token': NS_TOKEN,
            },
        }).then(response => {
            console.log(response.data)
            this.setState({ terms: response.data })
            return response.data
        }).catch(error => {
            return error
        })
    }
    render(){
        let termsConditions = (
            <Oux>
                <div class="clearfix"></div>
                <div className="container-login100">
                    <div className="privacy_policy text-center">
                        {ReactHtmlParser(this.state.terms)}
                    </div>
                </div>
            </Oux>
        )

        if (this.props.isLoggedIn) {
            termsConditions = (
                <main className="main_section">
                    <div className="main_cont">
                        <div className="container-fluid fp_pl_0 fp_sub_sidenav_outer">
                            <div className="privacy_policy">
                                {ReactHtmlParser(this.state.terms)}
                            </div>
                        </div>
                    </div>
                </main>
            )
        }
        return termsConditions
    }
}

const mapState = state => {
    return {
        isLoggedIn: state.authReducer.isLoggedIn
    }
}

export default connect(mapState)(TermsAndConditions)