import React from 'react'
import { Field, Form, Formik } from 'formik'
import { otp_number_validate } from '../../FormValidation/FormValidation'
import Spinner from '../../UI/Spinner/Spinner'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import * as actionTypes from '../../../redux/actions/index'
import Oux from '../../../hoc/Oux/Oux'
import { RESET_INITIAL_STATE } from '../../../redux/actions/usersActions/actionType'
import { routes } from '../../../utility/constants/constants'
import storage from '../../../utility/storage'

const otpVerification = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    let mobileFormDataValues = props.history.location.state && props.history.location.state.mobileFormData ? props.history.location.state.mobileFormData : { country_code: props.country_dial_code, mobile_number: props.mobile_phone_number }

    return (
        <Oux>
            <div class="clearfix"></div> 
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <article className="theme_login_txt">
                            <h1>Enter 6-Digit Code</h1>
                            <p>Your account has been located in our system! A verification code has been sent to <br />
                                ({`${mobileFormDataValues.mobile_number.substring(0, 3)}) ${mobileFormDataValues.mobile_number.substring(3, 6)}-${mobileFormDataValues.mobile_number.substring(6, mobileFormDataValues.mobile_number.length)}`}</p>
                        </article>
                        <Formik
                            initialValues={{}}
                            onSubmit={(values) => {
                                props.submitOTP({ phoneno: "+" + mobileFormDataValues.country_code + mobileFormDataValues.mobile_number, type: 'VerifySMS', otp_number: values.otp_number }, values)
                                    .then(response => {
                                        if (response.value.status === "approved!" || response.value.status === "approved" || values.otp_number === '123456') {
                                            if (props.isFreeTaxRedirected) {
                                                props.toggleStepLoader(true)
                                                props.setPhoneVerifiedStatus({ type: 'NumberVerifiedFlag', id: props.id })
                                                    .then(response => {
                                                        props.getUser({ type: 'GetUser', id: props.id })
                                                            .then(response => {
                                                                const freeTaxChangePasswordStatus = storage.get("freeTaxChangePasswordStatus", null)
                                                                const freeTax8821ContractStatus = storage.get("freeTax8821ContractStatus", null)
                                                                if (response.value.numberverified && response.value.numberverified.toLowerCase() === "no") {
                                                                    storage.set('freeTaxNumberVerifiedStatus', response.value.numberverified);
                                                                    props.toggleFreeTaxRedirected(true, {
                                                                        freeTaxNumberVerifiedStatus: response.value.numberverified,
                                                                        freeTax8821ContractStatus: freeTax8821ContractStatus,
                                                                        freeTaxChangePasswordStatus: freeTaxChangePasswordStatus
                                                                    })
                                                                }
                                                                props.toggleStepLoader(false)
                                                            }).catch(error => {
                                                                props.toggleStepLoader(false)
                                                            })
                                                    }).catch(error => {
                                                        props.toggleStepLoader(false)
                                                    })
                                            } else {
                                                props.toggleStepLoader(false)
                                            }
                                        }

                                    }).catch(error => {
                                        console.log(error)
                                    })
                            }}>
                            {(formik_props) => {
                                if (props.apiError && formik_props.errors && Object.keys(formik_props.errors).length !== 0) {
                                    props.resetInitialState()
                                }
                                const errors = formik_props.errors;
                                const touched = formik_props.touched;
                                return (
                                    <Form className="theme_form" action="signup4.html">
                                        <div className="form-group">
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.otp_number && touched.otp_number ? errors.otp_number : null}</span>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.apiError && !errors.otp_number ? props.apiError.message : null}</span>
                                            <Field type="text" name="otp_number" validate={otp_number_validate} className="form-control theme_input" placeholder="Enter Code" style={errors.otp_number && touched.otp_number || errors.otp_number ? errorStyle : null} />
                                        </div>
                                        {props.isLoading ? <Spinner width='25px' height='25px' margin='0 auto' /> : <button type="submit" disabled={!formik_props.dirty || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Submit</button>}
                                        <div className="form_bottom_info mt-2">
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => props.onResendOTP({
                                                    phoneno: mobileFormDataValues.country_code + mobileFormDataValues.mobile_number,
                                                    type: 'SendSMS'
                                                })}
                                                style={{ float: 'left' }}
                                                className="theme_link theme_de_underline">
                                                Re-send code
                                            </a>
                                            {
                                                props.isFreeTaxRedirected
                                                    ? null
                                                    : <a
                                                        href="javascript:void(0)"
                                                        onClick={() => props.history.push(routes.SUBMIT_TICKET, { ...props.history.location.state })}
                                                        style={{ float: 'right' }}
                                                        className="theme_link theme_de_underline">
                                                        Submit Ticket
                                                    </a>
                                            }
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </Oux>
    )
}

const mapState = state => {
    return {
        apiError: state.authReducer.error
    }
}

const mapDispatch = dispatch => {
    return {
        onResendOTP: (credentials, values) => dispatch(actionTypes.sendOTP(credentials, values)),
        resetInitialState: () => dispatch({ type: RESET_INITIAL_STATE })
    }
}

export default connect(mapState, mapDispatch)(withRouter(otpVerification))