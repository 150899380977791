import React from 'react'
import { routes } from '../../utility/constants/constants'
import storage from '../../utility/storage'
import $ from 'jquery'

const myAccount = props => {


    let currentUrl = `${props.historyProps.location.pathname}${props.historyProps.location.hash}`

    return (
        <div id="fp_sub_sidenav_mob" className="fp_sub_sidenav myAccount_sidenav fp_sub_sidenav_mob">
            <div className="nav flex-column nav-pills fp_sub_sidenav_vTabs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a className={currentUrl === routes.MYACCOUNT + routes.PERSONAL_INFORMATION ? "nav-link active" : "nav-link"}
                    id="v-pills-Personal-Information-tab"
                    data-toggle="pill" href="javascript:void(0)"
                    onClick={() => {
                        document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                        props.historyProps.push(routes.MYACCOUNT + routes.PERSONAL_INFORMATION)
                    }}
                    role="tab"
                    aria-controls="v-pills-Personal-Information"
                    aria-selected="false">
                    <span className="nav_link_text">
                        <img src="/images/icons/icn_user_white.svg" className="icn_active mr-1" />
                        <img src="/images/icons/icn_user_blue.svg" className="icn_non_active mr-1" />
                        Personal Information
                    </span>
                    <i className="fa fa-angle-right"></i>
                </a>
                <a className={currentUrl === routes.MYACCOUNT + routes.BUSINESS_INFORMATION ? "nav-link active" : "nav-link"} id="v-pills-Business-Information-tab" data-toggle="pill" href="javascript:void(0)" onClick={() => {
                    document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                    props.historyProps.push(routes.MYACCOUNT + routes.BUSINESS_INFORMATION)
                }} role="tab" aria-controls="v-pills-Business-Information" aria-selected="false">
                    <span className="nav_link_text">
                        <img src="/images/icons/icn_building_white.svg" className="icn_active mr-1" />
                        <img src="/images/icons/icn_building_blue.svg" className="icn_non_active mr-1" />
                        Business Information
                </span>
                    <i className="fa fa-angle-right"></i>
                </a>
                <a className={currentUrl === routes.MYACCOUNT + routes.BILLING_INFORMATION ? "nav-link active" : "nav-link"} id="v-pills-Billing-Information-tab" data-toggle="pill" href="javascript:void(0)" onClick={() => {
                    document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                    props.historyProps.push(routes.MYACCOUNT + routes.BILLING_INFORMATION)
                }} role="tab" aria-controls="v-pills-Billing-Information" aria-selected="false">
                    <span className="nav_link_text">
                        <img src="/images/icons/icn_dollar_white.svg" className="icn_active mr-1" />
                        <img src="/images/icons/icn_dollar_blue.svg" className="icn_non_active mr-1" />
                        Billing Information
                </span>
                    <i className="fa fa-angle-right"></i>
                </a>
                <a className={currentUrl === routes.MYACCOUNT + routes.CHANGE_PASSWORD ? "nav-link active" : "nav-link"} id="v-pills-Change-Password-tab" data-toggle="pill" href="javascript:void(0)" onClick={() => {
                    document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                    props.historyProps.push(routes.MYACCOUNT + routes.CHANGE_PASSWORD)
                }} role="tab" aria-controls="v-pills-Change-Password" aria-selected="false">
                    <span className="nav_link_text">
                        <img src="/images/icons/icn_lock_white.svg" className="icn_active mr-1" />
                        <img src="/images/icons/icn_lock_blue.svg" className="icn_non_active mr-1" />
                        Change Password
                </span>
                    <i className="fa fa-angle-right"></i>
                </a>
                <a
                    className="nav-link"
                    href="https://www.communitytax.com/california-opt-out-form/"
                    target="_blank">
                    <span className="nav_link_text">
                        <img style={{ width: '15px', height: '20px' }} src="/images/customs/do_not_sell.svg" className="icn_non_active mr-1" />
                        {/* <img src="/images/icons/icn_lock_blue.svg" className="icn_non_active mr-1" /> */}
                        Do Not Sell My Personal Information (California)
                </span>
                    <i className="fa fa-angle-right"></i>
                </a>
                <a
                    className="nav-link"
                    href="https://www.communitytax.com/california-privacy-rights-ccpa/"
                    target="_blank">
                    <span className="nav_link_text">
                        <img style={{ width: '15px', height: '20px' }} src="/images/customs/california_consumer.svg" className="icn_non_active mr-1" />
                        {/* <img src="/images/customs/california_consumer.svg" className="icn_non_active mr-1" /> */}
                        California Consumer Privacy Act Notice
                </span>
                    <i className="fa fa-angle-right"></i>
                </a>
                <hr className="cst_divider" />
                <a className="nav-link"
                    onClick={() => {
                        $(".tb_sidebar_active").removeClass("tb_sidebar_active")
                        $("#sidebar").removeClass("active")
                        return props.logoutUser({
                            caseid: storage.get("case_id", null),
                            userid: storage.get("userid", null), type: 'Logout'
                        })
                    }}
                    href="javascript:void(0)">
                    <span style={{ zIndex: '0' }} className="nav_link_text">
                        <img src="/images/icons/icn_logout_white.svg" className="icn_active mr-1" />
                        <img src="/images/icons/icn_logout_blue.svg" className="icn_non_active mr-1" />
                    </span>
                    Logout
                </a>
            </div>
        </div>
    )
}

export default myAccount