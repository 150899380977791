import React, { Component } from 'react'
import RegisterForm from '../../components/RegisterForm/RegisterForm'
import PhoneVerificationForm from '../../components/RegisterForm/PhoneVerification/PhoneVerification'
import OTPVerificationForm from '../../components/RegisterForm/PhoneVerification/OTPVerification'
import { Formik } from 'formik'
import { submitTicketValidateForm } from '../../components/FormValidation/FormValidation'
import PhoneNumNotLinkedForm from '../../components/RegisterForm/PhoneVerification/PhoneNumNotLinked'
import ValidateAccountForm from '../../components/RegisterForm/AccountValidation/ValidateAccount'
import { withRouter } from 'react-router'
import { routes } from '../../utility/constants/constants'
import SubmitTicket from '../../components/SubmitTicket/SubmitTicket'
import TicketSubmitted from '../../components/SubmitTicket/TicketSubmitted'
import * as actionTypes from '../../redux/actions/index'
import { connect } from 'react-redux'

class Register extends Component {

    componentDidMount() {
        let currentUrl = `${this.props.history.location.pathname}${this.props.history.location.hash}`
        if (currentUrl === this.props.history.location.pathname + routes.REGISTER) {
            this.props.history.push(this.props.history.location.pathname + routes.PROFILE)
        }
    }

    state={
        isLoading: false,
        mobile_number: null,
        country_code: null
    }

    onSubmitPhoneNumberNotLinked = (values) => {
        this.setState({ isLoading: true })
        this.props.resetMobileFormData({mobile_number: null, country_code: null})
        if (values.checkbox1) {
            setTimeout(()=>{
                this.setState({isLoading: false})
                this.props.history.push(
                    this.props.history.location.pathname + routes.VERIFY_OLD_MOBILE,
                    {
                        ...this.props.history.location.state,
                        prevPath: this.props.history.location.pathname + this.props.history.location.hash
                    })
            }, 2000)
        }
        else{
            let formDataValues = {...this.props.history.location.state.registerFormData, ...this.props.history.location.state.mobileFormData}
            let apiValues = this.props.history.location.state.apiResponse
            this.props.checkUser({ type: 'CheckNew', phone: formDataValues.mobile_number, email: formDataValues.email }, this.props.history.location.state.mobileFormData)
            this.setState({isLoading: false})
        }
    }

    setMobileFormData = (mobile_number, country_code) => {
        if(this.state.mobile_number !== mobile_number)
        {
            this.setState({mobile_number: mobile_number})
        }
        if(this.state.country_code !== country_code)
        {
            this.setState({country_code: country_code})
        }
    }

    render() {

        let step = null;
        let currentUrl = `${this.props.history.location.pathname}${this.props.history.location.hash}`
        const isRegistered = this.props.history.location.state ? this.props.history.location.state.isRegistered : false

        if(currentUrl === routes.REGISTER + routes.VALIDATE_ACCOUNT)
        {
            let stateValues = {...this.props.history.location.state}
            window.addEventListener("popstate", e => {
                // Nope, go back to your page
                let newCurrentUrl = `${this.props.history.location.pathname}${this.props.history.location.hash}`
                if(newCurrentUrl === routes.REGISTER+routes.VERIFY_OTP)
                {
                    this.props.history.push(routes.VALIDATE_ACCOUNT,{...stateValues});
                }
              });
        }
        
        if (currentUrl === this.props.history.location.pathname + routes.PROFILE) {
            step = <RegisterForm apiError={this.props.error} isLoading={this.props.isLoading} onSubmitRegister={this.props.checkEmail} />
        }
        else if (currentUrl === this.props.history.location.pathname + routes.VERIFY_MOBILE) {
            if (isRegistered) {
                step = <PhoneVerificationForm history={this.props.history} apiError={this.props.error} isLoading={this.props.isLoading} checkUser={this.props.checkUser} />
            }
            else {
                this.props.history.push(this.props.history.location.pathname + routes.PROFILE)
            }
        }
        else if (currentUrl === this.props.history.location.pathname + routes.VERIFY_OLD_MOBILE) {
            if (isRegistered) {
                step = <PhoneVerificationForm history={this.props.history} apiError={this.props.error} isLoading={this.props.isLoading} checkUser={this.props.checkUser} />
            }
            else {
                this.props.history.push(this.props.history.location.pathname + routes.PROFILE)
            }
        }
        else if (isRegistered && currentUrl === this.props.history.location.pathname + routes.PHNO_NOT_LINKED) {
            step = <PhoneNumNotLinkedForm isLoading={this.state.isLoading} isApiLoading={this.props.isLoading} onSubmitPhoneNumberNotLinked={this.onSubmitPhoneNumberNotLinked} />
        }
        else if (isRegistered && currentUrl === this.props.history.location.pathname + routes.VERIFY_OTP) {
            step = <OTPVerificationForm isLoading={this.props.isLoading} submitOTP={this.props.submitOTP} />
        }
        else if (isRegistered && currentUrl === this.props.history.location.pathname + routes.VALIDATE_ACCOUNT) {
            step = <ValidateAccountForm isLoading={this.props.isLoading} signUpUser={this.props.signUpUser} />
        }
        else if (isRegistered && currentUrl === this.props.history.location.pathname + routes.SUBMIT_TICKET) {
            step = <SubmitTicket history={this.props.history} isLoading={this.props.isLoading} submitTicket={this.props.submitTicket} />
        }
        else if (isRegistered || currentUrl === this.props.history.location.pathname + "#ticket-submitted") {
            step = <TicketSubmitted />
        }
        else {
            this.props.history.push(this.props.history.location.pathname + routes.PROFILE)
        }
        return step
    }
}

const mapState = state => {
    return {
        isLoading: state.authReducer.isloading,
        userStatus: state.authReducer.userStatus,
        caseid: state.authReducer.case_id,
        error: state.authReducer.error
    }
}

const mapDispatch = dispatch => {
    return {
        checkUser: (credentials, values) => dispatch(actionTypes.checkUser(credentials, values)),
        submitTicket: (credentials, values) => dispatch(actionTypes.submitTicket(credentials, values)),
        sendOTP: (credentials, values) => dispatch(actionTypes.sendOTP(credentials, values)),
        submitOTP: (credentials, values) => dispatch(actionTypes.submitOTP(credentials, values)),
        signUpUser: (credentials) => dispatch(actionTypes.signup(credentials)),
        checkEmail: (credentials, values) => dispatch(actionTypes.checkEmail(credentials, values)),
        resetMobileFormData: (values) => dispatch(actionTypes.resetMobileFormData(values))
    }
}

export default connect(mapState, mapDispatch)(withRouter(Register))
