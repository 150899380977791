import store from '../../redux/store/store';
import * as actions from '../../redux/actions/usersActions/action';
import { toastMsg } from '../utility';

const tokenInterceptor = (response) => {
    const state = store.getState();
    let userIsLoggedIn = state.authReducer.isLoggedIn
    if (userIsLoggedIn) {
        if (response.data.error) {
            if ((response.data.error.message && response.data.error.message !== "No data found!") || (response.data.error.message && response.data.error.message !== "No account found!")) {
                toastMsg(response.data.error.message, true, 5000);
            }
            else {
                if (response.data.error !== "No data found!") {
                    if (response.data.error !== "No account found!") {
                        toastMsg(response.data.error, true, 5000);
                    }
                }
            }
        }
    }

    return response;
}

export default tokenInterceptor;