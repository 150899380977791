import React from 'react'
import Spinner from '../../UI/Spinner/Spinner'

const chat = props => {
    let chatBox = null

    console.log(props.isLoggedIn)

    if (props.isLoggedIn) {
        chatBox = (
            <div className="fp_right_content">
                <div className="tab-content tab-pane fade active show" id="v-pills-tabContent">
                    <div className="fb_tabs_inner contact_us">
                        <div className="doc_tab_content">
                            <div style={{}} className="loadImg"><Spinner spinnerStyle={{ width: '50px', height: '50px', top: '50%', left: '60%', position: 'absolute' }}/></div>
                            <iframe
                                id="chat"
                                className="chat"
                                border={0}
                                src="https://sugarapi.ctaxcrm.com/mobile-app/CTaxMobileChat.html"
                                height="480"
                                scrolling="no"
                                noresize
                                frameborder="0"
                                onLoad={() => document.getElementsByClassName("loadImg")[0].style.display = 'none'}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return chatBox
}
export default chat