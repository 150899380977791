import React, { useState } from 'react'
import { Form, Field, Formik } from 'formik'
import $ from 'jquery'

import './ChangePassword.css'
import Spinner from '../../UI/Spinner/Spinner';
import { validateChangePassword } from '../../FormValidation/FormValidation';

const ChangePassword = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    const eyeErrorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftStyle: 'solid'
    }

    const toggleInputType = (event) => {
        if ($(event.target).parent().parent().parent().children().attr('type') === 'password') {
            $(event.target).parent().parent().parent().children().attr('type', 'text')
            $(event.target).addClass('fa-eye').removeClass('fa-eye-slash')
        }
        else {
            $(event.target).parent().parent().parent().children().attr('type', 'password')
            $(event.target).addClass('fa-eye-slash').removeClass('fa-eye')
        }
    }

    let apiErrorMessage = null

    if (props.apiError) {
        apiErrorMessage = props.apiError.message
    }

    return (
        <div className="tab-pane fade changes_password active show" id="v-pills-Change-Password" role="tabpanel" aria-labelledby="v-pills-Change-Password-tab">
            <div className="fb_tabs_inner contact_us business_information">
                <div className="doc_tab_content">
                    <div className="bi_add_details">
                        <div className="fp_tabs_inner_40">
                            <div className="fp_invs_inner_block">
                                <h1 className="fp_sec_title">Change Password</h1>
                            </div>
                            <div className="business_info_content">
                                <Formik
                                    initialValues={{}}
                                    onSubmit={(values, formik_props) => {
                                        if (values.old_password === values.new_password) {
                                            formik_props.setErrors({
                                                ...formik_props.errors,
                                                new_password: 'Password Should Not Be Same As Old Password'
                                            })
                                        }
                                        else {
                                            props.loginUser({ email: props.user.email, password: values.old_password, type: 'SignIn' }, values)
                                        }
                                    }}
                                    validationSchema={validateChangePassword}>
                                    {(formik_props) => {
                                        const errors = formik_props.errors;
                                        const touched = formik_props.touched;
                                        return (
                                            <Form>
                                                <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.old_password && touched.old_password && errors.old_password}</span>
                                                <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{apiErrorMessage && !errors.old_password ? 'Invalid Old Password' : null}</span>
                                                <div className="input-group mb-3 cst_input_group">
                                                    <Field
                                                        type='password'
                                                        name="old_password"
                                                        className="form-control input_modifier"
                                                        placeholder="Old Password"
                                                        style={errors.old_password && touched.old_password ? errorStyle : null} />
                                                    <div className="input-group-append">
                                                        <span style={errors.old_password && touched.old_password ? eyeErrorStyle : null} className="input-group-text" id="basic-addon2">
                                                            <i onClick={(event) => toggleInputType(event)} className='fa fa-eye-slash'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.new_password && touched.new_password && errors.new_password}</span>
                                                <div className="input-group mb-3 cst_input_group">
                                                    <Field
                                                        type='password'
                                                        name="new_password"
                                                        className="form-control input_modifier"
                                                        placeholder="New Password"
                                                        style={errors.new_password && touched.new_password ? errorStyle : null} />
                                                    <div className="input-group-append">
                                                        <span style={errors.new_password && touched.new_password ? eyeErrorStyle : null} className="input-group-text" id="basic-addon2">
                                                            <i onClick={(event) => toggleInputType(event)} className='fa fa-eye-slash'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.confirm_new_password && touched.confirm_new_password && errors.confirm_new_password}</span>
                                                <div className="input-group mb-3 cst_input_group">
                                                    <Field
                                                        type='password'
                                                        name="confirm_new_password"
                                                        className="form-control input_modifier"
                                                        placeholder="Confirm Password"
                                                        style={errors.confirm_new_password && touched.confirm_new_password ? errorStyle : null} />
                                                    <div className="input-group-append">
                                                        <span style={errors.confirm_new_password && touched.confirm_new_password ? eyeErrorStyle : null} className="input-group-text" id="basic-addon2">
                                                            <i onClick={(event) => toggleInputType(event)} className='fa fa-eye-slash'></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                {props.isLoading ? <Spinner width='25px' height='25px' margin='0 auto' />
                                                    : <button type="submit" disabled={!formik_props.dirty || !formik_props.isValid} className="btn btn-primary cst_btn">Save</button>}
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ChangePassword