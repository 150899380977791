import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from '../../redux/actions';
import { APIType } from '../../utility/constants/constants';
import { serviceRoute } from '../../utility/utility';
import { compose } from 'redux';

const ServiceWrapper = (WrappedComponent) => {
    return class HigherOrderComponent extends Component {
        constructor(props) {
            super(props);
            if (this.props.user && this.props.isLoggedIn) {
                this.fetchServices();
            }
        }

        componentDidUpdate = (prevProps, prevState) => {
            if (!prevProps.user && this.props.user) {
                this.fetchServices();
            }
        }

        fetchServices = () => {
            if (!this.props.isAvailableServicesLoaded && this.props.availableServices.length === 0) {
                let request = {
                    type: APIType.AvailableServices,
                    caseid: this.props.user.caseid
                }
                this.props.fetchAvailableServices(request);
            }

            let request = {
                type: APIType.MyServices,
                caseid: this.props.user.caseid
            }
            this.props.fetchMyServices(request);
        }

        myServiceHandler = (service) => {
            console.log("my service:" + JSON.stringify(service))
            let routeName = serviceRoute(service.name);
            this.props.history.push(routeName)
        }

        render() {
            return <WrappedComponent {...this.props}
                myServiceHandler={this.myServiceHandler}
                fetchServices={this.fetchServices} />
        }
    }
};

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    availableServices: state.serviceReducer.availableServices,
    myServices: state.serviceReducer.myServices,
    isLoading: state.serviceReducer.isLoading,
    error: state.serviceReducer.error,
    isAvailableServicesLoaded: state.serviceReducer.isAvailableServicesLoaded,
    isMyServicesLoaded: state.serviceReducer.isMyServicesLoaded,
    isLoggedIn: state.authReducer.isLoggedIn
});

const mapStateToDispatch = (dispatch) => ({
    fetchAvailableServices: (request) => dispatch(action.fetchAvailableServices(request)),
    fetchMyServices: (request) => dispatch(action.fetchMyServices(request)),
});

const composedHOC = compose(
    connect(mapStateToProps, mapStateToDispatch),
    ServiceWrapper
);

export default composedHOC;