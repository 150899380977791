import React, { useState } from 'react'
import { Form, Field, Formik } from 'formik'
import Spinner from '../UI/Spinner/Spinner'
import { submitTicketValidateForm } from '../FormValidation/FormValidation';
import Oux from '../../hoc/Oux/Oux';
import { routes } from '../../utility/constants/constants';

const SubmitTicket = props => {

    const [isLoading, setIsloading] = useState(false)

    let currentUrl = `${props.history.location.pathname}${props.history.location.hash}`
    let step = null

    function formatPhoneNumber(number, setFieldValue) {
        if (number.match(/\D/g, '')) {
            number = number.replace(/\D/g, '')
        }
        var match = number.match(/^(\d{3})(\d{3})(\d{4})$/)
        let formatted_mobile_number = null
        if (match) {
            formatted_mobile_number = '(' + match[1] + ') ' + match[2] + '-' + match[3]
        }
        debugger
        if (setFieldValue && formatted_mobile_number) {
            setFieldValue('ticket_mobile_number', formatted_mobile_number)
        }
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    const onChangePhoneNumber = (event, setFieldValue) => {
        debugger
        const formDataValues = { ...props.history.location.state.registerFormData, ...props.history.location.state.mobileFormData }
        let number = event.target.value
        if (number.match(/\D/g, '')) {
            number = event.target.value.replace(/\D/g, '')
        }
        if (number.length === 10 && event.target.value !== formDataValues.mobile_number) {
            formatPhoneNumber(event.target.value, setFieldValue)
        }
        else {
            setFieldValue('ticket_mobile_number', event.target.value)
        }
    }

    if (currentUrl !== routes.CONTACT_US + '#submit-ticket') {
        const formDataValues = { ...props.history.location.state.registerFormData, ...props.history.location.state.mobileFormData }
        let formatted_mobile_number = null
        if (formDataValues) {
            formatted_mobile_number = formatPhoneNumber(formDataValues.mobile_number)
        }
        step = <Oux>
            <div class="clearfix"></div>
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <article className="theme_login_txt">
                            <h1>Submit Ticket</h1>
                            <p>Please add any additional information you believe will be helpful to assist us with locating your account.</p>
                        </article>
                        <Formik
                            initialValues={{ ticket_mobile_number: formatted_mobile_number }}
                            onSubmit={(values) => {
                                props.submitTicket({
                                    phone: formDataValues.mobile_number,
                                    email: formDataValues.email,
                                    caseid: props.history.location.state.apiResponse.caseid,
                                    message: values.message_description
                                },
                                    { ...formDataValues, values }
                                )
                            }}
                            validationSchema={submitTicketValidateForm}>
                            {(formik_props) => {

                                const errors = formik_props.errors;
                                const touched = formik_props.touched;

                                return (
                                    <Form className="theme_form" action="signup6_ticket_success.html">
                                        <div className="form-group">
                                            <Field name="message_description" component="textarea" rows="4" className="form-control theme_input" placeholder="Additional Information" style={errors.message_description && touched.message_description ? errorStyle : null} />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.message_description && touched.message_description && errors.message_description}</span>
                                        </div>
                                        <div className="form-group">
                                            <Field onChange={(event) => onChangePhoneNumber(event, formik_props.setFieldValue)} name="ticket_mobile_number" type="text" className="form-control theme_input" placeholder="Best Contact Number" style={errors.ticket_mobile_number && touched.ticket_mobile_number ? errorStyle : null} />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.ticket_mobile_number && touched.ticket_mobile_number && errors.ticket_mobile_number}</span>
                                        </div>
                                        {props.isLoading ? <Spinner width='25px' height='25px' margin='0 auto' /> : <button disabled={!formik_props.dirty || !formik_props.isValid} type="submit" className="btn btn-primary btn-block btn_submit globel_btn">Submit Ticket</button>}
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </Oux>
    }
    else {
        step = <Oux>
            <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                <div className="theme_form_outer" style={{ marginTop: '0px' }}>
                    <article className="theme_login_txt">
                        <h1>Tell us what do you like about us?</h1>
                    </article>
                    <Formik
                        initialValues={{}}
                        onSubmit={(values) => {
                            setIsloading(true)
                            props.submitTicket({
                                // phone: values.ticket_mobile_number,
                                caseid: props.caseid,
                                email: props.email,
                                message: values.feedback_message
                            },
                                { values }
                            )
                        }}>
                        {(formik_props) => {

                            const errors = formik_props.errors;
                            const touched = formik_props.touched;
                            console.log(errors)
                            return (
                                <Form className="theme_form" action="signup6_ticket_success.html">
                                    <div className="form-group">
                                        <Field name="feedback_message" component="textarea" rows="4" className="form-control theme_input" placeholder="Write your feedback here...." style={errors.message_description && touched.message_description ? errorStyle : null} />
                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.message_description && touched.message_description && errors.message_description}</span>
                                    </div>
                                    {/* <div className="form-group">
                                        <Field name="ticket_mobile_number" type="text" className="form-control theme_input" placeholder="Best Contact Number" style={errors.ticket_mobile_number && touched.ticket_mobile_number ? errorStyle : null} />
                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.ticket_mobile_number && touched.ticket_mobile_number && errors.ticket_mobile_number}</span>
                                    </div> */}
                                    {isLoading ? <Spinner width='25px' height='25px' margin='0 auto' /> : <button disabled={!formik_props.values.feedback_message} type="submit" className="btn btn-primary btn-block btn_submit globel_btn">Send Feedback</button>}
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </Oux>
    }

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    return step
}

export default SubmitTicket