import React from 'react'
import Spinner from '../../UI/Spinner/Spinner'
import { Form, Formik, Field } from 'formik'
import { validateForgotPasswordForm } from '../../FormValidation/FormValidation'
import { connect } from 'react-redux'
import * as actionTypes from '../../../redux/actions/index'
import { routes } from '../../../utility/constants/constants'
import './ForgotPassword.css'
import Oux from '../../../hoc/Oux/Oux'

const forgotPassword = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    return (
        <Oux>
            <div class="clearfix"></div>
            <div className="container-login100">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <Formik
                        initialValues={{}}
                        onSubmit={(values) => props.forgotPassword({ email: values.email, type: 'Forgot' })}
                        validationSchema={validateForgotPasswordForm} >
                        {(formik_props) => {
                            const errors = formik_props.errors;
                            const touched = formik_props.touched;
                            return (
                                <div className="theme_form_outer">
                                    <center><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.apiError ? props.apiError.message === 'EMAIL_NOT_FOUND' ? 'Email Not Found!' : props.apiError.message === 'INVALID_EMAIL' ? 'Invalid Email!' : null : null}</span></center>
                                    <Form className="theme_form">
                                        <div className="form-group">
                                            <Field type="email" style={errors.email && touched.email ? errorStyle : null} name="email" className="form-control theme_input" placeholder="Email Address" />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.email && touched.email && errors.email}</span>
                                        </div>
                                        {props.isLoading
                                            ? <Spinner width='25px' height='25px' margin='0 auto' />
                                            : <button type="submit" disabled={!formik_props.dirty || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Submit</button>}
                                        <div className="form_bottom_info mt-2">
                                            <a onClick={() => props.history.push(routes.LOGIN)} href="javascript:void(0)" className="theme_link form-submit-btn back-button">
                                                <img className="back-icon" src="images/icons/icn_back-button.svg" />
                                                <img className="hover-back-icon" src="images/icons/icn_back-button_hover.svg" />
                                                <span> Back To Login</span>
                                            </a>
                                        </div>
                                    </Form>
                                </div>
                            )
                        }}
                    </Formik>
                </div>
                <div className="login100-more">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                            <div className="logo_img">
                                <img src="images/icons/logo_lg.svg" alt="FinancePal" />
                            </div>
                            <div className="cst_checkbox_block">
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Easy and intuitive to use</span>
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Save time and money</span>
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Helpful tools to manage your taxes and finances</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Oux>
    )
}

const mapState = state => {
    return {
        isLoading: state.authReducer.isloading,
        apiError: state.authReducer.error
    }
}

const mapDispatch = dispatch => {
    return {
        forgotPassword: (credentials) => dispatch(actionTypes.forgotPassword(credentials))
    }
}

export default connect(mapState, mapDispatch)(forgotPassword)