import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import ContactUsPage from '../../components/ContactUs/ContactUs';
import axios from '../../config'
import { API_VERSION, NS_TOKEN } from '../../config'
import * as actionTypes from '../../redux/actions/index'

class Contactus extends React.Component {

    state = {
        contactUs: null,
        isLoading: true
    }

    // componentDidMount() {
    //     const formData = new FormData()
    //     formData.append('caseid','614491')
    //     axios.post(API_VERSION() + 'CTaxMobileContactUs.php', formData, {
    //         headers: {
    //             'ns-token': NS_TOKEN,
    //         },
    //     }).then(response => {
    //         console.log(response.data)
    //         this.setState({ contactUs: response.data, isLoading: false })
    //         return response.data
    //     }).catch(error => {
    //         this.setState({isLoading: false})
    //         return error
    //     })
    // }

    render() {
        return (
            <main className="main_section">
                <div className="main_cont">
                    <div className="container-fluid fp_pl_0 fp_sub_sidenav_outer">
                        <ContactUsPage email={this.props.user.email} submitTicket={this.props.submitTicket} caseid={this.props.user.caseid} isLoading={this.state.isLoading} history={this.props.history} isLoggedIn={this.props.isLoggedIn} contactUsPage={this.state.contactUs} />
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    isLoggedIn: state.authReducer.isLoggedIn,
});

const mapStateToDispatch = (dispatch) => ({
    submitTicket: (credentials, values) => dispatch(actionTypes.submitTicket(credentials, values))
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Contactus));


