import axios from 'axios';
export const BASE_URL = () => {  
  let url;
  let env = process.env.REACT_APP_ENV.trim();
  if (env === 'development') {
    url = 'https://sugarapi.ctaxcrm.com/'
  }
  if (env === 'staging') {
    url = 'https://sugarapi.ctaxcrm.com/'
  }
  if (env === 'production') {
    console.log("production if");
    url = 'https://sugarapi.ctaxcrm.com/'
  }
  return url;
};


export const DOC_SIGNING_REDIRECT_URI = () => {
  let url;
  if (process.env.REACT_APP_ENV.trim() === 'development') {
    url = 'http://localhost:3000';
  }
  if (process.env.REACT_APP_ENV.trim() === 'staging') {
    url = 'https://stage-fianancialpal.bitcotapps.com';
  }
  if (process.env.REACT_APP_ENV.trim() === 'production') {
    console.log("production if");
    url = 'https://app.financepal.com';
  }
  return url;
};

export const API_VERSION = () => {
  let version;
  let env = process.env.REACT_APP_ENV.trim();
  if (env === 'development') {
    version = 'mobile-app/'
  }
  if (env === 'staging') {
    version = 'mobile-app/'
  }
  if (env === 'production') {
    console.log("production if");
    version = 'mobile-api/'
  }
  return version;
};

export const NS_TOKEN = "b5293bbc-f7a8-11e8-a44a-6299d9bbe373"

const instance = axios.create({
  baseURL: BASE_URL()
});

export const DocuSignKey = {
  integratorKey: "74abc820-ce7b-4eff-9636-f51c39baf7bf",
  email: "559f34c9-0686-4c67-a166-505f54b32b40",
  password: "test_ngisucod"
}

export default instance;