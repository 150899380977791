import React from 'react'
import Oux from '../../hoc/Oux/Oux';
import { Formik, Form, Field } from 'formik';
import Spinner from '../UI/Spinner/Spinner'
import { validate_password_field } from '../FormValidation/FormValidation';
import storage from '../../utility/storage';
import { isTokenValid } from '../../utility/constants/constants';

const freeTaxChangePassword = props => {
    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }
    return (
        <Oux>
            <div class="clearfix"></div>
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <article className="theme_login_txt">
                            <h1>Add Your New Password</h1>
                        </article>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{}}
                            onSubmit={(values) => {
                                const isIdTokenValid = isTokenValid(props.idToken)
                                if (isIdTokenValid) {
                                    props.toggleStepLoader(true)
                                    props.resetPassword({ type: 'ChangePassword', password: values.password, idToken: props.idToken })
                                        .then(response => {
                                            if ((response.value && response.value.result) && (response.value.result.toLowerCase() === "success" || response.value.result.toLowerCase() === "success!")) {
                                                props.setChangePasswordStatus({ type: 'ChangePasswordFlag', id: props.id })
                                                    .then(response => {
                                                        props.getUser({ type: 'GetUser', id: props.id })
                                                            .then(response => {
                                                                const freeTaxNumberVerifiedStatus = storage.get("freeTaxNumberVerifiedStatus", null)
                                                                const freeTax8821ContractStatus = storage.get("freeTax8821ContractStatus", null)
                                                                if (response.value.changepassword && response.value.changepassword.toLowerCase() === "no") {
                                                                    storage.set('freeTaxChangePasswordStatus', response.value.changepassword);
                                                                    props.toggleFreeTaxRedirected(true, {
                                                                        freeTaxChangePasswordStatus: response.value.changepassword,
                                                                        freeTax8821ContractStatus: freeTax8821ContractStatus,
                                                                        freeTaxNumberVerifiedStatus: freeTaxNumberVerifiedStatus
                                                                    })
                                                                }
                                                                props.toggleStepLoader(false)
                                                            }).catch(error => {
                                                                props.toggleStepLoader(false)
                                                            })
                                                    }).catch(error => {
                                                        props.toggleStepLoader(false)
                                                    })
                                            } else if (response.value.error && response.value.error.message && (response.value.error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN' || response.value.error.message === 'INVALID_ID_TOKEN')) {
                                                props.toggleStepLoader(false)
                                                props.logoutUser({
                                                    caseid: storage.get("case_id", null),
                                                    userid: storage.get("userid", null),
                                                    type: 'Logout'
                                                })
                                            } else {
                                                props.toggleStepLoader(false)
                                            }
                                        }).catch(error => {
                                            props.toggleStepLoader(false)
                                            props.logoutUser({
                                                caseid: storage.get("case_id", null),
                                                userid: storage.get("userid", null),
                                                type: 'Logout'
                                            })
                                            console.log(error)
                                        })
                                } else {
                                    props.refreshToken({ type: 'IdToken', refreshtoken: props.refresh_token })
                                        .then(response => {
                                            if (response.value.idtoken) {
                                                props.toggleStepLoader(true)
                                                props.resetPassword({ type: 'ChangePassword', password: values.password, idToken: response.value.idtoken })
                                                    .then(response => {
                                                        if ((response.value && response.value.result) && (response.value.result.toLowerCase() === "success" || response.value.result.toLowerCase() === "success!")) {
                                                            props.setChangePasswordStatus({ type: 'ChangePasswordFlag', id: props.id })
                                                                .then(response => {
                                                                    props.getUser({ type: 'GetUser', id: props.id })
                                                                        .then(response => {
                                                                            const freeTaxNumberVerifiedStatus = storage.get("freeTaxNumberVerifiedStatus", null)
                                                                            const freeTax8821ContractStatus = storage.get("freeTax8821ContractStatus", null)
                                                                            if (response.value.changepassword && response.value.changepassword.toLowerCase() === "no") {
                                                                                storage.set('freeTaxChangePasswordStatus', response.value.changepassword);
                                                                                props.toggleFreeTaxRedirected(true, {
                                                                                    freeTaxChangePasswordStatus: response.value.changepassword,
                                                                                    freeTax8821ContractStatus: freeTax8821ContractStatus,
                                                                                    freeTaxNumberVerifiedStatus: freeTaxNumberVerifiedStatus
                                                                                })
                                                                            }
                                                                            props.toggleStepLoader(false)
                                                                        }).catch(error => {
                                                                            props.toggleStepLoader(false)
                                                                        })
                                                                }).catch(error => {
                                                                    props.toggleStepLoader(false)
                                                                })
                                                        } else if (response.value.error && response.value.error.message && (response.value.error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN' || response.value.error.message === 'INVALID_ID_TOKEN')) {
                                                            props.toggleStepLoader(false)
                                                            props.logoutUser({
                                                                caseid: storage.get("case_id", null),
                                                                userid: storage.get("userid", null),
                                                                type: 'Logout'
                                                            })
                                                        } else {
                                                            props.toggleStepLoader(false)
                                                        }
                                                    }).catch(error => {
                                                        props.toggleStepLoader(false)
                                                        console.log(error)
                                                    })
                                            } else {
                                                props.toggleStepLoader(false)
                                                props.logoutUser({
                                                    caseid: storage.get("case_id", null),
                                                    userid: storage.get("userid", null),
                                                    type: 'Logout'
                                                })
                                            }
                                        }).catch(error => {
                                            props.toggleStepLoader(false)
                                            props.logoutUser({
                                                caseid: storage.get("case_id", null),
                                                userid: storage.get("userid", null),
                                                type: 'Logout'
                                            })
                                            console.log(error)
                                        })
                                }
                            }}>
                            {(formik_props) => {
                                const errors = formik_props.errors;
                                const touched = formik_props.touched;
                                return (
                                    <Oux>
                                        <center><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.apiError ? props.apiError.message : null}</span></center>
                                        <Form className="theme_form">
                                            <div className="form-group">
                                                <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.password && touched.password && errors.password}</span>
                                                <Field type="password" validate={validate_password_field} style={errors.password && touched.password ? errorStyle : null} name="password" className="form-control theme_input" placeholder="Password" />
                                            </div>
                                            {props.isLoading
                                                ? <Spinner width='25px' height='25px' margin='0 auto' />
                                                : <button type="submit" disabled={!formik_props.dirty || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Submit</button>}
                                        </Form>
                                    </Oux>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </Oux >
    )
}

export default freeTaxChangePassword