import axios from '../config';
import { API_ADMIN as adminAxios } from '../config';
import { API_VERSION, NS_TOKEN } from '../config'
import directAxios from 'axios'

export const login = (credentials) => {
    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('password', credentials.password)
    formData.append('type', credentials.type)
    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const checkEmail = (credentials) => {
    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('type', credentials.type)
    return axios.post(API_VERSION() + 'CTaxMobileSignUp.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const getUser = (credentials) => {
    const formData = new FormData()
    formData.append('id', credentials.id)
    formData.append('type', credentials.type)
    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const checkUser = (credentials) => {
    const formData = new FormData()
    formData.append('phone', credentials.phone)
    formData.append('type', credentials.type)
    if (credentials.email) {
        formData.append('email', credentials.email)
    }
    return axios.post(API_VERSION() + 'CTaxMobileSignUp.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const submitOTP = (credentials) => {
    const formData = new FormData()
    formData.append('phoneno', credentials.phoneno)
    formData.append('type', credentials.type)
    formData.append('code', credentials.otp_number)
    return axios.post(API_VERSION() + 'CTaxMobileTwilio.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const submitTicket = (credentials) => {
    const formData = new FormData()
    formData.append('phone', credentials.phone)
    formData.append('email', credentials.email)
    formData.append('caseid', credentials.caseid)
    formData.append('message', credentials.message)
    return axios.post(API_VERSION() + 'CTaxMobileEmail.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const sendOTP = (credentials) => {
    const formData = new FormData()
    formData.append('phoneno', credentials.phoneno)
    formData.append('type', credentials.type)
    return axios.post(API_VERSION() + 'CTaxMobileTwilio.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const signup = (credentials) => {
    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('type', credentials.type)
    formData.append('phone', credentials.phone)
    formData.append('caseid', credentials.caseid)
    formData.append('passcode', credentials.passcode)
    formData.append('password', credentials.password)
    return axios.post(API_VERSION() + 'CTaxMobileSignUp.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const forgotPassword = (credentials) => {

    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const resetPassword = (credentials) => {
    const formData = new FormData()
    formData.append('password', credentials.password)
    formData.append('idtoken', credentials.idToken)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const logout = (credentials) => {
    const formData = new FormData()
    formData.append('userid', credentials.userid)
    formData.append('caseid', credentials.caseid)
    formData.append('type', credentials.type)

    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};

export const refreshToken = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }

    return axios.post(API_VERSION() + 'CTaxMobileSignIn.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        // defaultErrorHandler: false
    });
};
