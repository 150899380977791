import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import Spinner from '../../UI/Spinner/Spinner'

import './BusinessInformation.css'
import { InputField } from '../../UI/FormInputFields/FormInputFields'
import { validateBusinessInformation } from '../../FormValidation/FormValidation'
import Skeleton from 'react-loading-skeleton'
import Oux from '../../../hoc/Oux/Oux'
import $ from 'jquery'


const AddBusinessInfoForm = props => {

    console.log(props.businessUser)

    return (
        <div className={props.divClassName} style={props.style ? props.style : null}>
            <Formik
                initialValues={props.businessUser
                    ? {
                        businessname: props.businessUser.businessname,
                        street: props.businessUser.street,
                        city: props.businessUser.city,
                        state: props.businessUser.state,
                        postalcode: props.businessUser.postalcode,
                        phonenumber: props.businessUser.phonenumber,
                        email: props.businessUser.email
                    }
                    : {}}
                onSubmit={(values) => {
                    console.log(props.caseid)
                    props.updateBusinessInfo({ type: 'UpdateUserBusiness', caseid: props.caseid, ...values })
                    props.toggleFormHidden()
                }}
                enableReinitialize={true}
                validationSchema={validateBusinessInformation}>
                {(formik_props) => {
                    console.log(formik_props)
                    return (
                        <Form>
                            <div className="form-group">
                                <InputField form_class_name="business-information-form" name="businessname" label="Business Name" type="text" />
                            </div>
                            <div className="form-group">
                                <InputField form_class_name="business-information-form" name="street" label="Address" type="text" />
                            </div>
                            {/* <div className="form-group">
                                <InputField form_class_name="business-information-form" name="address_line_2" label="Address Line 2" type="text" />
                            </div> */}
                            <div className="form-group">
                                <InputField form_class_name="business-information-form" name="city" label="City" type="text" />
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField form_class_name="business-information-form" name="state" label="State" type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <InputField form_class_name="business-information-form" name="postalcode" label="Zip Code" type="text" />
                                    </div>
                                </div>
                            </div>
                            {/* <a href="JavaScript:void(0);" className="btn btn_secondary cst_btn mb-3"><i
                                className="fa fa-plus-circle mr-1"></i>Add Address</a> */}
                            <div className="form-group">
                                <InputField form_class_name="business-information-form" name="phonenumber" label="Phone Number" type="text" />
                            </div>
                            {/* <div className="form-group">
                                <InputField form_class_name="business-information-form" name="email" label="Email Address" type="text" />
                            </div> */}
                            {
                                props.viewExistingBusinessDetail
                                    ? props.isLoading
                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                        : <div className="btn_block">
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (formik_props.errors && Object.keys(formik_props.errors).length === 0) {
                                                        props.updateBusinessInfo({ type: 'UpdateUserBusiness', caseid: props.caseid, ...formik_props.values, indexid: props.businessUser.indexid })
                                                        setTimeout(() => {
                                                            props.toggleExistingBusinessInfoForm()
                                                        }, 1000)
                                                    }
                                                }}
                                                className="btn btn-primary cst_btn mb-3">Update</button>
                                            <button
                                                type="button"
                                                data-toggle="modal" data-target="#myModal"
                                                className="btn btn-danger cst_btn">Delete</button>
                                        </div>
                                    : null

                            }
                            {
                                props.viewHiddenForm
                                    ? props.isLoading
                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                        : <button disabled={!formik_props.dirty || !formik_props.isValid} type="submit" class="btn btn-primary cst_btn">Save</button>
                                    : null
                            }
                        </Form>
                    )
                }}
            </Formik>
            {/* <!-- MODAL --> */}
            <div class="modal" id="myModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Delete Business Information</h5>
                            <button class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            This action will erase your business details. Are You Sure You Want To Delete ?
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" data-dismiss="modal">No</button>
                            <button onClick={() => {
                                props.deleteBusinessInfo({ type: 'DeleteBusiness', indexid: props.businessUser.indexid })
                                props.toggleExistingBusinessInfoForm()
                            }} class="btn btn-danger" data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const BusinessInformation = props => {

    let [formHidden, setFormHidden] = useState(true)
    let [existingBusinessInfoForm, setExistingBusinessInfoForm] = useState(false)
    let [businessInfo, setBusinessInfo] = useState(null)

    console.log(props.businessUser, 'Business Info')

    useEffect(() => {
        // do something on route change
        // for my example, close a drawer
        console.log(props)
        props.getUserBusiness({ type: 'GetUserBusiness', caseid: props.caseid })
    }, [])

    const toggleFormHidden = () => {
        setExistingBusinessInfoForm(false)
        setFormHidden(!formHidden)
    }

    const toggleExistingBusinessInfoForm = () => {
        setFormHidden(true)
        setExistingBusinessInfoForm(!existingBusinessInfoForm)
    }

    const addBusiness = () => {
        setExistingBusinessInfoForm(false);
        setFormHidden(!formHidden);
    }

    const closeExistingForm = () => {
        window.scroll(0, 0);
        setBusinessInfo(null)
        setExistingBusinessInfoForm(false)
    }

    return (
        <div className="tab-pane fade active show" id="v-pills-Business-Information" role="tabpanel"
            aria-labelledby="v-pills-Business-Information-tab">
            <div className="fb_tabs_inner fp_tabs_cont contact_us business_information doc_tab_content">
                <div className="fp_tabs_inner_40 fp_tabs_cell">
                    <div className="fp_invs_inner_block">
                        <h1 className="fp_sec_title">Business Information
                                    {
                                props.businessUser
                                    ? <a onClick={addBusiness} className="add_icn">
                                        <img src="images/icons/icn_plus.png" alt="Icon" />
                                    </a>
                                    : null
                            }
                        </h1>
                    </div>
                    <div className="business_info_content">
                        {props.businessUser
                            ? null
                            : props.isLoading
                                ? <Oux><Skeleton height={100} /><br /><br /><Skeleton height={100} /></Oux>
                                : <div>
                                    <p>It seems you have not added <br /> information for your business yet.</p>
                                    <a onClick={addBusiness} className="btn btn-primary cst_btn">Add Business</a>
                                </div>
                        }
                    </div>
                    {
                        formHidden
                            ? null
                            : <AddBusinessInfoForm
                                isLoading={props.isSubmitLoading}
                                updateBusinessInfo={props.updateBusinessInfo}
                                viewHiddenForm={!formHidden}
                                toggleFormHidden={toggleFormHidden}
                                toggleExistingBusinessInfoForm={toggleExistingBusinessInfoForm}
                                caseid={props.caseid}
                                divClassName="business_info_content" />
                    }
                    <div className="fp_timeline_block">

                        {
                            props.businessUser
                                ? props.businessUser.map((business, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                $(".target_user_pro").removeClass("target_user_pro_active");
                                                if (!formHidden) {
                                                    setBusinessInfo(null)
                                                }
                                                if (businessInfo && businessInfo.indexid === business.indexid) {
                                                    setBusinessInfo(null)
                                                    setExistingBusinessInfoForm(false)
                                                }
                                                else {
                                                    // setIsLoading(true)
                                                    setFormHidden(true)
                                                    $("#target_user_pro"+index).addClass("target_user_pro_active");
                                                    if (window.screen.width <= 768) {
                                                        $('html, body').animate({
                                                            scrollTop: $("#existing_business_details").offset().top
                                                        }, 2000);
                                                    }
                                                    else {
                                                        window.scroll(0, 0)
                                                    }
                                                    setBusinessInfo(business)
                                                    setFormHidden(true)
                                                    setExistingBusinessInfoForm(true)

                                                }
                                            }}
                                            style={{ cursor: 'pointer' }}
                                            className="timeline-item add_info_box target_user_pro"
                                            id={"target_user_pro"+index}>
                                            <div className="fp_inner_body pl_0 pr_0">
                                                <h4 className="item-title pl_15 pr_15">{business.businessname}</h4>
                                                <a href="javascript:void(0)" className="item-title pl_15 pr_15 business-info fp_ativity_icons">
                                                    <span class="fp_ativity_icons">
                                                        <span className="fp_activity_icn_txt">{business.street}&ensp;{business.city}&ensp;{business.postalcode}&ensp;{business.state}</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                </div>
                <div className="fp_tabs_inner_60 fp_tabs_cell">
                    <div class="bi_add_details">
                        {existingBusinessInfoForm ? <button className="close_existing_business_form_button" type="button"><img onClick={closeExistingForm} src="/images/customs/icn_close.svg" /></button> : null}
                        <div className="fp_inner_user_pro" id="existing_business_details">
                            {
                                existingBusinessInfoForm
                                    ? <AddBusinessInfoForm
                                        isLoading={props.isSubmitLoading}
                                        updateBusinessInfo={props.updateBusinessInfo}
                                        deleteBusinessInfo={props.deleteBusinessInfo}
                                        viewExistingBusinessDetail={existingBusinessInfoForm}
                                        toggleExistingBusinessInfoForm={toggleExistingBusinessInfoForm}
                                        style={{ display: 'block', paddingTop: '0px', top: '0px' }}
                                        businessUser={businessInfo}
                                        caseid={props.caseid}
                                        divClassName="fp_media_user_pro fp_media_user_pro_block account_business_info" />
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessInformation