import React from 'react';
import { APIType, ServiceAction, routes, dateFormat } from '../../utility/constants/constants';
import { servicesRequestApi, fetchDocuSignURL } from '../../api/servicesAPI';
import Skeleton from 'react-loading-skeleton';
import Spinner from '../../components/UI/Spinner/Spinner'
import { getDateKey } from '../../utility/utility';
import './ServiceSectionDetail.css';
import BillingInformation from '../../components/MyAccount/BillingInformation/BillingInformation';
import { connect } from 'react-redux'
import * as actionTypes from '../../redux/actions/index'
import { createBillingPayment } from '../../api/myAccountApi';
import { formatServiceName, toastMsg } from '../../utility/utility';
import { DOC_SIGNING_REDIRECT_URI } from '../../config';
import DragAndDrop from '../../components/UI/DragAndDrop/DragAndDrop';
import $ from 'jquery'
import Oux from '../../hoc/Oux/Oux';

class ServiceSectionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showContactDetail: false,
            contactDetails: null,
            showUploadOption: false,
            showPaymentOption: false,
            isFetchingData: false,
            selectedCard: null,
            file: null,
            selectedServiceSection: null
        }
        this.fileInputRef = React.createRef();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.userBillingDetails === null && this.props.userBillingDetails !== null) {
            this.props.userBillingDetails.map(billingDetails => {
                if (billingDetails.PrimaryAccount) {
                    this.setState({ selectedCard: billingDetails })
                }
            })
        }
        if (prevProps.service !== this.props.service) {
            this.setState({ showPaymentOption: false, showContactDetail: false })
        }
    }

    getSelectedCard = (cardDetail) => {
        console.log(cardDetail)
        this.setState({ selectedCard: cardDetail })
    }

    continuePayment = (selectedCard, selectedService) => {
        let amount = null
        let pay_type = null
        if (selectedService.serviceid === this.props.serviceData.serviceid) {
            this.props.serviceData.servicesection.map((ele, index) => {
                if (ele.action === ServiceAction.pay.text && ele.servicereqestid === this.state.selectedServiceSection.servicereqestid) {
                    amount = ele.attachment.amount
                }
            })
        }
        if (selectedCard.AccountType === 2) {
            pay_type = '1'
        }
        else if (selectedCard.AccountType === 3) {
            pay_type = '2'
        }
        this.toggleLoading(true);
        createBillingPayment({
            caseid: this.props.user.caseid,
            type: 'CasePaymentCreate',
            pay_type: pay_type,
            amount: amount,
            comment: selectedService.serviceid
        })
            .then(response => {
                if (response.data.result === 'Success' || response.data.result === 'Success!') {
                    toastMsg('Payment Done Successfully')
                }
                this.props.reloadService(selectedService);
                this.setState({ showPaymentOption: false })
                this.toggleLoading(false);
                console.log(response)
            }).catch(error => {
                console.log(error)
                this.setState({ showPaymentOption: false })
                this.toggleLoading(false);
            })
    }

    fetchSigningURL = (serviceSection) => {
        let service = this.props.selectedService;
        let formattedServiceName = formatServiceName(service.name);
        this.setState({
            selectedServiceSection: serviceSection,
            showContactDetail: false,
            showUploadOption: false,
            showPaymentOption: false,
        });

        let request = {
            envid: serviceSection.attachment.envelopeid,
            returnurl: `${DOC_SIGNING_REDIRECT_URI()}/service/${formattedServiceName}`
        }
        this.toggleLoading(true);
        fetchDocuSignURL(request)
            .then(res => {
                let url = res.data.url;
                window.location.replace(url)
                // const win = window.open(url, 'blank');
                // win.focus();
                this.toggleLoading(false);
            }).catch(error => {
                console.log(error);
                this.toggleLoading(false);
            });
    }

    serviceActionHandler = (serviceSection) => {
        let action = serviceSection.action
        console.log("action clicked:" + action);
        window.scroll(0, 0);
        //click on upload - select a file
        if (action === ServiceAction.upload.text && !this.state.file) {
            this.setState({
                showContactDetail: false,
                showUploadOption: true,
                showPaymentOption: false,
                selectedServiceSection: serviceSection
            });
            return;
        } else if (action === ServiceAction.pay.text) {
            this.setState({
                showPaymentOption: true,
                showContactDetail: false,
                showUploadOption: false,
                selectedServiceSection: serviceSection
            })
            return;
        } else {
            this.setState({
                selectedServiceSection: serviceSection
            })
        }
        let service = this.props.selectedService;
        let type = ServiceAction[action].type;
        let request = {}
        if (action === ServiceAction.upload.text) {
            // this is called on file selection
            request = {
                type: type,
                servicerequestid: serviceSection.servicereqestid,
                file: this.state.file,
                comment: service.name
            }
        }
        this.toggleLoading(true);

        servicesRequestApi(request)
            .then(res => {
                console.log(res);
                if (action === ServiceAction.signDoc.text) {
                    let url = res.data.link;
                    const win = window.open(url, '_blank');
                    win.focus();
                } else if (action === ServiceAction.pay.text) {
                    // this.setState({ showPaymentOption: false })
                    // this.props.reloadService(this.props.service);
                } else if (action === ServiceAction.providerInfo.text) {
                    let url = res.data.web;
                    const win = window.open(url, '_blank');
                    win.focus();
                } else if (action === ServiceAction.upload.text) {
                    this.setState({
                        showUploadOption: false,
                        file: null,
                    });
                    this.props.reloadService(this.props.service);
                    console.log("upload done!");
                } else {
                    //Announcement - ??
                }

                this.toggleLoading(false);
            }).catch(error => {
                console.log(error);
                this.toggleLoading(false);
            });

    }

    toggleLoading = (loading) => {
        this.setState({
            isFetchingData: loading,
        });
    }

    fetchContactDetails = (contact) => {
        this.setState({
            ...this.state,
            contactDetails: contact,
            showContactDetail: true,
            showPaymentOption: false
        });
    }

    openFileDialog = () => {
        if (this.state.isFetchingData) {
            return;
        }
        this.fileInputRef.current.click();
    }

    onFilesAdded = (e) => {
        let file = e && e.target ? e.target.files[0] : e[0];
        let type = file.type
        if (["image/png", "image/jpeg", "image/jpg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"].includes(type)) {
            this.setState({
                ...this.state,
                file: file
            }, () => { this.serviceActionHandler(this.state.selectedServiceSection) });
        } else {
            toastMsg("Only images and pdfs are allowed!", true);
        }

    }

    render() {
        ;

        console.log(this.state.selectedCard)

        let serviceSectionsContent = [];

        let { service, serviceData } = this.props;
        let { showContactDetail, contactDetails, showUploadOption, showPaymentOption } = this.state;
        let name = service ? service.name : "";
        let { servicesection, status } = serviceData ? serviceData : {};
        let progressContentClasses = ["tab_disabled", "tab_disabled", "tab_disabled"];

        if (status) {
            if (status.toLowerCase() === "requested") {
                progressContentClasses = ["tab_disabled", "tab_disabled", "tab_disabled"];
            } else if (status.toLowerCase() === "enrollment") {
                progressContentClasses = ["tab_inactive", "tab_disabled", "tab_disabled"];
            } else if (status.toLowerCase() === "active") {
                progressContentClasses = ["tab_inactive", "tab_inactive", "tab_disabled"];
            } else if (status.toLowerCase() === "completed") {
                progressContentClasses = ["tab_inactive", "tab_inactive", "tab_inactive"];
            }
        }
        if (servicesection) {
            if (servicesection.length === 0) {
                serviceSectionsContent = (
                    <div class="empty_details">
                        <div class="card">
                            <div class="card-body">
                                <div class="empty_img">
                                    <img src="/images/icons/empty_doc.png" alt="Icon" />
                                </div>
                                <h3 class="h3_title">Currently, there is no service request</h3>
                                {/* <p class="cst_para">oops! seems like there's<br />Nothing in this folder yet</p> */}
                            </div>
                        </div>
                    </div>
                )
            } else {
                let className = servicesection.length > 1 ? "timeline" : ""
                serviceSectionsContent = (
                    <div className={className}>
                        {
                            servicesection.map((ele, index) => {
                                if (ele.action === ServiceAction.signDoc.text) {
                                    return <ServiceSection status={status} key={index} fetchContactDetails={() => this.fetchContactDetails(ele.contact)} {...ele} serviceActionHandler={() => this.fetchSigningURL(ele)} isFetchingData={this.state.isFetchingData && ele.servicereqestid === this.state.selectedServiceSection.servicereqestid} />
                                } else {
                                    return <ServiceSection status={status} key={index} fetchContactDetails={() => this.fetchContactDetails(ele.contact)} {...ele} serviceActionHandler={() => this.serviceActionHandler(ele)} isFetchingData={false} />
                                }
                            })
                        }
                    </div>
                );
            }

        } else {
            serviceSectionsContent = (
                <div className="">
                    {
                        [...Array(5)].map((ele, index) => {
                            return <ServiceSection status={status} key={index} isLoading={true} />
                        })
                    }
                </div>
            );
        }

        let email = contactDetails && contactDetails.email;
        let phone = contactDetails && contactDetails.phone
        let mailTo = `mailto:${email}`;
        let phoneTo = `tel:${phone}`;

        return (
            <div className="tab-pane fade show active"
                id={`v-pills-${formatServiceName(name)}`}
                role="tabpanel"
                aria-labelledby={`v-pills-${name}-tab`}>
                <div className="fb_tabs_inner">
                    <div className="fp_tabs_inner_40">
                        <div className="fp_invs_inner_block">
                            {/* <a href="#" className="btn btn-outline-primary btn_radius_50 fp_btn">IN PROGRESS</a> */}
                            <h1 className="fp_sec_title">{name}</h1>
                        </div>
                        <div className="navigation_menu" id="navigation_menu">
                            <ul className="navigation_tabs" id="navigation_tabs">
                                <li className={progressContentClasses[0]}>
                                    <a>Enrollment</a>
                                </li>
                                <li className={progressContentClasses[1]}>
                                    <a>Active</a>
                                </li>
                                <li className={progressContentClasses[2]}>
                                    <a>Completed</a>
                                </li>
                            </ul>
                        </div>
                        <div className="fp_timeline_block">
                            {serviceSectionsContent}
                        </div>
                    </div>
                    <div className="fp_tabs_inner_60" style={{ height: 'auto' }}>
                        <div className="fp_inner_user_pro">

                            <div className="fp_media_user_pro fp_media_user_pro_block" style={showContactDetail ? { display: "block" } : { display: "none" }}>
                                <div className="media align-items-center">
                                    <span className="fp_avatar_32 mr-3">
                                        <img src="/images/icons/user.jpg" alt="Generic placeholder image" />
                                    </span>
                                    <div className="media-body">
                                        <h5 className="mt-0">{contactDetails && contactDetails.name}</h5>
                                    </div>
                                </div>
                                <div className="fp_contact"><i className="fa fa-phone mr-1"></i> <a href={phoneTo}>{contactDetails && contactDetails.phone}</a></div>
                                <div className="fp_contact">
                                    <i className="fa fa-send mr-1"></i> <a href={mailTo} target="blank" className="theme_link theme_de_underline">{contactDetails && contactDetails.email}</a>
                                </div>
                            </div>

                            <div className="fp_media_user_pro fp_upload_bank_statement" style={showUploadOption ? { display: "block" } : { display: "none" }}>
                                <h3 className="mt-0">Upload Bank Statements</h3>
                                <div className="card fp_bank_state_card">
                                    <div className="card-body text-center">
                                        <DragAndDrop files={this.state.file} uploadFile={this.onFilesAdded}>
                                            <div style={{ cursor: 'pointer' }} className="card_inner_body">
                                                <img src="/images/icons/icn_upload_gray.png" alt="Upload" className="card-title" />
                                                {this.state.isFetchingData && showUploadOption ?
                                                    <Spinner width={25} height={25} /> :
                                                    <p className="card-text">Drag and drop files here</p>
                                                }
                                            </div>
                                        </DragAndDrop>
                                    </div>
                                    <a onClick={this.openFileDialog} className="btn btn-primary fb_style2 fp_btn "> <img src="/images/icons/icn_down_white.png" alt="Upload" /> {this.state.isFetchingData ? "Uploading..." : "Upload"}</a>
                                    <input
                                        ref={this.fileInputRef}
                                        className="FileInput"
                                        type="file"
                                        onChange={this.onFilesAdded}
                                    />
                                </div>
                            </div>
                            {
                                showPaymentOption
                                    ? <div className="fp_media_user_pro fp_media_user_pro_block" style={showPaymentOption ? { display: "block" } : { display: "none" }}>
                                        <div className="media align-items-center">
                                            {/* <span className="fp_avatar_32 mr-3">
                                        <img src="/images/icons/user.jpg" alt="Generic placeholder image" />
                                    </span> */}
                                            <div className="media-body">
                                                <h4 className="mt-0" style={{ fontWeight: 600 }}>Total Payment</h4>
                                                {
                                                    this.props.selectedService && serviceData
                                                        ? this.props.selectedService.serviceid === serviceData.serviceid
                                                            ? serviceData.servicesection.map((ele, index) => {
                                                                if (ele.action === ServiceAction.pay.text && ele.servicereqestid === this.state.selectedServiceSection.servicereqestid) {
                                                                    return <h5 className="mt-0" style={{ fontWeight: 600, marginBottom: '0.5rem' }}>$ {ele.attachment.amount}</h5>
                                                                }
                                                            })
                                                            : null
                                                        : null
                                                }
                                                <p style={{ marginBottom: '0.5rem' }}>{this.props.selectedService ? this.props.selectedService.name : null}</p>
                                                {
                                                    this.state.isFetchingData
                                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                                        : <button disabled={this.state.selectedCard ? false : true} type="button" onClick={() => this.continuePayment(this.state.selectedCard, this.props.selectedService)} className="btn btn-primary">Continue</button>
                                                }
                                                <br />
                                                <br />
                                                <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)', marginTop: '0', marginBottom: '0' }} />
                                            </div>
                                        </div>
                                        {/* <div className="fp_contact"><i className="fa fa-phone mr-1"></i> <a href={phoneTo}>{contactDetails && contactDetails.Phone}</a></div>
                                <div className="fp_contact">
                                    <i className="fa fa-send mr-1"></i> <a href={mailTo} target="blank" className="theme_link theme_de_underline">{contactDetails && contactDetails.Email}</a>
                                </div> */}
                                        {/* <MyAccount hideSidebar={true} currentUrl = {routes.MYACCOUNT+routes.BILLING_INFORMATION} /> */}
                                        <BillingInformation
                                            caseid={this.props.user.caseid}
                                            saveUserBillingDetails={this.props.saveUserBillingDetails}
                                            userBillingDetails={this.props.userBillingDetails}
                                            isLoading={this.props.isLoading}
                                            isMyAccountLoading={this.props.isMyAccountLoading}
                                            isSubmitLoading={this.props.isSubmitLoading}
                                            getUserBillingDetails={this.props.getUserBillingDetails}
                                            isServiceSectionPage={true}
                                            getSelectedCard={this.getSelectedCard} />
                                        {/* <center>
                                    <button style={{ width: '70%', marginLeft }} type="button" className="btn btn-primary">Continue</button>
                                </center> */}
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    isLoading: state.authReducer.isloading,
    isMyAccountLoading: state.myAccountReducer.isloading,
    isSubmitLoading: state.myAccountReducer.isSubmitButtonLoading,
    userBillingDetails: state.myAccountReducer.userBillingDetails
});

const mapDispatch = (dispatch) => ({
    getUserBillingDetails: (credentials) => dispatch(actionTypes.getUserBillingDetails(credentials)),
    saveUserBillingDetails: (credentials) => dispatch(actionTypes.saveUserBillingDetails(credentials)),
    // createBillingPayment: (credentials) => dispatch(actionTypes.createBillingPayment(credentials)),
});

export default connect(mapStateToProps, mapDispatch)(ServiceSectionDetail);

const ServiceSection = (props) => {
    let { isLoading, acceptedDate, action, requestedDate, contact, attachment, isFetchingData } = props;
    let status = null;
    let name = null;
    let attachmnt = null;
    let buttonLink = null;
    let infoContent = [];


    if (contact) {
        name = `${contact.name}`
    }

    let serviceActiveClass = "";

    if (action === ServiceAction.pay.text) {
        if (acceptedDate) {
            serviceActiveClass = "active"
            let date = new Date(acceptedDate.replace(/ /g, "T"));
            status = `Payment Completed on ${getDateKey(date)}`;
            infoContent.push(
                <a href="#" className="fp_ativity_icons target_user_pro service_section_status pl_15">
                    <span className="fp_activity_icn_txt">
                        <img src="/images/icons/icn_check_suc.svg" className="mr-1" /> {status}
                    </span>
                </a>)
        }
    } else if (action === ServiceAction.upload.text) {
        if (acceptedDate) {
            serviceActiveClass = "active"
            let date = new Date(acceptedDate.replace(/ /g, "T"));
            status = `Uploaded on ${getDateKey(date)}`;
            infoContent.push(
                <a href="#" className="fp_ativity_icons target_user_pro service_section_status pl_15">
                    <span className="fp_activity_icn_txt">
                        <img src="/images/icons/icn_check_suc.svg" className="mr-1" /> {status}
                    </span>
                </a>)
        }
        attachmnt = { name: attachment.file, link: attachment.file2 };
        infoContent.push(
            <a href={attachmnt.link} target="_blank" className="fp_ativity_icons target_user_pro service_section_status pl_15">
                <span className="fp_activity_icn_txt"><img src="/images/icons/icn_file_link.svg" className="mr-1" /> {attachmnt.name}</span>
            </a>
        )
    } else if (action === ServiceAction.providerInfo.text) {
        // attachmnt = { name: attachment, link: attachment };
        buttonLink = attachment;
    } else if (action === ServiceAction.Announcement.text) {
        buttonLink = attachment.link ? attachment.link : null;
        status = attachment.desc;
        infoContent.push(
            <span className="fp_ativity_icons target_user_pro service_section_status pl_15">
                <span className="fp_activity_icn_txt">
                    <img src="/images/icons/icn_bell_clr.svg" className="mr-1" /> {status}
                </span>
            </span>)
    }

    if (isLoading) {
        return (
            <div className="timeline-item">
                <div className="fp_inner_body pl_0 pr_0">
                    <h4 className="item-title pl_15 pr_15"><Skeleton height={20} />
                    </h4>
                    <a className="fp_ativity_icons target_user_pro">
                        <Skeleton width={160} />
                    </a>
                    <a className="fp_ativity_icons target_user_pro">
                        <Skeleton width={160} />
                    </a>
                    <a className="fp_ativity_icons target_user_pro">
                        <Skeleton width={160} />
                    </a>
                </div>
                <Skeleton height={40} />
            </div>
        );
    } else {
        let newReqDate = requestedDate ? new Date(requestedDate.replace(/ /g, "T")) : null
        return (
            <div className={`timeline-item ${serviceActiveClass}`}>
                <div className="fp_inner_body pl_0 pr_0">
                    <h4 className="item-title pl_15 pr_15">{props.name}</h4>
                    {requestedDate && <span className="requested_date_text"> {getDateKey(new Date(newReqDate))}</span>}
                    {props.contact &&
                        <Oux>
                            <a href="#" onClick={props.fetchContactDetails} className="fp_ativity_icons target_user_pro">
                                <span className="fp_activity_icn_txt">
                                    <img src="/images/icons/icn_user.svg" className="mr-1 icn_unactive" />
                                    <img src="/images/icons/icn_user_white.png" className="mr-1 icn_active" /> {name}
                                </span> <i className="fa fa-angle-right"></i>
                            </a>
                        </Oux>
                    }
                    {console.log(newReqDate, "reqqq")}
                    {infoContent}
                </div>
                {
                    buttonLink ?
                        props.status && props.status.toLowerCase() === "completed" || props.status.toLowerCase() === "cancelled"
                            ? <button disabled={true} class="fp_btn fb_style2 btn btn-primary fp_target_upl_statemet">
                                <img src={ServiceAction[props.action].icon} /> {ServiceAction[props.action].buttonTitle}
                                {/* <Spinner width={25} height={25} /> */}
                            </button>
                            : <a href={buttonLink} target="_blank" class="fp_btn fb_style2 btn btn-primary fp_target_upl_statemet">
                                <img src={ServiceAction[props.action].icon} /> {ServiceAction[props.action].buttonTitle}
                                {/* <Spinner width={25} height={25} /> */}
                            </a>
                        :
                        !status && ServiceAction[props.action].buttonTitle ?
                            <button disabled={props.status && props.status.toLowerCase() === "completed" || props.status.toLowerCase() === "cancelled" ? true : false} onClick={props.serviceActionHandler} class="fp_btn fb_style2 btn btn-primary fp_target_upl_statemet" style={{ color: "white" }}>
                                <img src={ServiceAction[props.action].icon} /> {ServiceAction[props.action].buttonTitle}

                                <div style={{ float: "right", width: "25px", height: "25px" }}>
                                    {isFetchingData && <Spinner width={25} height={25} class={"service_section_spinner"} />}
                                </div>
                            </button>
                            :
                            null
                }

            </div>
        );
    }
}
