import React from 'react'
import { Field } from 'formik'
import Oux from '../../../hoc/Oux/Oux'
import { TextField, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import './FormInputFields.css'
import { upload_dropdown_validate } from '../../FormValidation/FormValidation'

export const InputField = props => {
    return (
        <Field name={props.name}>
            {({ field, form }) => {
                const errors = form.errors
                const touched = form.touched
                return (
                    <TextField
                        className={props.form_class_name ? props.form_class_name : null}
                        inputProps={{ ...field}}
                        InputLabelProps={{ className: props.labelClassName ? props.labelClassName : null }}
                        label={props.label}
                        disabled={props.disabled ? props.disabled : false}
                        type={props.type}
                        margin="normal"
                        variant="outlined"
                        helperText={(errors[field.name] && touched[field.name]) && errors[field.name]}
                        error={errors[field.name] && touched[field.name] && errors[field.name]}
                        fullWidth
                    />
                )
            }}
        </Field>
    )
}

export const InputCheckbox = props => {
    return (
        <Oux>
            <Field
                type="checkbox"
                checked={props.values[props.name]}
                disabled={props.disabled ? props.disabled : false}
                onChange={props.onChange ? props.onChange : (event) => props.setFieldValue(props.name, event.target.checked)}
                name={props.name} className="custom-control-input" id={props.checkbox_id} />
            <label className="custom-control-label" htmlFor={props.checkbox_id}>{props.label ? props.label : null}</label>
        </Oux>
    )
}

export const SelectMenu = props => {
    return (
        <FormControl variant="outlined" className={props.form_class_name ? props.form_class_name : null}>
            <InputLabel id={props.labelId ? props.labelId : null}>
                {props.label ? props.label : null}
            </InputLabel>
            <Field as={Select} validate={upload_dropdown_validate} name={props.name ? props.name : null}>
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {
                    props.menuItems
                        ? props.menuItems.map((item, index) => {
                            return <MenuItem value={item}>{item}</MenuItem>
                        })
                        : null
                }
            </Field>
        </FormControl>
    )
}