import React from 'react'
import { withRouter } from 'react-router'
import { routes } from '../../utility/constants/constants'
import Oux from '../../hoc/Oux/Oux'

const ticketSubmitted = props => {

    let currentUrl = `${props.history.location.pathname}${props.history.location.hash}`
    let step = null

    if (currentUrl === routes.CONTACT_US + routes.TICKET_SUBMITTED) {
        step = <Oux>
            <div class="clearfix"></div>
            <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                <div className="theme_form_outer" style={{marginTop: '0px'}}>
                    <article className="theme_login_txt">
                        <figure className="theme_fig text-center">
                            <img className="theme_img" src="/images/icons/icn_ticket_suc.png" alt="Ticket Success" />
                        </figure>
                        <h1>Feedback Successfully Submitted</h1>
                        <p>Thank you. Your feedback is valuable to us.</p>
                    </article>
                    {/* <button type="submit" onClick={() => props.history.push(routes.LOGIN)} className="btn btn-primary btn-block btn_submit globel_btn">Continue To Account</button> */}
                </div>
            </div>
        </Oux>
    }

    else {
        step = <Oux>
            <div class="clearfix"></div>
            <div className="container-login100 no_hero_full">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <article className="theme_login_txt">
                            <figure className="theme_fig text-center">
                                <img className="theme_img" src="/images/icons/icn_ticket_suc.png" alt="Ticket Success" />
                            </figure>
                            <h1>Ticket Successfully Submitted</h1>
                            <p>We will contact you as soon as we manage to verify your information in our system.</p>
                            <p>Thank you for patience. You will be contacted as soon as possible by a member of our team to verify your account.</p>
                        </article>
                        <button type="submit" onClick={() => props.history.push(routes.LOGIN)} className="btn btn-primary btn-block btn_submit globel_btn">Continue To SIGN IN</button>
                    </div>
                </div>
            </div>
        </Oux>
    }

    return step
}

export default withRouter(ticketSubmitted)