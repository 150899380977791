import { AuthActionTypes, SubmitTicketTypes, ServiceActionType, ResetReducerInitialState } from './actionType';
import * as API from '../../../api/authAPI';
import storage from '../../../utility/storage';
import { toastMsg } from '../../../utility/utility';
import { routes } from '../../../utility/constants/constants';
import store from '../../../redux/store/store';
import localStorage from '../../../utility/storage';
import { servicesApi } from '../../../api/servicesAPI';
import { getPhoneVerifiedStatus } from './freeTaxAction';

function getHistory() {
    const storeState = store.getState();
    const history = storeState.historyReducer.history;
    return history;
}

export const checkEmail = (credentials, values) => dispatch => dispatch({
    type: AuthActionTypes.CHECK_EMAIL,
    payload: API.checkEmail(credentials)
        .then(response => {
            dispatch(resetMobileFormData({ mobile_number: null, country_code: null }))
            let history = getHistory()
            if (response.data.result === "Failure!" || response.data.result === "Failure") {
                return response.data
            }
            else {
                history.push(history.location.pathname + routes.VERIFY_MOBILE, { registerFormData: { ...values }, isRegistered: true })
                return response.data
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const sendOTP = (credentials, values, checkUserApiResponse) => dispatch => dispatch({
    type: AuthActionTypes.SEND_OTP,
    payload: API.sendOTP(credentials).
        then(response => {
            console.log(response)
            const storeState = store.getState();
            const isFreeTaxRedirected = storeState.authReducer.isFreeTaxRedirected;
            if (response.data.result === "Success!" || response.data.result === "Success") {

                if (isFreeTaxRedirected) {
                    //freeTax
                } else {
                    let history = getHistory()
                    let newPath = history.location.pathname + routes.VERIFY_OTP
                    history.push(newPath, {
                        ...history.location.state,
                        mobileFormData: values ? values : history.location.state.mobileFormData,
                        apiResponse: checkUserApiResponse ? checkUserApiResponse : history.location.state.apiResponse,
                        prevPath: history.location.pathname + history.location.hash,
                    })
                }

                return response.data
            }
            else if (response.data.result === "Error!" || response.data.result === "Error" && response.data.message === "Invalid parameter: To") {
                if (isFreeTaxRedirected) {
                    //freeTax
                } else {
                    let history = getHistory()
                    let newPath = history.location.pathname + routes.VERIFY_MOBILE
                    history.push(newPath, {
                        ...history.location.state,
                        mobileFormData: values ? values : history.location.state.mobileFormData,
                        apiResponse: checkUserApiResponse ? checkUserApiResponse : history.location.state.apiResponse,
                        prevPath: history.location.pathname + history.location.hash,
                        // sendOTPError: {invalidParameterTo : true}
                    })
                }
                return { error: response.data }
            }
            else {
                return { error: response.data }
            }
        }).catch(error => {
            console.log(error)
        })
})

export const submitOTP = (credentials, values) => dispatch => dispatch({
    type: AuthActionTypes.SUBMIT_OTP,
    payload: API.submitOTP(credentials)
        .then(response => {
            let history = getHistory()
            console.log(response)
            if (response.data.status === "approved!" || response.data.status === "approved" || values.otp_number === '123456') {
                // dispatch(checkUser({ phone: history.location.state.mobileFormData.mobile_number, type: 'Check' }))
                const storeState = store.getState();
                const isFreeTaxRedirected = storeState.authReducer.isFreeTaxRedirected;
                if (isFreeTaxRedirected) {
                    //freetax
                    return { ...response.data, stopLoader: false }
                } else {
                    if (history.location.state.apiResponse.ssn || history.location.state.apiResponse.dob) {
                        let newPath = history.location.pathname + routes.VALIDATE_ACCOUNT
                        history.push(newPath, { ...history.location.state })
                        return { ...response.data, stopLoader: true }
                    }
                    else if (history.location.state.apiResponse.caseid && !history.location.state.apiResponse.ssn && !history.location.state.apiResponse.dob) {
                        dispatch(signup({
                            phone: history.location.state.mobileFormData.mobile_number,
                            caseid: history.location.state.apiResponse.caseid,
                            passcode: '123456',
                            email: history.location.state.registerFormData.email,
                            password: history.location.state.registerFormData.password,
                            type: 'MobileAccount'
                        }))
                        return { ...response.data, stopLoader: false }
                    }
                }
            }
            else {
                return { error: { ...response.data, message: 'Invalid OTP' }, stopLoader: true }
            }
        }).catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const getUser = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.GET_USER,
    payload: API.getUser(credentials)
        .then(response => {
            if (!response.error) {
                localStorage.set('user', response.data.data);
            }
            return response.data.data
        }).catch(error => {
            return error
        })
})

export const checkUser = (credentials, values) => dispatch => dispatch({
    type: AuthActionTypes.CHECK_USER,
    payload: API.checkUser(credentials)
        .then(response => {
            dispatch(setMobileFormData(values))
            console.log(response)
            let history = getHistory()
            if (credentials.type === 'CheckOld') {
                if (history.location.pathname + history.location.hash === routes.REGISTER + routes.VERIFY_MOBILE) {
                    if (response.data.result === "Success" || response.data.result === "Success!") {
                        localStorage.set('case_id', response.data.caseid)
                        if (response.data.caseid) {
                            dispatch(sendOTP({ type: 'SendSMS', phoneno: values.country_code + values.mobile_number }, values, response.data))
                        }
                        return { ...response.data, stopLoader: false }
                    }
                    else {
                        if (response.data.error === 'User already exist!' || response.data.error === 'User already exist' || response.data.error === 'User mobile already exists!' || response.data.error === 'User mobile already exists' || response.data.error === 'User already exists!' || response.data.error === 'User already exists' || response.data.error === 'User mobile already exist!' || response.data.error === 'User mobile already exist') {
                            history.push(routes.PHNO_NOT_LINKED, { ...history.location.state, mobileFormData: values, apiResponse: response.data, prevPath: history.location.pathname + history.location.hash })
                        }
                        else {
                            let newPath = history.location.pathname + routes.PHNO_NOT_LINKED
                            history.push(newPath, { ...history.location.state, mobileFormData: values, apiResponse: response.data, prevPath: history.location.pathname + history.location.hash })
                        }
                        return { ...response.data, stopLoader: true }
                    }
                }
                if (history.location.pathname + history.location.hash === routes.REGISTER + routes.VERIFY_OLD_MOBILE) {
                    if (response.data.result === "Success" || response.data.result === "Success!") {
                        localStorage.set('case_id', response.data.caseid)
                        if (response.data.caseid) {
                            dispatch(sendOTP({ type: 'SendSMS', phoneno: values.country_code + values.mobile_number }, values, response.data))
                        }
                        return { ...response.data, stopLoader: false }
                    }
                    else {
                        if (response.data.error === 'User already exist!' || response.data.error === 'User already exist' || response.data.error === 'User mobile already exists!' || response.data.error === 'User mobile already exists' || response.data.error === 'User already exists!' || response.data.error === 'User already exists' || response.data.error === 'User mobile already exist!' || response.data.error === 'User mobile already exist') {
                            history.push(routes.PHNO_NOT_LINKED, { ...history.location.state, mobileFormData: values, apiResponse: response.data, prevPath: history.location.pathname + history.location.hash })
                        }
                        else {
                            let newPath = history.location.pathname + routes.PHNO_NOT_LINKED
                            history.push(newPath, { ...history.location.state, mobileFormData: values, apiResponse: response.data, prevPath: history.location.pathname + history.location.hash })
                        }
                        return { ...response.data, stopLoader: true }
                    }
                }
            }
            if (credentials.type === 'CheckNew') {
                localStorage.set('case_id', response.data.caseid)
                dispatch(sendOTP({ type: 'SendSMS', phoneno: values.country_code + values.mobile_number }, values, response.data))
                return { ...response.data, stopLoader: false }
            }
        }).catch(error => {
            console.log(error);
            // errorHandler(error);
            return { error, stopLoader: true };
        })
});

//5027864 caseid
//devesh.arora@nablasol.com email
export const signup = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.SIGNUP,
    payload: API.signup(credentials)
        .then(response => {
            let history = getHistory()
            if (response.data.result === "Success!") {
                localStorage.set('id', response.data.id)
                localStorage.set('userid', response.data.userid)
                dispatch(getUser({ type: 'GetUser', id: response.data.id }))
            }
            else {
                resetLocalStorage()
                history.push(routes.LOGIN)
            }
            return response.data;
        }).catch(error => {
            console.log(error, 'errorMsg');
            // errorHandler(error);
            return error;
        })
});

export const submitTicket = (credentials, values) => dispatch => dispatch({
    type: SubmitTicketTypes.SUBMIT_TICKET,
    payload: API.submitTicket(credentials)
        .then(response => {
            console.log(response)
            let history = getHistory()
            let currentUrl = `${history.location.pathname}${history.location.hash}`
            if (currentUrl === routes.MYACCOUNT + routes.SUBMIT_TICKET) {
                resetLocalStorage()
                if (response.data.result === "Success!" || response.data.result === "Success") {
                    history.push(history.location.pathname + routes.TICKET_SUBMITTED, { isRegistered: true })
                }
            }
            else {
                if (response.data.result === "Success!" || response.data.result === "Success") {
                    history.push(history.location.pathname + routes.TICKET_SUBMITTED, { isRegistered: true })
                }
            }
            return response.data
        }).catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const setMobileFormData = (values) => dispatch => dispatch({
    type: AuthActionTypes.SET_MOBILE_FORM_DATA,
    payload: {
        mobile_number: values.mobile_number,
        country_code: values.country_code
    }
})

export const login = (credentials, values) => dispatch => dispatch({
    type: AuthActionTypes.LOGIN,
    payload: API.login(credentials)
        .then(response => {
            let history = getHistory()
            const storeState = store.getState();
            if (response.data.result === 'Success!' || response.data.result === 'Success') {
                localStorage.set('id', response.data.id)
                localStorage.set('userid', response.data.userid)
                localStorage.set('case_id', response.data.caseid)
                localStorage.set('refresh_token', response.data.refreshToken)
                localStorage.set('idToken', response.data.idToken)
                let numberverified = response.data.numberverified
                let changepassword = response.data.changepassword
                let contract8821 = response.data['8821contract']
                if (history.location.pathname + history.location.hash === routes.MYACCOUNT + routes.CHANGE_PASSWORD) {
                    if (!response.data.error) {
                        dispatch(resetPassword({ type: 'ChangePassword', password: values.new_password, idToken: storage.get("idToken", null) }))
                    }
                    return { ...response.data, stopLoader: false }
                }
                if ((numberverified && numberverified.toLowerCase() !== 'no') || (changepassword && changepassword.toLowerCase() !== 'no') || (contract8821 && contract8821.toLowerCase() !== 'no')) {
                    dispatch(toggleFreeTaxRedirected(true, {
                        freeTaxChangePasswordStatus: changepassword,
                        freeTax8821ContractStatus: contract8821,
                        freeTaxNumberVerifiedStatus: numberverified
                    }))
                    localStorage.set("isFreeTaxRedirected", true)
                    localStorage.set('freeTaxNumberVerifiedStatus', numberverified)
                    localStorage.set('freeTaxChangePasswordStatus', changepassword)
                    localStorage.set('freeTax8821ContractStatus', contract8821)
                    dispatch(getUser({ type: 'GetUser', id: storage.get('id', null) }))
                        .then(response => {
                            //http://localhost:3001/login?email=dGVzdHFhKzQ2M0BiaXRjb3QuY29t&password=MTIzNDU2
                            //fetching user
                        }).catch(error => {
                            console.log(error)
                        })
                    return { ...response.data, stopLoader: true }
                } else {
                    dispatch(getUser({ type: 'GetUser', id: storage.get('id', null) }))
                        .then(response => {
                            //http://localhost:3001/login?email=dGVzdHFhKzQ2M0BiaXRjb3QuY29t&password=MTIzNDU2
                            //fetching user
                        }).catch(error => {
                            console.log(error)
                        })
                    return { ...response.data, stopLoader: true }
                }
            }
            if (response.data.error) {
                return { ...response.data, stopLoader: true }
            }
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return { ...error, stopLoader: true };
        })
});

export const forgotPassword = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: API.forgotPassword(credentials)
        .then(response => {
            if (response.data.error) {
                // toastMsg(response.data);
            } else {
                toastMsg("Please check your email to reset your password!")
                const history = getHistory();
                history.push(routes.LOGIN);
            }

            return response.data;
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const resetPassword = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.RESET_PASSWORD,
    payload: API.resetPassword(credentials)
        .then(response => {
            const storeState = store.getState();
            const isFreeTaxRedirected = storeState.authReducer.isFreeTaxRedirected;
            if (response.data.error) {
                // toastMsg(response.data);
            } else {
                if (response.data.result === 'Success!' || response.data.result === 'Success') {
                    storage.set('idToken', response.data.idToken);
                    toastMsg("Your Password has been reset successfully.");
                    let history = getHistory()
                    if (!isFreeTaxRedirected) {
                        history.push(routes.MYACCOUNT + routes.PERSONAL_INFORMATION)
                    }
                }
            }

            return response.data;
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const refreshToken = (request) => dispatch => dispatch({
    type: AuthActionTypes.REFRESH_TOKEN,
    payload: API.refreshToken(request)
        .then(response => {
            localStorage.set('idToken', response.data.idtoken)
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const authorizeUser = (user_profile) => {
    console.log("authorize:" + user_profile.access_token);
    console.log("user:" + user_profile);

    storage.set('token', user_profile.access_token);
    storage.set('refresh_token', user_profile.refresh_token);
    storage.set('user', user_profile);


    const token = user_profile.access_token;
    const refresh_token = user_profile.refresh_token;

    return {
        type: AuthActionTypes.AUTHORIZE,
        payload: {
            token,
            user_profile,
            refresh_token
        }
    }
};

export const resetMobileFormData = (credentials) => {
    return {
        type: AuthActionTypes.RESET_MOBILE_FORM_DATA,
        payload: credentials
    }
};

// export const logout = () => dispatch => {
//     let history = getHistory()
//     resetLocalStorage()
//     history.replace(routes.LOGIN);
//     dispatch({
//         type: ServiceActionType.SERVICE_RESET_DATA,
//     })
//     dispatch({
//         type: AuthActionTypes.LOGOUT,
//     })
// }

export const logout = (credentials) => dispatch => dispatch({
    type: AuthActionTypes.LOGOUT,
    payload: API.logout(credentials)
        .then(response => {
            if (response.data.result === 'Success!' || response.data.result === 'Success') {
                let history = getHistory()
                // history.replace(routes.LOGIN);
                dispatch({
                    type: ServiceActionType.SERVICE_RESET_DATA,
                })
                // resetLocalStorage()
            }

            return response.data;
        })
        .catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const resetLocalStorage = () => {
    storage.remove('user');
    storage.remove('refresh_token');
    storage.remove('id')
    storage.remove('userid')
    storage.remove('case_id')
    storage.remove('idToken')
    storage.remove('isFreeTaxRedirected')
    storage.remove('freeTaxChangePasswordStatus')
    storage.remove('freeTax8821ContractStatus')
    storage.remove('freeTaxNumberVerifiedStatus')
    storage.remove('isOTPSent')
}

export const toggleFreeTaxRedirected = (flag, status) => dispatch => {
    dispatch({
        type: AuthActionTypes.IS_FREETAX_REDIRECTED, payload: {
            flag: flag,
            status: status
        }
    })
}

export const updateUserProfile = (user) => dispatch => dispatch({
    type: AuthActionTypes.UPDATE_USER,
    payload: user
})