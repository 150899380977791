

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { RESET_INITIAL_STATE } from '../redux/actions/usersActions/actionType';
import { useLocation } from "react-router-dom";


const Component = ({ history, ...props }) => {
    const { pathname } = useLocation();

    useEffect(() => history.listen(() => {
        // do something on route change
        // for my example, close a drawer

        let location = history.location;
        console.log(props)
        props.resetInitialState()
        console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
    }), [])


    //scroll to top on path change
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
    //...
}

const mapDispatch = dispatch => {
    return {
        resetInitialState: () => dispatch({ type: RESET_INITIAL_STATE })
    }
}

export default connect(null, mapDispatch)(withRouter(Component))