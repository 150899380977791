import React, { Component, useState, useEffect } from 'react';
import Aux from '../Oux/Oux';
import { routes } from '../../utility/constants/constants'
import { withRouter, NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import storage from '../../utility/storage';
import Sidebbar from '../../components/Sidebar/Sidebar';
import './Layout.css';
import { animateSidebar } from '../../styles/js/custom';

const Header = (props) => {

    console.log(props);

    //default when user is not logged in

    let headerTabsContent = null


    if (!props.isLoggedIn || props.isFreeTaxRedirected) {
        headerTabsContent = (
            <div className="login_header">
                <a className="theme_brand" href="/">
                    <img src="/images/icons/logo.svg" alt="Logo" />
                </a>
            </div>
        );
    } else {
        headerTabsContent = (
            <Aux>
                <header className="app_header">
                    <nav className="navbar navbar-expand navbar-light ">
                        <div className="sidebar-header">
                            <a className="navbar-brand m-0" href="/">
                                <img src="/images/icons/logo.svg" alt="Logo" />
                            </a>
                            <div style={{ margin: '45px', padding: '26px', cursor: 'pointer', alignItems: 'center' }} id="sidebarCollapse" className="outer-collapse-icon">
                                <div id="sidebarCollapse" className="collapse_icon">
                                    <div className="tb-button-bar1"></div>
                                    <div className="tb-button-bar2"></div>
                                    <div className="tb-button-bar3"></div>
                                </div>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                {console.log(document.getElementsByClassName("fp_sub_sidenav_mob"), 'sidebar')}
                                {console.log(document.getElementsByClassName("fp_sub_sidenav_mob").length, 'sidebarLength')}
                                {
                                    props.showMobMyAccountSideNav
                                        ? <li className="nav-item fb_right_sideNav">
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")}
                                                id="fb_sub_mob_rightNav"
                                                className="nav-link">
                                                <i className="fa fa-bars"></i>
                                            </a>
                                        </li>
                                        : null
                                }
                                {/* <li className="nav-item">
                                    <a className="nav-link user_profile" href="#"><span className="user_name_txt">Thom Yorke</span></a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link current_user" href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span><img src="/images/icons/profile-pic .png" className="rounded-circle" /></span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="#"><span className="lnr lnr-user"></span>Personal Information</a>
                                        <a className="dropdown-item" href="#"><span className="lnr lnr-cog"></span>Business Information</a>
                                        <a className="dropdown-item" href="#"><span className="lnr lnr-cog"></span> Billing Information</a>
                                        <a className="dropdown-item" href="#"><span className="lnr lnr-cog"></span> Change Password</a>
                                        <div className="dropdown-divider"></div>
                                        <a onClick={() => props.logoutUser()} className="dropdown-item">Sign Out</a>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </nav>
                </header>
                <Sidebbar />
            </Aux>
        )
    }


    if (props.user) {

    }

    return headerTabsContent
}


const Footer = (props) => {
    let currentYear = new Date().getFullYear();
    const footerStyle = {
        paddingLeft: 0
    }  

    return (
        <footer className="fp_footer" style={props.isLoggedIn ? null : footerStyle}>
            <div className="fp_copy text-center">
                <span className="ft_txt">© {currentYear} Community Tax, Inc. Version 2.7</span> |
                <a href="https://sugarapi.ctaxcrm.com/mobile-app/CTaxMobilePrivacyPolicy.php" style={{ textDecoration: 'none' }} target="_blank" className="pt_txt">Privacy &amp; Terms</a> |
                <a href="https://www.facebook.com/CommunityTaxLLC/" target="_blank"><img src="/images/icons/icn_facebook.png" alt="Icon" /></a>
                <a href="https://twitter.com/communitytaxllc?lang=en" target="_blank"><i className="fa fa-twitter"></i></a> |
                <a href="https://play.google.com/store/apps/details?id=com.communitytax.financepal&hl=en_IN" target="_blank"><img className="mobile_app_download_icon" src="/images/customs/play_store_download.png" /></a>
                <a href="https://apps.apple.com/tt/app/finance-pal-by-communitytax/id1490922032?ign-mpt=uo%3D2" target="_blank"><img className="mobile_app_download_icon" src="/images/customs/app_store_download.png" /></a>
            </div>
        </footer >
    );
}

class Layout extends Component {

    state = {
        showMobMyAccountSideNav: false
    }

    componentDidMount() {
        console.log(document.getElementById("sidebarCollapse"), "sidebarCollapseIcon")
        if (document.getElementsByClassName("fp_sub_sidenav_mob")[0] && document.getElementsByClassName("fp_sub_sidenav_mob").length > 0) {
            if (!this.state.showMobMyAccountSideNav) {
                this.setState({ showMobMyAccountSideNav: true })
            }
        }
        else {
            if (this.state.showMobMyAccountSideNav) {
                this.setState({ showMobMyAccountSideNav: false })
            }
            animateSidebar()
        }
    }

    componentDidUpdate = (prevProps) => {
        //scroll to top on transition
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
        if (document.getElementsByClassName("fp_sub_sidenav_mob")[0] && document.getElementsByClassName("fp_sub_sidenav_mob").length > 0) {
            if (!this.state.showMobMyAccountSideNav) {
                this.setState({ showMobMyAccountSideNav: true })
            }
        }
        else {
            if (this.state.showMobMyAccountSideNav) {
                this.setState({ showMobMyAccountSideNav: false })
            }
            animateSidebar()
        }
    }

    logout = () => {
        storage.remove('token');
        storage.remove('user');
        storage.remove('refresh_token');
        storage.remove('step');
        // const history = getHistory();
        window.location.replace(routes.HOME);
    }

    render() {
        return (
            <Aux>
                <div className="limiter">
                    <Header showMobMyAccountSideNav={this.state.showMobMyAccountSideNav} {...this.props} />

                    <div className="clearfix"></div>
                    {this.props.children}
                    <div className="clearfix"></div>
                    <Footer />
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authReducer.user,
        isLoggedIn: state.authReducer.isLoggedIn,
        isFreeTaxRedirected: state.authReducer.isFreeTaxRedirected
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => dispatch(actions.logout())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));