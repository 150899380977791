import React, { useState, createRef } from 'react'
import Dropzone from 'react-dropzone'

const DragAndDrop = props => {

    console.log(props.children, "Drag Drop Children")

    const [errorMsg, setErrorMsg] = useState(null)

    const onDrop = (accepted, rejected) => {
        if (Object.keys(rejected).length !== 0) {
            const message = "Please submit valid file type";
            setErrorMsg(message)
        } else {
            props.uploadFile(accepted);
            setErrorMsg(null)
            console.log(accepted[0].preview);

            // var blobPromise = new Promise((resolve, reject) => {
            //     const reader = new window.FileReader();
            //     reader.readAsDataURL(accepted[0]);
            //     reader.onloadend = () => {
            //         const base64data = reader.result;
            //         resolve(base64data);
            //     };
            // });
            // blobPromise.then(value => {
            //     console.log(value);
            // });
        }
    };

    const { files } = props;

    return (
        <Dropzone onDrop={(acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles)}>
            {({ getRootProps, getInputProps }) => {
                return (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {props.children}
                        </div>
                    </section>
                )
            }}
        </Dropzone>
    )
}

export default DragAndDrop