import { DocumentsActionType } from './actionType'
import * as API from '../../../api/documentsAPI';
import { toastMsg } from '../../../utility/utility';
import storage from '../../../utility/storage';

export const fetchAllDocuments = (credentials) => dispatch => dispatch ({
    type: DocumentsActionType.FETCH_ALLDOCUMENT,
    payload: API.fetchAllDocuments(credentials)
        .then(response=>{
            console.log(response.data)
            return response.data
        }).catch(error=>{
            console.log(error)
            return error
        })
})

export const fetchDocumentCategories = (credentials) => dispatch => dispatch ({
    type: DocumentsActionType.FETCH_ALLDOCUMENT_CATEGORY,
    payload: API.fetchDocumentCategories(credentials)
        .then(response=>{
            console.log(response.data)
            return response.data
        }).catch(error=>{
            console.log(error)
            return error
        })
})

export const fetchFilteredDocuments = (credentials) => dispatch => dispatch ({
    type: DocumentsActionType.FETCH_ALLDOCUMENT_FILTERED,
    payload: API.fetchFilteredDocuments(credentials)
        .then(response=>{
            console.log(response.data)
            return response.data
        }).catch(error=>{
            console.log(error)
            return error
        })
})

export const uploadDocument = (credentials) => dispatch => dispatch ({
    type: DocumentsActionType.UPLOAD_DOCUMENT,
    payload: API.uploadDocument(credentials)
        .then(response=>{
            console.log(response.data)
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                toastMsg("Document Uploaded Successfully")
                setTimeout(()=>{
                    dispatch(fetchAllDocuments({caseid:storage.get('case_id', null), type:'Listing'}))
                },2000)
            }
            return response.data
        }).catch(error=>{
            console.log(error)
            return error
        })
})

export const downloadDocument = (credentials) => dispatch => dispatch ({
    type: DocumentsActionType.DOWNLOAD_DOCUMENT,
    payload: API.downloadDocument(credentials)
        .then(response=>{
            console.log(response.data)
            return response.data
        }).catch(error=>{
            console.log(error)
            return error
        })
})
