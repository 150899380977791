import axios, { API_VERSION, NS_TOKEN } from '../config';

export const getChangePasswordStatus = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileFreeTax.php', formData, {
        headers: {
            'ns-token': NS_TOKEN,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
};

export const getPhoneVerifiedStatus = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileFreeTax.php', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
};

export const form8821Status = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileFreeTax.php', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
};

export const form8821EnvelopeId = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileFreeTax.php', formData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    });
};