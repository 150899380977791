import React from 'react'
import { Form, Field, Formik } from 'formik'
import { routes } from '../../utility/constants/constants'
import './RegisterForm.css'
import { withRouter } from 'react-router'
import Spinner from '../UI/Spinner/Spinner'
import { registerValidateForm } from '../FormValidation/FormValidation'
import $ from 'jquery'
import CryptoJS from 'crypto-js'
import Oux from '../../hoc/Oux/Oux'


const registerForm = props => {

    var ciphertext = CryptoJS.HmacSHA1('998-82-5556', '4b5a01355c7144e6a47296faefb0b658');
    console.log("encrypted text", ciphertext.toString());
    console.log(ciphertext.toString() === "cbca1e058b4a5da8a8d90f83396259dc1a16deff")

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid',
    }

    const eyeErrorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeft: 'none',
        background: 'white'
    }

    const toggleInputType = (event) => {
        if ($('#' + event.target.id).parent().parent().parent().children().attr('type') === 'password') {
            $('#' + event.target.id).parent().parent().parent().children().attr('type', 'text')
            $('#' + event.target.id).addClass('fa-eye').removeClass('fa-eye-slash')
        }
        else {
            $('#' + event.target.id).parent().parent().parent().children().attr('type', 'password')
            $('#' + event.target.id).addClass('fa-eye-slash').removeClass('fa-eye')
        }
    }

    const routeToLogin = () => {
        props.history.push(routes.LOGIN);
    }
    return (
        <Oux>
            <div class="clearfix"></div>
            <div className="container-login100">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <div className="theme_form_outer">
                        <h1>Sign Up</h1>
                        <Formik
                            initialValues={{}}
                            onSubmit={(values) => props.onSubmitRegister({ email: values.email, type: 'CheckEmail' }, values)}
                            validationSchema={registerValidateForm}>
                            {(formik_props) => {

                                const errors = formik_props.errors;
                                const touched = formik_props.touched;

                                return (
                                    <Form>
                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.apiError ? 'Email Address Exists' : null}</span>
                                        <div className="form-group">
                                            <Field type="email" style={errors.email && touched.email ? errorStyle : null} name="email" className="form-control theme_input" placeholder="Email Address" />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.email && touched.email && errors.email}</span>
                                        </div>
                                        <div className="form-group">
                                            {/* <Field type="password" style={errors.password && touched.password ? errorStyle : null} name="password" className="form-control theme_input" placeholder="Password" />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.password && touched.password && errors.password}</span> */}
                                            <div className="input-group cst_input_group">
                                                <Field
                                                    type='password'
                                                    name="password"
                                                    className="form-control input_modifier"
                                                    placeholder="Password"
                                                    id="password"
                                                    style={errors.password && touched.password ? { ...errorStyle, borderRight: 'none' } : { borderRight: 'none' }} />
                                                <div className="input-group-append">
                                                    <span style={errors.password && touched.password ? eyeErrorStyle : { borderLeft: 'none', background: 'white' }} className="input-group-text" id="basic-addon2">
                                                        <i
                                                            id="password-input"
                                                            style={{ cursor: 'pointer', color: '#1E7995' }}
                                                            className='fa fa-eye-slash'
                                                            onClick={(event) => toggleInputType(event)}></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.password && touched.password && errors.password}</span>
                                        </div>
                                        {/* <div className="form-group">
                                            <Field type="password" style={errors.confirm_password && touched.confirm_password ? errorStyle : null} name="confirm_password" className="form-control theme_input" placeholder="Confirm Password" />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.confirm_password && touched.confirm_password && errors.confirm_password}</span>
                                        </div> */}
                                        <div className="form-group">
                                            <div className="input-group cst_input_group">
                                                <Field
                                                    type='password'
                                                    name="confirm_password"
                                                    className="form-control input_modifier"
                                                    placeholder="Confirm Password"
                                                    id="confirm_password"
                                                    style={errors.confirm_password && touched.confirm_password ? { ...errorStyle, borderRight: 'none' } : { borderRight: 'none' }} />
                                                <div className="input-group-append">
                                                    <span style={errors.confirm_password && touched.confirm_password ? eyeErrorStyle : { borderLeft: 'none', background: 'white' }} className="input-group-text" id="basic-addon2">
                                                        <i
                                                            id="confirm-password-input"
                                                            style={{ cursor: 'pointer', color: '#1E7995' }}
                                                            className='fa fa-eye-slash'
                                                            onClick={(event) => toggleInputType(event)}></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.confirm_password && touched.confirm_password && errors.confirm_password}</span>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <Field type="checkbox" id="customCheck1" name="register_checkbox" className="custom-control-input" checked={formik_props.values.register_checkbox} onChange={(event) => formik_props.setFieldValue("register_checkbox", event.target.checked)} />
                                                <label className={errors.register_checkbox && touched.register_checkbox ? "custom-control-label checkbox-error-class" : "custom-control-label"} htmlFor="customCheck1">I accept the {/*<a href="javascript:void(0)" className="theme_link" style={{cursor:'pointer'}} onClick={()=>props.history.push(routes.TERMS_AND_CONDITIONS)}>Terms of Service</a>*/}<a href="https://sugarapi.ctaxcrm.com/mobile-app/CTaxMobileTC.php" target="_blank" className="theme_link">Terms of Service</a> </label>
                                                <br /><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.register_checkbox && touched.register_checkbox && errors.register_checkbox}</span>
                                            </div>
                                        </div>
                                        {props.isLoading ? <Spinner width='25px' height='25px' margin='0 auto' /> : <button type="submit" disabled={!formik_props.dirty || !formik_props.isValid} style={props.isLoading ? { backgroundColor: 'white', borderColor: 'white' } : null} className="btn btn-primary btn-block btn_submit globel_btn">Sign Up</button>}
                                        <div className="form_bottom_info text-center">
                                            Already have an account? <a onClick={routeToLogin} className="theme_link form-submit-btn">Log In</a>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
                <div className="login100-more">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                            <div className="logo_img">
                                <img src="images/icons/logo_lg.svg" alt="FinancePal" />
                            </div>
                            <div className="cst_checkbox_block">
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Easy and intuitive to use</span>
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Save time and money</span>
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Helpful tools to manage your taxes and finances</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Oux>
    )
}

export default withRouter(registerForm)