import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import Oux from '../../../hoc/Oux/Oux'
import Skeleton from 'react-loading-skeleton';
import axios, { API_VERSION, NS_TOKEN } from '../../../config';

const ContactYourTeam = props => {

    const [contactUsPage, setContactUsPage] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const formData = new FormData()
        formData.append('caseid', props.caseid)
        axios.post(API_VERSION() + 'CTaxMobileContactUs.php', formData, {
            headers: {
                'ns-token': NS_TOKEN,
            },
        }).then(response => {
            console.log(response.data)
            setContactUsPage(response.data)
            setIsLoading(false)
            return response.data
        }).catch(error => {
            setIsLoading(false)
            return error
        })
    }, [])

    console.log(props.isLoading)

    const openContact = (event) => {
        console.log(event.target.innerText)
        window.open('tel:' + event.target.innerText)
    }

    const openEmail = (event) => {
        console.log(event.target.innerText)
        window.open('mailto:' + event.target.innerText)
    }

    return (
        <div className="fp_right_content" >
            <div className="tab-content tab-pane fade active show" id="v-pills-tabContent">
                <div className="fb_tabs_inner contact_us">
                    <div className="doc_tab_content">
                        <div className="doc_head">
                            <h3 className="fp_sec_title">Contact Your Team</h3>
                        </div>
                        <div className="contact_details">
                            <div className="col-md-6 col-lg-6 col-xl-4 contact_cst_cal">
                                {
                                    contactUsPage && contactUsPage.data
                                        ? contactUsPage.data.map((contact, index) => {
                                            return (
                                                <div className="card doc_card">
                                                    <div className="card-body">
                                                        <div className="media">
                                                            <span className="img_circle">
                                                                <img className="mr-3" src="images/icons/img01.png" alt="Generic placeholder image" />
                                                            </span>
                                                            <div className="media-body">
                                                                <h5 className="mt-0 h5_title">{contact.name}</h5>
                                                                <h6 className="h6_title">{contact.position}</h6>
                                                                {contact.email
                                                                    ? <div className="con_details">
                                                                        <i className="fa fa-paper-plane-o mr-2"></i><a onClick={(event) => openEmail(event)}>{contact.email}</a>
                                                                    </div>
                                                                    : null
                                                                }
                                                                {
                                                                    contact.phone
                                                                        ? <div className="con_details">
                                                                            <i className="fa fa-phone mr-2"></i> <a href="javascript:void(0)" onClick={(event) => openContact(event)}>{contact.phone}</a>
                                                                        </div>
                                                                        : null
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : isLoading
                                            ? <Oux><Skeleton height={120} /><br /><Skeleton height={120} /></Oux>
                                            : <h4>No Contact Found</h4>
                                }
                                {/* <div className="card doc_card">
                                    <div className="card-body">
                                        <div className="media">
                                            <span className="img_circle">
                                                <img className="mr-3" src="images/icons/img01.png" alt="Generic placeholder image" />
                                            </span>
                                            <div className="media-body">
                                                <h5 className="mt-0 h5_title">Jacob Dayan</h5>
                                                <h6 className="h6_title">Case Analyst</h6>
                                                <div className="con_details">
                                                    <i className="fa fa-paper-plane-o mr-2"></i><a href="#">Jacobdayan@communitytax.com</a>
                                                </div>
                                                <div className="con_details">
                                                    <i className="fa fa-phone mr-2"></i> <a href="#">(312) 123-4567</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactYourTeam