import React from 'react'
import Spinner from '../UI/Spinner/Spinner'
import './Notifications.css'
import Oux from '../../hoc/Oux/Oux'
import Skeleton from 'react-loading-skeleton'
import { dateFormat } from '../../utility/constants/constants'

const notifications = props => {

    console.log(props.userNotifications)

    return (
        <div className="container fp_pl_0">
            <div className="row">
                <div className={props.userNotifications ? "col-lg-6" : props.isLoading ? "col-lg-6" : "col-lg-6 offset-lg-3"}>
                    <div className="billing_sc notifications">
                        {props.userNotifications ? <div className="account_service">
                            <h3 className="fp_sec_title">Notifications</h3>
                            <h6 className="h6_title">Active Notification</h6>
                        </div> : null}
                        {
                            props.userNotifications
                                ? props.userNotifications.map((notification, index) => {
                                    console.log(notification)
                                    let shortMsg = null
                                    let date = dateFormat(new Date(notification.date), 'MM-DD-YYYY')
                                    if (notification.message) {
                                        shortMsg = notification.message.substring(0, 120)
                                    }
                                    return (
                                        <div key={index} className="account_service">
                                            {/* <h3 className="fp_sec_title">Notifications</h3>
                                            <h6 className="h6_title">Active Notification</h6> */}
                                            <div className="timeline-item notification_card">
                                                <div className="card_top_banner banner_red">
                                                    <h5 className="card-title">{notification.servicename ? notification.servicename : notification.title ? notification.title : null}</h5>
                                                    {notification.name ? <span className="card_head_icn txt_inves_icn"></span> : null}
                                                </div>
                                                <div className="fp_inner_body">
                                                    <h4 className="item-title">{notification.servicename && notification.title ? notification.title : shortMsg}</h4>
                                                    <div className="fp_ativity_icons text-primary">
                                                        <a href="javascript:void(0)">
                                                            {/* <img src="images/icons/icn_user.svg" className="mr-1" />Ali Connors */}
                                                        </a>
                                                    </div>
                                                    <div className="fp_ativity_icons">Date: {date}</div>
                                                </div>
                                                <button onClick={props.showRightContent && props.selectedNotificationIndex === index ? props.closeRightContent : () => props.setSelectedNotification(notification, index)} className="fp_btn fb_style2 btn btn-primary"><img src="images/icons/icn_tag.png" />{props.showRightContent && props.selectedNotificationIndex === index ? "Close" : "Read More"}</button>
                                            </div>
                                        </div>
                                    )
                                })
                                : props.isLoading
                                    ? <Oux><Skeleton height={120}></Skeleton><Skeleton height={120}></Skeleton></Oux>
                                    : <div class="empty_details notifications">
                                        <div class="card">
                                            <div class="card-body">
                                                <div className="empty_img">
                                                    <img src="/images/customs/icn_no_notifications.png" />
                                                </div>
                                                <h3 className="h3_title">There are no notifications.</h3>
                                            </div>
                                        </div>
                                    </div>
                        }
                        {/* <div className="account_service">
                            <div className="timeline-item notification_card">
                                <div className="card_top_banner banner_blue">
                                    <h5 className="card-title">Tax Report</h5>
                                    <span className="card_head_icn txt_report_icn"></span>
                                </div>
                                <div className="fp_inner_body">
                                    <h4 className="item-title">Upload Bank Statement</h4>
                                    <div className="fp_ativity_icons text-primary"><a href="javascript:void(0)"><img src="images/icons/icn_user.svg" className="mr-1" />Ali Connors</a></div>
                                    <div className="fp_ativity_icons">Due: 10-07-2019</div>
                                </div>
                                <a href="#" className="fp_btn fb_style2 btn btn-primary"> <img src="images/icons/icn_down_white.png" /> Upload</a>
                            </div>
                        </div>
                        <div className="account_service">
                            <div className="timeline-item notification_card">
                                <div className="card_top_banner banner_blue">
                                    <h5 className="card-title">Tax Report</h5>
                                    <span className="card_head_icn txt_report_icn"></span>
                                </div>
                                <div className="fp_inner_body">
                                    <h4 className="item-title">Upload Bank Statement</h4>
                                    <div className="fp_ativity_icons text-primary"><a href="javascript:void(0)"><img src="images/icons/icn_user.svg" className="mr-1" />Ali Connors</a></div>
                                    <div className="fp_ativity_icons">Due: 10-07-2019</div>
                                </div>
                                <a href="#" className="fp_btn fb_style2 btn btn-primary"><img src="images/icons/icn_tax_rep_sm.png" alt="Review" /> Upload</a>
                            </div>
                        </div>
                        <div className="account_service">
                            <h6 className="h6_title mt-2">Completed Notification</h6>
                            <div className="timeline-item fp_timeline_gray notification_card">
                                <div className="fp_inner_body">
                                    <h4 className="item-title">Review Financial Profile</h4>
                                    <div className="fp_ativity_icons"><a href="javascript:void(0)"><img src="images/icons/icn_user_gray.png" className="mr-1" /> Ali Connors</a></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {console.log(props.selectedNotification)}
                {
                    props.showRightContent
                        ? <div className="col-lg-6">
                            <div className="billing_sc notifications">
                                <div className="notifications_right_content">
                                    <div className="notifications_fp_inner_user_pro" id="notifications_fp_inner_user_pro">
                                        <div className="notifications_fp_media_user_pro fp_media_user_pro_block" style={{ display: 'block' }}>
                                            <div className="account_service">
                                                {/* <h3 className="fp_sec_title">Notifications</h3>
                                                    <h6 className="h6_title">Active Notification</h6> */}
                                                <div className="timeline-item notification_card">
                                                    {/* <div className="card_top_banner banner_red">
                                                    <h5 style={{float:'left'}} className="card-title">{props.selectedNotification.title}</h5>
                                                    <span className="card_head_icn txt_inves_icn">
                                                    </span>
                                                </div> */}
                                                    <div className="fp_inner_body">
                                                        <h4 className="item-title">{props.selectedNotification.message}</h4>
                                                        <div className="fp_ativity_icons text-primary">
                                                            <a href="javascript:void(0)">
                                                                {/* <img src="images/icons/icn_user.svg" className="mr-1" />Ali Connors */}
                                                            </a>
                                                        </div>
                                                        {/* <div className="fp_ativity_icons">Date: {notification.date}</div> */}
                                                    </div>
                                                    {/* <button onClick={props.closeRightContent} className="fp_btn fb_style2 btn btn-primary"><img src="images/icons/icn_tag.png" />Close</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default notifications