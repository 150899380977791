import React from 'react'

const businessServicesTab = props => {
    return (
        <div class={props.show ? "tab-pane fade show active" : "tab-pane fade"} id="pills-business-services" role="tabpanel" aria-labelledby="pills-business-services-tab">
            <div class="services_list_block">
                {/* <h3>Business Services</h3> */}
                {/* <div class="row row_m8">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col_p8">
                        <a href="javascript:void(0);" class="card_services_link">
                            <div class="card services_card other_card">
                                <div class="card-body">
                                    <h5 class="card-title">Squad tech</h5>
                                    <div class="card_details">
                                        <p>Text payments</p>
                                        <div class="payments_fields">
                                            <span>Recommended Services</span>
                                            <input type="text" name="rservice" class="" />
                                        </div>
                                        <p>Collections</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col_p8">
                        <a href="javascript:void(0);" class="card_services_link">
                            <div class="card services_card other_card">
                                <div class="card-body">
                                    <h5 class="card-title">Doughnuts 1</h5>
                                    <div class="card_details">
                                        <p>Book Keeping</p>
                                        <div class="payments_fields">
                                            <span>Recommended Serviecs</span>
                                            <input type="text" name="rservice" class="" />
                                        </div>
                                        <p>Payroll</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col_p8">
                        <a href="javascript:void(0);" class="card_services_link">
                            <div class="card services_card other_card">
                                <div class="card-body">
                                    <h5 class="card-title">Squad Tech 2</h5>
                                    <div class="card_details">
                                        <p>Tax payments</p>
                                        <div class="payments_fields">
                                            <span>Recommended Serviecs</span>
                                            <input type="text" name="rservice" class="" />
                                        </div>
                                        <p>Collections</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-sm-6 col_p8">
                        <a href="javascript:void(0);" class="card_services_link">
                            <div class="card services_card other_card">
                                <div class="card-body">
                                    <h5 class="card-title">Doughnuts 2</h5>
                                    <div class="card_details">
                                        <p>Book Keeping</p>
                                        <div class="payments_fields">
                                            <span>Recommended Serviecs</span>
                                            <input type="text" name="rservice" class="" />
                                        </div>
                                        <p>Payroll</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div> */}
                {/* <div class="row">
                    <div class="col-sm-12 text-center add_business_wrp">
                        <button class="btn btn-primary globel_btn">Add Business</button>
                    </div>
                </div> */}
                <div class="empty_details notifications">
                    <div class="card">
                        <div class="card-body">
                            <div className="empty_img">
                                <img src="/images/customs/coming_soon.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default businessServicesTab