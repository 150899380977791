import React from 'react';
import { connect } from 'react-redux';
import { APIType, serviceRoute, routes, HomeTabs } from '../../utility/constants/constants';
import { servicesApi } from '../../api/servicesAPI';
import * as action from '../../redux/actions';
import './Home.css';
import Skeleton from 'react-loading-skeleton';
import Oux from '../../hoc/Oux/Oux';
import { toastMsg } from '../../utility/utility';
import Spinner from '../../components/UI/Spinner/Spinner'
import ServiceWrapper from './ServiceWrapper';
import storage from '../../utility/storage';
import BusinessServicesTab from './BusinessServices/BusinessServicesTab';
import GetPersonalizedFinanceHelp from './GetPersonalizedFinanceHelp/GetPersonalizedFinanceHelp';
import AwesomeTools from './AwesomeTools/AwesomeTools';
import FindBusinessSolutions from './FindBusinessSolutions/FindBusinessSolutions';
import ComingSoonModal from '../../components/Modal/ComingSoonModal';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activatingService: null,
            activeHomeTab: HomeTabs.PERSONAL_SERVICES
        }
    }

    componentDidMount = () => {
        // this.props.getUser({ id: this.props.id, type: 'GetUser' })
        if (this.props.isFreeTaxRedirected) {
            // FreeTax
            let changePassword = storage.get('changepassword', null)
            let contract8821 = storage.get('8821contract', null)
            let numberverified = storage.get('numberverified', null)
            // debugger
            if ((changePassword && changePassword.toLowerCase() !== 'no') || !changePassword) {
                this.props.history.push(routes.ADD_PASSWORD)
            }
            if ((numberverified && numberverified.toLowerCase() !== 'no') || !numberverified) {
                this.props.history.push(routes.MOBILE_VERIFICATION)
            }
        }
    }

    changeActiveHomeTab = (tabName) => {
        this.setState({ activeHomeTab: tabName })
    }

    activateService = (service) => {
        console.log("service:" + JSON.stringify(service));

        let request = {
            type: APIType.OptService,
            caseid: this.props.user.caseid,
            servicename: service.name,
            servicecode: service.service_code
        }

        this.setState({
            ...this.state,
            activatingService: service,
        })

        servicesApi(request)
            .then(res => {
                this.setState({
                    ...this.state,
                    activatingService: null,
                });
                if (res.error) {
                    toastMsg(res.error, true);
                } else {
                    //fetch all services
                    this.props.fetchServices();
                    let request = {
                        type: APIType.AvailableServices,
                        caseid: this.props.user.caseid
                    }
                    this.props.fetchAvailableServices(request);
                }
            }).catch(e => {
                this.setState({
                    ...this.state,
                    activatingService: null,
                });
                toastMsg(e.message, true);
            })
    }

    showComingSoonModal = () => {
        window.$('#coming_soon_modal').modal()
    }

    render() {
        let availableServices = this.props.availableServices;
        let myServices = this.props.myServices;
        var arrayConstructor = [].constructor;
        let isNotAnArray = myServices.constructor !== arrayConstructor;

        if (isNotAnArray) {
            myServices = [];
        }

        let myServicesContent = null;
        let availableServicesContent = null;
        if (this.props.isMyServicesLoaded && this.props.isAvailableServicesLoaded) {
            if (myServices.length > 0 && availableServices.length > 0) {
                for (let i = 0; i < availableServices.length; i++) {
                    for (let j = 0; j < myServices.length; j++) {
                        console.log(myServices[j].name, "my")
                        console.log(availableServices[i].name, "ava")
                        if (myServices[j].name === availableServices[i].name) {
                            availableServices.splice(i)
                        }
                    }
                }
            }
        }
        if (this.props.isMyServicesLoaded) {
            if (myServices.length > 0) {
                myServicesContent = (
                    myServices.map((ele, index) => {
                        return <MyService key={index} myServiceHandler={() => this.props.myServiceHandler(ele)} name={ele.name} background={ele.hex} icon={ele.icon} status={ele.status} />
                    })
                );
            } else {
                myServicesContent = <NoServiceActivated />;
            }
        } else {
            myServicesContent = <SkeletonLoading />;
        }

        if (this.props.isAvailableServicesLoaded) {
            if (availableServices.length > 0) {
                availableServicesContent = (
                    availableServices.map((ele, index) =>
                        <AvailableService key={index} background={ele.hex} icon={ele.icon} isActivatingService={this.state.activatingService && ele.name === this.state.activatingService.name} activateService={() => this.activateService(ele)} name={ele.name} />)
                );
            } else {
                availableServicesContent = null;
            }
        } else {
            availableServicesContent = <SkeletonLoading />;
        }

        return (
            <main className="main_section">
                <div className="main_cont">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul class="nav nav-pills theme_nav_pills" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class={this.state.activeHomeTab === HomeTabs.PERSONAL_SERVICES ? "nav-link active" : 'nav-link'} onClick={() => this.changeActiveHomeTab(HomeTabs.PERSONAL_SERVICES)} id="pills-personal-services-tab">
                                            Active <br />Services</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={this.state.activeHomeTab === HomeTabs.BUSINESS_SERVICES ? "nav-link active" : 'nav-link'} onClick={() => this.changeActiveHomeTab(HomeTabs.BUSINESS_SERVICES)} id="pills-business-services-tab">
                                            Business <br />Services
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={this.state.activeHomeTab === HomeTabs.GET_PERSONAL_FINANCE_HELP ? "nav-link active" : 'nav-link'} onClick={() => this.changeActiveHomeTab(HomeTabs.GET_PERSONAL_FINANCE_HELP)} id="pills-personal-finace-tab">
                                            Get Personal <br />Finance Help
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={this.state.activeHomeTab === HomeTabs.FIND_BUSINESS_SOLUTION ? "nav-link active" : 'nav-link'} onClick={() => this.changeActiveHomeTab(HomeTabs.FIND_BUSINESS_SOLUTION)} id="pills-business-solutions-tab">
                                            Find Business <br /> Solutions
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={this.state.activeHomeTab === HomeTabs.AWESOME_TOOLS ? "nav-link active" : 'nav-link'} onClick={() => this.changeActiveHomeTab(HomeTabs.AWESOME_TOOLS)} id="pills-awesome-tools-tab">
                                            Awesome <br /> Tools
                                        </a>
                                    </li>
                                </ul>
                                {/* Personal Services Tab Starts */}
                                {
                                    this.state.activeHomeTab === HomeTabs.PERSONAL_SERVICES
                                        ? <div class="tab-content" id="pills-tabContent">
                                            <div class={this.state.activeHomeTab === HomeTabs.PERSONAL_SERVICES ? "tab-pane fade show active" : "tab-pane fade"} id="pills-personal-services" role="tabpanel" aria-labelledby="pills-personal-services-tab">
                                                <div class="services_list_block">
                                                    <div className="tb-sectin-heading tb-style1">
                                                        <div className="tb-sectin-heading-left">
                                                            <h2 className="tb-section-title">Hello, {this.props.user && this.props.user.first_name}</h2>
                                                            <p style={{ maxWidth: '100%' }} className="tb-section-sub-title">Always know where your case stands, and send us your documents with the swipe of a hand. Rest assured, we have you covered!</p>
                                                        </div>
                                                    </div>
                                                    <div className="services_list_block">
                                                        {/* <h3>Active Services</h3> */}
                                                        <div className="row row_m8">
                                                            {myServicesContent}
                                                        </div>
                                                        {/* {this.props.isAvailableServicesLoaded && availableServices.length === 0 ?
                                                            null :
                                                            <div className="services_list_block">
                                                                <h3>Recommended Services</h3>
                                                                <div className="row row_m8">
                                                                    {availableServicesContent}
                                                                </div>
                                                            </div>} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                                {/* Personal Services Tab Ends */}
                                {/* Business Services Tab Starts */}
                                {
                                    this.state.activeHomeTab === HomeTabs.BUSINESS_SERVICES
                                        ? <BusinessServicesTab show={this.state.activeHomeTab === HomeTabs.BUSINESS_SERVICES} />
                                        : null
                                }
                                {/* Business Services Tab Ends */}
                                {
                                    this.state.activeHomeTab === HomeTabs.GET_PERSONAL_FINANCE_HELP
                                        ? <GetPersonalizedFinanceHelp showComingSoonModal={this.showComingSoonModal} show={this.state.activeHomeTab === HomeTabs.GET_PERSONAL_FINANCE_HELP} />
                                        : null
                                }
                                {
                                    this.state.activeHomeTab === HomeTabs.FIND_BUSINESS_SOLUTION
                                        ? <FindBusinessSolutions showComingSoonModal={this.showComingSoonModal} show={this.state.activeHomeTab === HomeTabs.FIND_BUSINESS_SOLUTION} />
                                        : null
                                }
                                {
                                    this.state.activeHomeTab === HomeTabs.AWESOME_TOOLS
                                        ? <AwesomeTools show={this.state.activeHomeTab === HomeTabs.AWESOME_TOOLS} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ComingSoonModal />
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    isFreeTaxRedirected: state.authReducer.isFreeTaxRedirected,
    id: state.authReducer.id
});

const mapStateToDispatch = (dispatch) => ({
    getChangePasswordStatus: (request) => dispatch(action.getChangePasswordStatus(request)),
    getPhoneVerifiedStatus: (request) => dispatch(action.getPhoneVerifiedStatus(request)),
    form8821Status: (request) => dispatch(action.form8821Status(request)),
    getUser: (credentials) => dispatch(action.getUser(credentials))
});

export default connect(mapStateToProps, mapStateToDispatch)(ServiceWrapper(Home));

const MyService = (props) => {
    return (
        <div className="col-xl-4 col-lg-6 col-sm-6 col_p8">
            <a onClick={props.myServiceHandler} className="card_services_link">
                <div className="card services_card">
                    <div className="card_top_banner banner_blue" style={{ background: props.background }}>
                        <img src={props.icon} style={{ filter: 'saturate(2)' }} className="banner_top_img" alt="Tax Report" />
                        <h5 className="card-title">{props.name}</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text">
                            <span style={{ display: 'inline' }} className="servie_title">Status:</span>
                            <span className="servie_val ml-2">{props.status}</span>
                        </p>
                        <p className="card-text">
                            {/* <span className="servie_title">Balance::</span>
                            <span className="servie_val">Free</span> */}
                        </p>
                    </div>
                </div>
            </a>
        </div>
    );
}

const NoServiceActivated = (props) => {
    return (
        <div className="col-xl-4 col-lg-6 col-sm-6 col_p8">
            <a className="card_services_link">
                <div className="card services_card">
                    <div className="card_top_banner banner_gray">
                        <h5 className="card-title">No service activated yet!</h5>
                    </div>
                </div>
            </a>
        </div>
    );
}

const SkeletonLoading = () => {
    return (
        <Oux>
            {
                [...Array(3)].map(ele => {
                    return <div className="col-xl-4 col-lg-6 col-sm-6 col_p8">
                        <div className="card services_card">
                            <Skeleton height={170} />
                        </div>
                    </div>
                })
            }
        </Oux>
    )
}

const AvailableService = (props) => {
    return (
        <div className="col-xl-4 col-lg-6 col-sm-6 col_p8" >
            <a onClick={props.activateService} className="card_services_link">
                <div className="card services_card">
                    <div className="card_top_banner banner_gray" style={{ background: props.background }}>
                        {props.isActivatingService && <span className="aservice_loader">
                            <Spinner width={25} height={25} />
                        </span>}
                        <img src={props.icon} style={{ filter: 'saturate(2)' }} className="banner_top_img" alt="Tax Report" />
                        <h5 className="card-title">{props.name}</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text">
                            <span style={{ display: 'inline' }} className="servie_title">Status:</span>
                            <span className="servie_val ml-2">Activate Now</span>
                        </p>
                        <p className="card-text" >
                            {/* <span className="servie_title">Balance::</span>
                            <span className="servie_val">Free</span> */}
                        </p>
                    </div>

                </div>
            </a>
        </div>
    );
}