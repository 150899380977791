export const UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO"
export const UPDATE_PERSONAL_INFO_PENDING = "UPDATE_PERSONAL_INFO_PENDING"
export const UPDATE_PERSONAL_INFO_FULFILLED = "UPDATE_PERSONAL_INFO_FULFILLED"

export const BillingActionTypes = {

    FETCH_BILLING_SCHEDULE: "FETCH_BILLING_SCHEDULE",
    FETCH_BILLING_SCHEDULE_PENDING: "FETCH_BILLING_SCHEDULE_PENDING",
    FETCH_BILLING_SCHEDULE_FULFILLED: "FETCH_BILLING_SCHEDULE_FULFILLED",

    BILLING_PAYMENT_CREATE: "BILLING_PAYMENT_CREATE",
    BILLING_PAYMENT_CREATE_PENDING: "BILLING_PAYMENT_CREATE_PENDING",
    BILLING_PAYMENT_CREATE_FULFILLED: "BILLING_PAYMENT_CREATE_FULFILLED",


    // My Account Billing Section Starts Here

    GET_USER_BILLING_DETAILS: "GET_USER_BILLING_DETAILS",
    GET_USER_BILLING_DETAILS_PENDING: "GET_USER_BILLING_DETAILS_PENDING",
    GET_USER_BILLING_DETAILS_FULFILLED: "GET_USER_BILLING_DETAILS_FULFILLED",

    SAVE_USER_BILLING_DETAILS: "SAVE_USER_BILLING_DETAILS",
    SAVE_USER_BILLING_DETAILS_PENDING: "SAVE_USER_BILLING_DETAILS_PENDING",
    SAVE_USER_BILLING_DETAILS_FULFILLED: "SAVE_USER_BILLING_DETAILS_FULFILLED"

    //My Account Billing Section Ends Here
}

export const BusinessActionTypes = {
    GET_USER_BUSINESS: "GET_USER_BUSINESS",
    GET_USER_BUSINESS_PENDING: "GET_USER_BUSINESS_PENDING",
    GET_USER_BUSINESS_FULFILLED: "GET_USER_BUSINESS_FULFILLED",

    UPDATE_USER_BUSINESS: "UPDATE_USER_BUSINESS",
    UPDATE_USER_BUSINESS_PENDING: "UPDATE_USER_BUSINESS_PENDING",
    UPDATE_USER_BUSINESS_FULFILLED: "UPDATE_USER_BUSINESS_FULFILLED",

    DELETE_USER_BUSINESS: "DELETE_USER_BUSINESS",
    DELETE_USER_BUSINESS_PENDING: "DELETE_USER_BUSINESS_PENDING",
    DELETE_USER_BUSINESS_FULFILLED: "DELETE_USER_BUSINESS_FULFILLED"
}

export const NotificationActionTypes = {
    GET_USER_NOTIFICATIONS: "GET_USER_NOTIFICATIONS",
    GET_USER_NOTIFICATIONS_PENDING: "GET_USER_NOTIFICATIONS_PENDING",
    GET_USER_NOTIFICATIONS_FULFILLED: "GET_USER_NOTIFICATIONS_FULFILLED"
}
