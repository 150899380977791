import axios from 'axios';
import swal from 'sweetalert';
import { lowerCase } from 'lodash';
import store from '../../redux/store/store';
import { toastMsg } from '../utility';

export default function errorHandler(error) {
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }
    if (error.data) {
        toastMsg(error.data.error.message, true, 5000);
    } else if (error.message) {
        toastMsg(error.message, true, 5000);
    }

    return Promise.reject(error.message);
}
