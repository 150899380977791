import React, { Component } from 'react'
import LoginForm from '../../components/LoginForm/LoginForm'
import { connect } from 'react-redux'
import * as actionTypes from '../../redux/actions/index'
import { routes } from '../../utility/constants/constants';
import base64 from 'base-64'
import storage from '../../utility/storage';
import { toastMsg } from '../../utility/utility';

class Login extends Component {

    UNSAFE_componentWillMount() {
        window.addEventListener("popstate", e => {
            // Nope, go back to your page
            if (this.props.history.location.pathname + this.props.history.location.hash === routes.REGISTER + routes.VALIDATE_ACCOUNT || this.props.history.location.pathname + this.props.history.location.hash === routes.REGISTER + routes.TICKET_SUBMITTED) {
                this.props.history.push(routes.HOME);
            }
        });
    }

    componentDidMount = () => {
        if (this.props.history.location.search) {
            this.props.toggleFreeTaxRedirected(true, {
                freeTaxChangePasswordStatus: "no",
                freeTax8821ContractStatus: "no",
                freeTaxNumberVerifiedStatus: "no"
            })
            let userEmail = base64.decode(new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('email'))
            let userPassword = base64.decode(new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('password'))
            if (userEmail && userPassword) {
                this.props.loginUser({ email: userEmail, password: userPassword, type: 'SignIn' })
                    .then(response => {
                        if (response.value.result === "Success!" || response.value.result === "Success") {
                            //success
                        } else {
                            if (response.value.error && response.value.error.message === "EMAIL_NOT_FOUND") {
                                this.props.history.push(routes.REGISTER)
                                toastMsg("No email found! Please Register", true)
                                storage.set("isFreeTaxRedirected", false)
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
            }
        }
    }

    render() {
        return (
            <LoginForm apiError={this.props.error} isLoading={this.props.isLoading} loginUser={this.props.loginUser} />
        )
    }
}

const mapState = state => {
    return {
        isLoading: state.authReducer.isloading,
        error: state.authReducer.error
    }
}

const mapDispatch = dispatch => {
    return {
        loginUser: (credentials) => dispatch(actionTypes.login(credentials)),
        toggleFreeTaxRedirected: (flag, status) => dispatch(actionTypes.toggleFreeTaxRedirected(flag, status))
    }
}

export default connect(mapState, mapDispatch)(Login)