import React from 'react'
const comingSoonModal = props => {
    return (
        <div className="modal fade" id="coming_soon_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <a href="javascript:void();" type="button" className="close p-0 m-0 ml-auto d-block" data-dismiss="modal" aria-label="Close">
                            <img src="/images/icons/icn_close_gray.svg" alt="Icon Close" />
                        </a>
                        <img style={{ width: '100%' }} src="/images/customs/coming_soon.png" />
                        {/* <a data-dismiss="modal" style={{ color: '#fff' }} className="btn btn-primary cst_btn">Close</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default comingSoonModal