import * as accountActionTypes from '../../actions/myAccountActions/actionType'

export const initialState = {
    isloading: false,
    isSubmitButtonLoading: false,
    error: null,
    billingData: null,
    businessUser: null,
    userBillingDetails: null,
    userNotifications: null,
    isBillingApiError: false
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const myAccountReducer = (state = initialState, action) => {
    switch (action.type) {

        case accountActionTypes.UPDATE_PERSONAL_INFO_PENDING:
            return updateObject(state, { isloading: true });
        case accountActionTypes.UPDATE_PERSONAL_INFO_FULFILLED:
            return updateObject(state, { isloading: false });

        case accountActionTypes.BillingActionTypes.FETCH_BILLING_SCHEDULE_PENDING:
            return updateObject(state, { isloading: true })
        case accountActionTypes.BillingActionTypes.FETCH_BILLING_SCHEDULE_FULFILLED:
            return updateObject(state, { isloading: false, billingData: action.payload ? action.payload.data : state.billingData })

        case accountActionTypes.BillingActionTypes.BILLING_PAYMENT_CREATE_PENDING:
            return updateObject(state, { isloading: true, isSubmitButtonLoading: true })
        case accountActionTypes.BillingActionTypes.BILLING_PAYMENT_CREATE_FULFILLED:
            return updateObject(state, { isloading: false, isSubmitButtonLoading: false })

        case accountActionTypes.BusinessActionTypes.GET_USER_BUSINESS_PENDING:
            return updateObject(state, { isloading: true, isSubmitButtonLoading: true })
        case accountActionTypes.BusinessActionTypes.GET_USER_BUSINESS_FULFILLED:
            return updateObject(state, { isloading: false, isSubmitButtonLoading: false, businessUser: action.payload ? action.payload.data : state.businessUser })

        case accountActionTypes.BusinessActionTypes.UPDATE_USER_BUSINESS_PENDING:
            return updateObject(state, { isloading: true, isSubmitButtonLoading: true })
        case accountActionTypes.BusinessActionTypes.UPDATE_USER_BUSINESS_FULFILLED:
            return updateObject(state, { isloading: false, isSubmitButtonLoading: false })

        case accountActionTypes.BusinessActionTypes.DELETE_USER_BUSINESS_PENDING:
            return updateObject(state, { isloading: true, isSubmitButtonLoading: true })
        case accountActionTypes.BusinessActionTypes.DELETE_USER_BUSINESS_FULFILLED:
            return updateObject(state, { isloading: false, isSubmitButtonLoading: false })

        case accountActionTypes.BillingActionTypes.GET_USER_BILLING_DETAILS_PENDING:
            return updateObject(state, { isloading: true })
        case accountActionTypes.BillingActionTypes.GET_USER_BILLING_DETAILS_FULFILLED:
            return updateObject(state, { isloading: false, userBillingDetails: action.payload ? action.payload.data : state.userBillingDetails })

        case accountActionTypes.BillingActionTypes.SAVE_USER_BILLING_DETAILS_PENDING:
            return updateObject(state, { isloading: true, isSubmitButtonLoading: true })
        case accountActionTypes.BillingActionTypes.SAVE_USER_BILLING_DETAILS_FULFILLED:
            return updateObject(state, { isloading: false, isSubmitButtonLoading: false, isBillingApiError: action.payload && action.payload.isBillingApiError ? true : false })

        case accountActionTypes.NotificationActionTypes.GET_USER_NOTIFICATIONS_PENDING:
            return updateObject(state, { isloading: true, isSubmitButtonLoading: true })
        case accountActionTypes.NotificationActionTypes.GET_USER_NOTIFICATIONS_FULFILLED:
            return updateObject(state, { isloading: false, isSubmitButtonLoading: false, userNotifications: action.payload ? action.payload.data : state.userNotifications })
        default: return state;
    }
}