import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import BillingsPage from '../../components/Billing/Billing';
import * as actionTypes from '../../redux/actions/index'

class Billing extends React.Component {

    state = {
        showRightContent: false,
        selectedPaymentInfo: null,
        selectedPrimaryPaymentMethod: null
    }

    toggleShowRightContent = (paymentInfo) => {
        if (this.state.selectedPaymentInfo && this.state.selectedPaymentInfo.CaseId === paymentInfo.CaseId) {
            this.setState({ showRightContent: false, selectedPaymentInfo: null })
        }
        else {
            this.setState({ showRightContent: true, selectedPaymentInfo: paymentInfo })
        }
    }

    componentDidMount() {
        this.props.fetchBillingPaymentSchedule({ caseid: this.props.caseid, type: 'PaymentSchedule' })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.userBillingDetails === null && this.props.userBillingDetails !== null) {
            this.props.userBillingDetails.map(billingDetails => {
                if (billingDetails.PrimaryAccount) {
                    this.setState({ selectedPrimaryPaymentMethod: billingDetails })
                }
            })
        }
        if (prevProps.service !== this.props.service) {
            this.setState({ showPaymentOption: false })
        }
    }

    render() {
        return (
            <main className="main_section">
                <div className="main_cont">
                    <BillingsPage
                        selectedPrimaryPaymentMethod={this.state.selectedPrimaryPaymentMethod}
                        selectedPaymentInfo={this.state.selectedPaymentInfo}
                        showRightContent={this.state.showRightContent}
                        // closeRightContent={this.closeRightContent}
                        toggleShowRightContent={this.toggleShowRightContent}
                        isLoading={this.props.isLoading}
                        createBillingPayment={this.props.createBillingPayment}
                        caseid={this.props.user.caseid}
                        isSubmitLoading={this.props.isSubmitLoading}
                        paymentInfo={this.props.paymentInfo} />
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    caseid: state.authReducer.case_id,
    paymentInfo: state.myAccountReducer.billingData,
    isSubmitLoading: state.myAccountReducer.isSubmitButtonLoading,
    isLoading: state.myAccountReducer.isloading,
    userBillingDetails: state.myAccountReducer.userBillingDetails
});

const mapStateToDispatch = (dispatch) => ({
    fetchBillingPaymentSchedule: (credentials) => dispatch(actionTypes.fetchBillingPaymentSchedule(credentials)),
    createBillingPayment: (credentials) => dispatch(actionTypes.createBillingPayment(credentials))
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Billing));


