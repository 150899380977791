import React, { useState, useEffect } from 'react'
import { Form, Formik, Field } from 'formik'
import Spinner from '../../UI/Spinner/Spinner'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import './BillingInformation.css'
import { InputCheckbox, InputField } from '../../UI/FormInputFields/FormInputFields'
import { validateBillingInformation, validateBillingAccountInformation } from '../../FormValidation/FormValidation'
import $ from 'jquery'
import Oux from '../../../hoc/Oux/Oux'
import Skeleton from 'react-loading-skeleton'
import storage from '../../../utility/storage'
import { dateFormat } from '../../../utility/constants/constants';
// import { AddCardForm, AddBankAccountForm } from './BillingInformationForm/BillingInformationForm';

export const AddCardForm = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    let expiry_date = new Date()
    let billingInfoExpDate = props.billingInfo ? props.billingInfo.CCExpDate.replace(/[/]/g, "") : null

    console.log(props.billingInfo)
    if (props.billingInfo) {
        let exp_month = billingInfoExpDate.substring(0, 2)
        let exp_year = billingInfoExpDate.substring(2, props.billingInfo.CCExpDate.length)
        expiry_date = new Date(exp_year, exp_month - 1)
    }

    return (
        <div className={props.divClassName} style={props.style ? props.style : null}>
            {props.viewExistingCardDetail ? <div className="custom-control custom-checkbox text-left mb-3">
                <button onClick={props.closeExistingForm} className="close_existing_billing_form_button" type="button"><img src="/images/customs/icn_close.svg" /></button>
            </div> : null}
            <Formik
                enableReinitialize={props.billingInfo ? true : false}
                // enableReinitialize={true}
                initialValues={props.billingInfo
                    ? {
                        primary_account: props.billingInfo.PrimaryAccount,
                        name_on_card: props.billingInfo.NameOnAccount,
                        card_number: props.billingInfo.CCNo,
                        // cvv: '230', 
                        expiry_date: expiry_date,
                        address: props.billingInfo.Address,
                        city: props.billingInfo.City,
                        state: props.billingInfo.State,
                        zip_code: props.billingInfo.Zip
                    }
                    : { expiry_date: new Date() }
                }
                onSubmit={(values, { setFieldTouched }) => {
                    let fullExpDate = new Date(values.expiry_date)
                    let formatted_date = dateFormat(fullExpDate, 'MMYYYY')
                    console.log(formatted_date)
                    values = {
                        ...values,
                        expiry_date: formatted_date
                    }
                    props.saveUserBillingDetails({ ...values, type: 'CaseAccountCreate', caseid: props.caseid, pay_type: '2', formType: 'add_card' })
                        .then(response => {
                            if (response.value && !response.value.error) {
                                props.toggleFormHidden()
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                }}
                validationSchema={props.viewHiddenForm ? validateBillingInformation : null}>
                {(formik_props) => {
                    let errors = formik_props.errors
                    let touched = formik_props.touched
                    let currentDate = new Date()
                    let currentYearAndMonth = new Date(currentDate.getFullYear(), currentDate.getMonth())
                    console.log(errors)
                    return (
                        <Form>
                            <div className="custom-control custom-checkbox text-left mb-3">
                                <InputCheckbox disabled={props.billingInfo && props.billingInfo.PrimaryAccount || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" checkbox_id="allServices" label="Make Default Payment Method" name="primary_account" values={formik_props.values} setFieldValue={formik_props.setFieldValue} />
                            </div>
                            <div className="form-group">
                                <InputField disabled={props.billingInfo && props.billingInfo.NameOnAccount || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="name_on_card" label="Name On Card" type="text" />
                            </div>
                            <div className="form-group">
                                <InputField disabled={props.billingInfo && props.billingInfo.CCNo || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="card_number" label="Card Number" type="text" />
                            </div>
                            <div className="form-group" style={errors.expiry_date && touched.expiry_date ? errorStyle : null}>
                                {
                                    props.viewExistingCardDetail
                                        ? <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                name="expiry_date"
                                                inputVariant="outlined"
                                                label="Expiry Date"
                                                id="custom_datepicker"
                                                className="form-control theme_input billing-information-form"
                                                // format="MM/yyyy"
                                                disabled={props.viewExistingCardDetail ? true : false}
                                                onChange={(event, date) => {
                                                    formik_props.setFieldValue("expiry_date", date)
                                                }}
                                                views={["month", "year"]}
                                                value={formik_props.values.expiry_date}
                                                InputAdornmentProps={{ position: "start" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        : <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant="inline"
                                                name="expiry_date"
                                                inputVariant="outlined"
                                                label="Expiry Date"
                                                id="custom_datepicker"
                                                className="form-control theme_input billing-information-form"
                                                // format="MM/yyyy"
                                                disabled={props.viewExistingCardDetail ? true : false}
                                                onChange={(event, date) => {
                                                    formik_props.setFieldValue("expiry_date", date)
                                                }}
                                                inputProps={{ readOnly: true }}
                                                views={["month", "year"]}
                                                minDate={currentYearAndMonth}
                                                value={formik_props.values.expiry_date}
                                                InputAdornmentProps={{ position: "start" }}
                                            />
                                        </MuiPickersUtilsProvider>
                                }
                                {/* <InputField disabled={props.billingInfo && props.billingInfo.CCExpDate ? true : false} form_class_name="billing-information-form" name="expiry_date" label="Expiry Date" type="text" /> */}
                            </div>
                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.expiry_date && touched.expiry_date && errors.expiry_date}</span>
                            <br />
                            <AddressFields viewExistingCardDetail={props.viewExistingCardDetail} />
                            {
                                props.viewExistingCardDetail
                                    ? props.isLoading
                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                        : <div className="btn_block">
                                            {/* <button type="button" onClick={() => props.updateBillingInfo(formik_props.values)} className="btn btn-primary cst_btn mb-3">Save</button> */}
                                            {/* <button className="btn btn-danger cst_btn">Delete</button> */}
                                        </div>
                                    : null

                            }
                            {
                                props.viewHiddenForm
                                    ? props.isLoading
                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                        : <button type="submit" disabled={!formik_props.dirty || !formik_props.isValid} className="btn btn-primary cst_btn">Save</button>
                                    : null
                            }
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export const AddBankAccountForm = props => {

    console.log(props.billingInfo)

    return (
        <div className={props.divClassName} style={props.style ? props.style : null}>
            {props.viewExistingBankDetail ? <div className="custom-control custom-checkbox text-left mb-3">
                <button onClick={props.closeExistingForm} className="close_existing_billing_form_button" type="button"><img src="/images/customs/icn_close.svg" /></button>
            </div> : null}
            <Formik
                enableReinitialize={props.billingInfo ? true : false}
                initialValues={props.billingInfo
                    ? {
                        primary_account: props.billingInfo.PrimaryAccount,
                        bank_name: props.billingInfo.BankName,
                        account_number: props.billingInfo.BankAccountNo,
                        account_holder_name: props.billingInfo.NameOnAccount,
                        route_number: props.billingInfo.BankRoutingNo,
                        address: props.billingInfo.Address,
                        city: props.billingInfo.City,
                        state: props.billingInfo.State,
                        zip_code: props.billingInfo.Zip
                    }
                    : {}}
                onSubmit={(values) => {
                    props.saveUserBillingDetails({ ...values, type: 'CaseAccountCreate', caseid: props.caseid, pay_type: '3' })
                        .then(response => {
                            if (response.value && !response.value.error) {
                                props.toggleBankFormHidden()
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    // if (!props.isBillingApiError) {
                    //     props.toggleBankFormHidden()
                    // }
                }}
                validationSchema={props.viewHiddenBankForm ? validateBillingAccountInformation : null}
            >
                {(formik_props) => {
                    console.log(formik_props.errors)
                    return (
                        <Form>
                            <div className="custom-control custom-checkbox text-left mb-3">
                                <InputCheckbox disabled={props.viewExistingBankDetail ? true : false} form_class_name="billing-information-form" checkbox_id="allServices" label="Make Default Payment Method" name="primary_account" values={formik_props.values} setFieldValue={formik_props.setFieldValue} />
                            </div>
                            <div className="form-group">
                                <InputField disabled={props.viewExistingBankDetail ? true : false} form_class_name="billing-information-form" name="account_holder_name" label="Account Holder Name" type="text" />
                            </div>
                            <div className="form-group">
                                <InputField disabled={props.viewExistingBankDetail ? true : false} form_class_name="billing-information-form" name="bank_name" label="Bank Name" type="text" />
                            </div>
                            <div className="form-group">
                                <InputField disabled={props.viewExistingBankDetail ? true : false} form_class_name="billing-information-form" name="route_number" label="Route Number" type="text" />
                            </div>
                            <div className="form-group">
                                <InputField disabled={props.viewExistingBankDetail ? true : false} form_class_name="billing-information-form" name="account_number" label="Account Number" type="text" />
                            </div>
                            <AddressFields viewExistingBankDetail={props.viewExistingBankDetail} />
                            {
                                props.viewExistingBankDetail
                                    ? props.isLoading
                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                        : <div className="btn_block">
                                            {/* <button type="button" onClick={() => props.updateBillingInfo(formik_props.values)} className="btn btn-primary cst_btn mb-3">Save</button> */}
                                            {/* <button className="btn btn-danger cst_btn">Delete</button> */}
                                        </div>
                                    : null

                            }
                            {
                                props.viewHiddenBankForm
                                    ? props.isLoading
                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                        : <button type="submit" className="btn btn-primary cst_btn">Save</button>
                                    : null
                            }
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export const AddressFields = props => {
    return (
        <Oux>
            <div className="form-group">
                <InputField disabled={props.viewExistingBankDetail || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="address" label="Full Address" type="text" />
            </div>
            {/* <div className="form-group">
                <InputField disabled={props.viewExistingBankDetail || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="address_line_2" label="Address Line 2" type="text" />
            </div> */}
            <div className="form-group">
                <InputField disabled={props.viewExistingBankDetail || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="city" label="City" type="text" />
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <InputField disabled={props.viewExistingBankDetail || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="state" label="State" type="text" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <InputField disabled={props.viewExistingBankDetail || props.viewExistingCardDetail ? true : false} form_class_name="billing-information-form" name="zip_code" label="Zip Code" type="text" />
                    </div>
                </div>
            </div>
        </Oux>
    )
}

const BillingInformation = props => {

    let [formHidden, setFormHidden] = useState(false)
    let [bankFormHidden, setBankFormHidden] = useState(false)
    let [existingBankForm, setExistingBankForm] = useState(false)
    let [existingCardForm, setExistingCardForm] = useState(false)
    let [showDropDown, setShowDropDown] = useState(false)
    let [selectedCardIndex, setSelectedCardIndex] = useState(null)
    let [isLoading, setIsLoading] = useState(false)
    let [billingInfo, setBillingInfo] = useState(null)

    console.log(props.userBillingDetails)

    useEffect(() => {
        // do something on route change
        // for my example, close a drawer
        console.log(props)
        props.getUserBillingDetails({ type: 'CaseAccountGet', caseid: props.caseid ? props.caseid : storage.get('case_id', null) })
    }, [])

    const toggleFormHidden = () => {
        setBankFormHidden(false)
        setExistingBankForm(false)
        setExistingCardForm(false)
        setFormHidden(!formHidden)
    }

    const toggleBankFormHidden = () => {
        setExistingBankForm(false)
        setExistingCardForm(false)
        setFormHidden(false)
        setExistingBankForm(false)
        setExistingCardForm(false)
        setBankFormHidden(!bankFormHidden)
    }

    const toggleCardFormHidden = () => {
        setExistingBankForm(false)
        setExistingCardForm(false)
        setBankFormHidden(false)
        setFormHidden(!formHidden)
    }

    const toggleExistingBankForm = () => {
        setFormHidden(false)
        setBankFormHidden(false)
        setExistingBankForm(false)
        setExistingCardForm(false)
        setExistingBankForm(!existingBankForm)
    }

    const onSubmitBillingInfo = (values) => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            console.log("Billing Info Submitted", values)
        }, 2000)
    }

    const onUpdateBillingInfo = (values) => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            console.log("Billing Info Updated", values)
        }, 2000)
    }

    const selectedCard = (event, index) => {
        setSelectedCardIndex(index)
        return event.target.checked ? props.getSelectedCard(props.userBillingDetails[index]) : null
    }

    const toggleForms = (billingDetails, index) => {
        $(".target_user_pro").removeClass("target_user_pro_active");
        if (window.screen.width <= 768) {
            $('html, body').animate({
                scrollTop: $("#existing_billing_details").offset().top
            }, 2000);
        }
        else {
            window.scroll(0, 0);
        }
        if (billingDetails.AccountType === 2) {
            setExistingBankForm(false)
            setFormHidden(false)
            setBankFormHidden(false)
            setExistingCardForm(false)
            if (formHidden || bankFormHidden) {
                setBillingInfo(null)
            }
            if (billingInfo && billingInfo.index === index) {
                setBillingInfo(null)
                setExistingCardForm(false)
            }
            else {
                setFormHidden(false)
                setBankFormHidden(false)
                $("#target_user_pro" + index).addClass("target_user_pro_active");
                // setIsLoading(true)
                setBillingInfo({ ...billingDetails, index: index })
                setExistingCardForm(true)
                // $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
            }
        }
        else {
            setExistingCardForm(false)
            if (billingInfo && billingInfo.index === index) {
                setBillingInfo(null)
                setExistingBankForm(false)
            }
            else {
                setFormHidden(false)
                setBankFormHidden(false)
                $("#target_user_pro" + index).addClass("target_user_pro_active");
                // setIsLoading(true)
                setBillingInfo({ ...billingDetails, index: index })
                setExistingBankForm(true)
                // $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
            }
        }
    }

    const closeExistingForm = () => {
        window.scroll(0, 0);
        setExistingBankForm(false)
        setFormHidden(false)
        setBankFormHidden(false)
        setExistingCardForm(false)
        setBillingInfo(null)
    }

    const fetchCardType = (CC_TYPE) => {
        if (CC_TYPE === 1) {
            return "/images/customs/icn_visa.svg"
        }
        if (CC_TYPE === 2) {
            return "/images/customs/icn_mastercard.svg"
        }
        if (CC_TYPE === 3) {
            return "/images/customs/icn_american_express.svg"
        }
        if (CC_TYPE === 4) {
            return "/images/customs/icn_discover.svg"
        }
        else {
            return "/images/customs/icn_dummy_credit_card.svg"
        }
    }

    if (props.userBillingDetails) {
        props.userBillingDetails.sort((a, b) => {
            if (a.PrimaryAccount || b.primary_account) {
                return -1
            }
            else {
                return 1
            }
        })
    }

    return (
        <div className="tab-pane fade active show" id="v-pills-Billing-Information" role="tabpanel"
            aria-labelledby="v-pills-Billing-Information-tab">
            <div className="fb_tabs_inner fp_tabs_cont contact_us business_information doc_tab_content">
                <div className="fp_tabs_inner_40 fp_tabs_cell">
                    <div className="fp_invs_inner_block">
                        <h1 className="fp_sec_title">Billing Information
                                    {
                                props.userBillingDetails
                                    ? formHidden || bankFormHidden
                                        ? <a href="javascript:void(0)" style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setShowDropDown(false)
                                                setFormHidden(false)
                                                setBankFormHidden(false)
                                            }} className="add_icn billing-form-dropbtn">
                                            <div className="billing-form-dropdown">
                                                <img src="/images/customs/icn_minus.svg" alt="Icon" />
                                                {/* <button className="btn btn-primary cst_btn billing-form-dropbtn"><img src="/images/icons/icn_plus.png" alt="Icon" /></button> */}
                                            </div>
                                        </a>
                                        : <a href="javascript:void(0)" style={{ cursor: 'pointer' }} onClick={() => setShowDropDown(!showDropDown)} className="add_icn billing-form-dropbtn">
                                            <div className="billing-form-dropdown">
                                                <img src="/images/icons/icn_plus.png" alt="Icon" />
                                                {/* <button className="btn btn-primary cst_btn billing-form-dropbtn"><img src="/images/icons/icn_plus.png" alt="Icon" /></button> */}
                                                <div style={showDropDown ? { display: 'block' } : { display: 'none' }} class="billing-form-dropdown-content">
                                                    <a onClick={toggleCardFormHidden}>Add Card Details</a>
                                                    <a onClick={toggleBankFormHidden}>Add Bank Details</a>
                                                </div>
                                            </div>
                                        </a>
                                    : null
                            }
                        </h1>
                    </div>
                    {!props.userBillingDetails ?
                        props.isMyAccountLoading
                            ? <Oux><Skeleton height={100} /><br /><br /><Skeleton height={100} /></Oux>
                            : <div className="business_info_content">
                                <div className="img_block">
                                    <img src="/images/icons/billing_info.png" alt="billing information" />
                                </div>
                                <p className="gray_txt">Add payment method so you can pay <br /> invoices with just one tap.</p>
                                <div className="billing-form-dropdown">
                                    <a href="JavaScript:void(0);" className="btn btn-primary cst_btn billing-form-dropbtn">Add Payment Method</a>
                                    <div class="billing-form-dropdown-content">
                                        <a onClick={toggleCardFormHidden}>Add Card Details</a>
                                        <a onClick={toggleBankFormHidden}>Add Bank Details</a>
                                    </div>
                                </div>
                            </div>
                        : null}
                    {formHidden ? <AddCardForm isBillingApiError={props.isBillingApiError} toggleFormHidden={toggleFormHidden} caseid={props.caseid} saveUserBillingDetails={props.saveUserBillingDetails} submitBillingInfo={onSubmitBillingInfo} isLoading={props.isSubmitLoading} viewHiddenForm={formHidden} divClassName="business_info_content" /> : null}
                    {bankFormHidden ? <AddBankAccountForm isBillingApiError={props.isBillingApiError} toggleBankFormHidden={toggleBankFormHidden} caseid={props.caseid} saveUserBillingDetails={props.saveUserBillingDetails} submitBillingInfo={onSubmitBillingInfo} isLoading={props.isSubmitLoading} viewHiddenBankForm={bankFormHidden} divClassName="business_info_content" /> : null}
                    {/* Existing Cards Section Starts Here */}
                    <div className="fp_timeline_block">

                        {
                            props.userBillingDetails
                                ? props.userBillingDetails.map((billingDetails, index) => {
                                    let card_type_icon = fetchCardType(billingDetails.CCType)
                                    return (
                                        <div key={index} id={"target_user_pro" + index} className="timeline-item add_info_box target_user_pro">
                                            <div
                                                onClick={props.isServiceSectionPage ? null : () => toggleForms(billingDetails, index)}
                                                // onClick={() => setExistingCardForm(!existingCardForm)}
                                                style={{ cursor: 'pointer' }} className="fp_inner_body pl_0 pr_0">
                                                <h4 className="item-title pl_15 pr_15">
                                                    {
                                                        props.isServiceSectionPage
                                                            ? <Formik
                                                                enableReinitialize={true}
                                                                initialValues={{}}
                                                                onSubmit={(values) => {
                                                                    console.log(values)
                                                                }}>
                                                                {(formik_props) => {
                                                                    return (
                                                                        <div className="custom-control custom-checkbox text-left mb-3">
                                                                            {/* <InputCheckbox disabled={props.billingInfo && props.billingInfo.PrimaryAccount ? true : false} form_class_name="billing-information-form" checkbox_id="allServices" label="Make Default Payment Method" name="primary_account" values={formik_props.values} setFieldValue={formik_props.setFieldValue} /> */}
                                                                            <Field
                                                                                type="checkbox"
                                                                                id={"card_" + index}
                                                                                name={"card_selected_checkbox_" + index}
                                                                                onChange={(event) => selectedCard(event, index)}
                                                                                className="custom-control-input billing-information-form"
                                                                                checked={selectedCardIndex ? index === selectedCardIndex : billingDetails.PrimaryAccount} />
                                                                            <label className="custom-control-label mt-1" htmlFor={"card_" + index}></label>
                                                                            {
                                                                                billingDetails.AccountType === 2
                                                                                    ? <Oux>
                                                                                        <img
                                                                                            src={card_type_icon}
                                                                                            alt="VISA"
                                                                                            className="mr-2" /><span>
                                                                                            {billingDetails.CCNo}</span>
                                                                                    </Oux>
                                                                                    : <Oux>
                                                                                        <img
                                                                                            src={card_type_icon}
                                                                                            alt="VISA"
                                                                                            className="mr-2" /><span>
                                                                                            {billingDetails.BankAccountNo}</span>
                                                                                    </Oux>
                                                                            }
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Formik>
                                                            : billingDetails.AccountType === 2
                                                                ? <Oux>
                                                                    <img
                                                                        src={card_type_icon}
                                                                        alt="VISA"
                                                                        className="mr-2" /><span>
                                                                        {billingDetails.CCNo}</span>
                                                                </Oux>
                                                                : <Oux>
                                                                    <img
                                                                        src={card_type_icon}
                                                                        alt="VISA"
                                                                        className="mr-2" /><span>
                                                                        {billingDetails.BankAccountNo}</span>
                                                                </Oux>
                                                    }
                                                </h4>
                                                {
                                                    billingDetails.AccountType === 2
                                                        ? <Oux>
                                                            <a href="javascript:void(0)" className="fp_ativity_icons">
                                                                <span className="fp_activity_icn_txt mb-2">Expires : {billingDetails.CCExpDate.length === 6 ? billingDetails.CCExpDate.substring(0, 2) + '/' + billingDetails.CCExpDate.substring(2, billingDetails.CCExpDate.length) : billingDetails.CCExpDate}</span>
                                                            </a>
                                                            <h6 className="h6_title pl_15 pr_15">{billingDetails.PrimaryAccount ? 'Default Payment Method' : null}</h6>
                                                        </Oux>
                                                        : <Oux>
                                                            <a href="javascript:void(0)" className="fp_ativity_icons">
                                                                <span className="fp_activity_icn_txt mb-2">Bank Name : {billingDetails.BankName}</span>
                                                            </a>
                                                            <h6 className="h6_title pl_15 pr_15">{billingDetails.PrimaryAccount ? 'Default Payment Method' : null}</h6>
                                                        </Oux>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                        }
                        {/* Existing Cards Section Ends Here */}
                    </div>
                </div>
                {/* Existing Card Details Section Starts Here */}
                {
                    props.isServiceSectionPage
                        ? null
                        : <div className="fp_tabs_inner_60 fp_tabs_cell">
                            <div className="fp_inner_user_pro" id="existing_billing_details">
                                {/* {existingCardForm || existingBankForm ? <button onClick={closeExistingForm} className="close_exisiting_billing_form_button" type="button"><img src="/images/customs/icn_close.svg" /></button> : null} */}
                                {existingCardForm ? <AddCardForm closeExistingForm={closeExistingForm} billingInfo={billingInfo} isLoading={isLoading} updateBillingInfo={onUpdateBillingInfo} viewExistingCardDetail={existingCardForm} divClassName="fp_media_user_pro fp_media_user_pro_block account_billing_info" style={{ display: 'block', paddingTop: '0px' }} /> : null}
                                {existingBankForm ? <AddBankAccountForm closeExistingForm={closeExistingForm} billingInfo={billingInfo} isLoading={isLoading} updateBillingInfo={onUpdateBillingInfo} viewExistingBankDetail={existingBankForm} divClassName="fp_media_user_pro fp_media_user_pro_block account_billing_info" style={{ display: 'block', paddingTop: '0px' }} /> : null}
                            </div>
                        </div>
                }
                {/* Existing Card Details Section Ends Here */}
            </div>
        </div >
    )
}

export default BillingInformation