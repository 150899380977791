import React, { useState, useEffect } from 'react'
import Spinner from '../UI/Spinner/Spinner'
import Skeleton from 'react-loading-skeleton';
import Oux from '../../hoc/Oux/Oux'
import './Billing.css'
import BillingInformation from '../MyAccount/BillingInformation/BillingInformation';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actions/index'

const Billing = props => {

    let [selectedCard, setSelectedCard] = useState(null)
    let [isFetchingData, setIsFetchingData] = useState(false)

    const getSelectedCard = (cardDetail) => {
        console.log(cardDetail)
        setSelectedCard({ cardDetail })
    }

    const continuePayment = (selectedCard, paymentInfo) => {
        let pay_type = null
        if (selectedCard.AccountType === 2) {
            pay_type = '1'
        }
        else if (selectedCard.AccountType === 3) {
            pay_type = '2'
        }
        props.createBillingPayment({
            caseid: props.caseid,
            pay_type: pay_type,
            amount: paymentInfo.Amount,
            type: 'CasePaymentCreate'
        })
        props.toggleShowRightContent(paymentInfo)
    }

    return (

        <div className="container fp_pl_0">
            {
                props.paymentInfo
                    ? null
                    : <div class="empty_details notifications">
                        <div class="card">
                            <div class="card-body">
                                <div className="empty_img">
                                    <img src="/images/customs/no_billings.png" />
                                    <h3 className="h3_title">There are no billings.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className="row">
                <div className="col-lg-6">
                    <div className="billing_sc">
                        <div className="account_service">
                            {props.paymentInfo ? <h3 className="h3_title">Billing</h3> : null}
                            {
                                props.paymentInfo
                                    ? props.paymentInfo.map((paymentInfo) => {
                                        return (
                                            paymentInfo.PaymentStatus === 'UnPaid'
                                                ? <div className="card cst_card">
                                                    <div className="card-body">
                                                        <span className="card_body_title"><i className="fa fa-clock-o mr-1" aria-hidden="true"></i>2019 Account + Services</span>
                                                        <span className="card_body_title as_cost">${paymentInfo.Amount}</span>
                                                    </div>
                                                    <div
                                                        onClick={() => props.toggleShowRightContent(paymentInfo)}
                                                        style={props.isSubmitLoading ? { cursor: 'pointer', backgroundColor: 'white' } : { cursor: 'pointer' }} className="card-footer">
                                                        {
                                                            !props.isSubmitLoading
                                                                ? <span>
                                                                    <i className="fa fa-credit-card mr-1"></i> Pay Now
                                                                </span>
                                                                : <span>
                                                                    <Spinner width='25px' height='25px' margin='0 auto' />
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                : null
                                        )
                                    })
                                    : props.isLoading
                                        ? <Oux><Skeleton height={120} /><br /><Skeleton height={120} /></Oux>
                                        : null
                            }
                        </div>
                        <div className="account_service as_display">
                            {
                                props.paymentInfo
                                    ? props.paymentInfo.map(paymentInfo => {
                                        return (
                                            paymentInfo.PaymentStatus === 'Paid'
                                                ? <div className="card cst_card">
                                                    <div className="card-body">
                                                        <span className="card_body_title"><img src="images/icons/Double_tick.png" alt="Icon" className="mr-1" />2019 Account + Services</span>
                                                        <span className="card_body_title as_cost">${paymentInfo.Amount}</span>
                                                        <h6 className="sm_title">Paid On: <span>10-05-2019</span></h6>
                                                        <h6 className="sm_title">Payment Method: <span>Visa ending in 6789</span></h6>
                                                    </div>
                                                </div>
                                                : null
                                        )
                                    })
                                    : null
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="billing_sc">
                        {
                            props.showRightContent
                                ? props.selectedPaymentInfo
                                    ? <div className="billings_section_right_content">
                                        <div className="billings_section_fp_inner_user_pro" id="billings_section_fp_inner_user_pro">
                                            <div className="billings_section_fp_media_user_pro fp_media_user_pro_block" style={{ display: 'block' }}>
                                                <h4 className="mt-0" style={{ fontWeight: 600 }}>Total Payment</h4>
                                                <h5 className="mt-0" style={{ fontWeight: 600, marginBottom: '0.5rem' }}>$ {props.selectedPaymentInfo.Amount}</h5>
                                                {/* <p style={{ marginBottom: '0.5rem' }}>{this.props.selectedService ? this.props.selectedService.name : null}</p> */}
                                                {
                                                    props.isSubmitLoading
                                                        ? <Spinner width='25px' height='25px' margin='0 auto' />
                                                        : <button type="button" onClick={() => {
                                                            return continuePayment(props.selectedPrimaryPaymentMethod ? props.selectedPrimaryPaymentMethod : selectedCard, props.selectedPaymentInfo)
                                                        }} className="btn btn-primary">Continue</button>
                                                }
                                                <br />
                                                <br />
                                                <hr style={{ borderTop: '2px solid rgba(0, 0, 0, 0.1)', marginTop: '0', marginBottom: '0' }} />
                                                <BillingInformation
                                                    caseid={props.user.caseid}
                                                    saveUserBillingDetails={props.saveUserBillingDetails}
                                                    userBillingDetails={props.userBillingDetails}
                                                    isLoading={props.isLoading}
                                                    isMyAccountLoading={props.isMyAccountLoading}
                                                    isSubmitLoading={props.isSubmitLoading}
                                                    getUserBillingDetails={props.getUserBillingDetails}
                                                    isServiceSectionPage={true}
                                                    getSelectedCard={getSelectedCard} />
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    isLoading: state.authReducer.isloading,
    isMyAccountLoading: state.myAccountReducer.isloading,
    isSubmitLoading: state.myAccountReducer.isSubmitButtonLoading,
    userBillingDetails: state.myAccountReducer.userBillingDetails
});

const mapDispatch = (dispatch) => ({
    getUserBillingDetails: (credentials) => dispatch(actionTypes.getUserBillingDetails(credentials)),
    saveUserBillingDetails: (credentials) => dispatch(actionTypes.saveUserBillingDetails(credentials)),
    // createBillingPayment: (credentials) => dispatch(actionTypes.createBillingPayment(credentials)),
});

export default connect(mapStateToProps, mapDispatch)(Billing)