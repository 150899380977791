import $ from 'jquery';

export function animateSidebar() {
    $(document).ready(function () {
        $("#sidebarCollapse").click(function () {
            console.log("click1")
            $("#sidebar").toggleClass("active");
            $("body").toggleClass("tb_sidebar_active");
            $(".main_section").toggleClass("active");
        });
        $("#sidebar").hover(function () {
            console.log("hover1")
            $("body").toggleClass("tb_sidebar_hover_active");
            // $(".main_section").toggleClass("active");
        });
        // $("#sidebar").hover(function () {
        //     console.log("hover2")
        //     $(".main_section").toggleClass("active");
        // });

        // $(".target_user_pro").click(function () {
        //     $(this).addClass("target_user_pro_active");
        // });
        // $(".target_user_pro").click(function () {
        //     $(".fp_media_user_pro_block").toggle();
        // });
        $(".fp_target_upl_statemet").click(function () {
            $(".fp_upload_bank_statement").toggle();
        });
        /*$( "#sidebar" ).mouseenter(" tb_sidebar_hover_active ").mouseleave( " tb_sidebar_hover_active " );*/
    });
    $(".hoverable_sidebar").hover(function () {
        $(this).addClass(".scrollbar");
    });

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
    }
    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }
} 