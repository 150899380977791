import * as Yup from 'yup'
import { notEqual } from 'assert';
import { type } from 'os';

export const registerValidateForm =
    Yup.object().shape({
        email: Yup.string().email('Please Enter a Valid Email').required('This field is required').nullable(),
        password: Yup.string().min(6, "6 char long").required("This field is required").nullable(),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], "Password Does Not Match").required('This field is required').nullable(),
        register_checkbox: Yup.bool()
            .test(
                'register_checkbox',
                'You have to agree with our Terms and Conditions!',
                value => value === true
            )
            .required(
                'You have to agree with our Terms and Conditions!'
            )
    })
export const loginValidateForm =
    Yup.object().shape({
        email: Yup.string().email('Please Enter a Valid Email').required('This field is required').nullable(),
        password: Yup.string().min(6, "6 char long").required("This field is required").nullable(),
    })

const PhNoPattern = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;

// country code compulsory check regex for phone number - /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/

export const submitTicketValidateForm =
    Yup.object().shape({
        message_description: Yup.string().required('This field is required').nullable(),
        ticket_mobile_number: Yup.string().matches(PhNoPattern, "Invalid Mobile Number").required("This field is required").nullable(),
        // old_mobile_number: Yup.string().matches(PhNoPattern, "Invalid Mobile Number").required("This field is required").nullable(),
    })

export const accountValidationForm =
    Yup.object().shape({
        ssn_number: Yup.string().matches(/^[0-9-\s]+$/, 'No Alphabets & Special Characters Allowed').test(
            'ssn_number',
            'SSN should be of 9 digits',
            val => {
                if (val) {
                    val = val.replace(/-/g, '')
                }
                return val && val.length === 9
            }
        ).required("This field is required"),
        date_of_birth: Yup.string().required("This field is required"),
    })

export const numberNotLinkedForm =
    Yup.object().shape({
        checkbox1: Yup.bool().required('This field is required'),
        checkbox2: Yup.bool().required("This field is required"),
    })

/* Field Level Validation Starts Here */
export const mobile_number_validate = (value) => {
    // const PhNoPattern = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
    let error;
    if (!value || value === '') {
        error = "This field is required"
    }
    else if (!PhNoPattern.test(value)) {
        error = "Mobile Number Invalid"
    }
    return error
}

export const country_code_validate = (value) => {
    let error;
    if (!value || value === '') {
        error = "This Country Code Field Is Required"
    }
    return error
}

export const otp_number_validate = (value) => {
    let error;
    if (!value) {
        error = "This field is required"
    }
    else if (value.match(/[a-zA-Z]/) || value.match(/\W|_/g)) {
        error = "OTP can have only number"
    }
    else if (value.toString().length < 6 || value.toString().length > 6) {
        error = "Please enter a valid 6 digit OTP sent to your phone."
    }
    return error
}

export const upload_dropdown_validate = (value) => {
    let error = null;
    if (!value || value === '') {
        error = "This field is required"
    }
    return error
}

export const validate_password_field = (value) => {
    let error = null;
    if (!value || value === '') {
        error = "This field is required"
    } else if (value.length < 6) {
        error = "6 char long"
    }
    return error
}

/* Field Level Validation Ends Here */

export const validateChangePassword =
    Yup.object().shape({
        old_password: Yup.string().min(6, "6 char long").required("This field is required").nullable(),
        new_password: Yup.string().min(6, "6 char long").required('This field is required').nullable(),
        confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], "Password Does Not Match").required('This field is required').nullable(),
    })

export const validateBillingInformation =
    Yup.object({
        name_on_card: Yup.string().required("This field is required").nullable(),
        card_number: Yup.string()
            .test(
                'card_number',
                'Please Enter Valid 16 Digit Card Number',
                val => {
                    if (val) {
                        val = val.replace(/ /g, '');
                    }
                    return val && val.length === 16 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g)
                }).required("This field is required").nullable(),
        expiry_date: Yup.string().required("This field is required").nullable(),
        // cvv: Yup.string().required("This field is required").nullable(),
        // address: Yup.string().required("This field is required").nullable(),
        // city: Yup.string().required("This field is required").nullable(),
        // state: Yup.string().required("This field is required").nullable(),
        zip_code: Yup.string()
            .test(
                'zip_code',
                'Please Enter Valid 6 Digit Zip Code',
                val => {
                    if (val) {
                        return val.length === 6 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g)
                    }
                    else {
                        return true
                    }
                }).nullable()
    })

export const validateBillingAccountInformation =
    Yup.object({
        account_holder_name: Yup.string().required("This field is required").nullable(),
        bank_name: Yup.string().required("This field is required").nullable(),
        account_number: Yup.string()
            .test(
                'account_number',
                'Please Enter Valid Account Number',
                val => val && !val.match(/[a-zA-Z]/)
            ).required("This field is required").nullable(),
        route_number: Yup.string()
            .test(
                'route_number',
                'Please Enter Valid Route Number',
                val => val && !val.match(/[a-zA-Z]/)
            ).required("This field is required").nullable(),
        // address: Yup.string().required("This field is required").nullable(),
        // city: Yup.string().required("This field is required").nullable(),
        // state: Yup.string().required("This field is required").nullable()
        zip_code: Yup.string()
            .test(
                'zip_code',
                'Please Enter Valid 6 Digit Zip Code',
                val => {
                    if (val) {
                        return val.length === 6 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g)
                    }
                    else {
                        return true
                    }
                }).nullable()
    })

export const validateBusinessInformation =
    Yup.object({
        businessname: Yup.string().required("This field is required").nullable(),
        // address: Yup.string().required("This field is required").nullable(),
        phonenumber: Yup.string().matches(PhNoPattern, "Invalid Mobile Number").nullable(),
        // email: Yup.string().email('Please Enter a Valid Email').required("This field is required").nullable(),
        // city: Yup.string().required("This field is required").nullable(),
        // state: Yup.string().required("This field is required").nullable(),
        postalcode: Yup.string()
            .test(
                'zip_code',
                'Please Enter Valid 6 Digit Zip Code',
                val => {
                    if (val) {
                        return val.length === 6 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g)
                    }
                    else {
                        return true
                    }
                }).nullable()
    })

export const validatePersonalInformation =
    Yup.object({
        // upload_photo: Yup.string().required("This field is required"),
        first_name: Yup.string().required("This field is required").nullable(),
        last_name: Yup.string().required("This field is required").nullable(),
        // phone_number: Yup.string().matches(PhNoPattern, "Invalid Mobile Number").required("This field is required").nullable(),
        email: Yup.string().email('Please Enter a Valid Email').required("This field is required").nullable(),
        ssn: Yup.string()
            .test(
                'ssn',
                'Please Enter Valid 9 Digit SSN',
                val => {
                    if (val) {
                        val = val.replace(/-/g, '');
                    }
                    console.log(val)
                    return val ? val.length === 9 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g) : true
                }).nullable(),
        spouse_ssn: Yup.string()
            .test(
                'spouse_ssn',
                'Please Enter Valid 9 Digit SSN',
                val => {
                    // window.alert(val)
                    if (val) {
                        val = val.replace(/-/g, '');
                    }
                    console.log(val)
                    return val ? val.length === 9 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g) : true
                }).nullable(),
        // date_of_birth: Yup.string().required("This field is required").nullable()
        primary_address_postalcode: Yup.string()
            .test(
                'primary_address_postalcode',
                'Please Enter Valid 6 Digit Zip Code',
                val => {
                    if (val) {
                        return val.length === 6 && !val.match(/[a-zA-Z]/) && !val.match(/\W|_/g)
                    }
                    else {
                        return true
                    }
                }).nullable()
    })

export const validateForgotPasswordForm =
    Yup.object({
        email: Yup.string().email('Please Enter a Valid Email').required("This field is required").nullable()
    })