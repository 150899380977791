import store from '../../redux/store/store';
import { NS_TOKEN } from '../../config';

const authInterceptor = (config) => {
    const state = store.getState();
    if (state.authReducer.isLoggedIn) {
        config.headers.common['ns-token'] = NS_TOKEN;
    }
    return config;
}

export default authInterceptor;