import React, { useState } from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import $ from 'jquery'
import * as actionCreators from '../../redux/actions/index'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, Field, FieldArray } from 'formik';
import Oux from '../../hoc/Oux/Oux'
import './Documents.css'
import Spinner from '../../components/UI/Spinner/Spinner';
import 'moment-timezone';
import { InputField, SelectMenu } from '../../components/UI/FormInputFields/FormInputFields';
import { toastMsg } from '../../utility/utility';
import { PDFObject } from 'react-pdfobject'

class Documents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            file: null,
            category: null,
            allDocuments: [],
            uploadDocumentComment: null,
            selectedDocument: null,
            showUploadModal: false
        }
        this.fileInputRef = React.createRef();
    }

    componentDidMount() {
        this.props.fetchAllDocuments({ caseid: this.props.user.caseid, type: 'Listing' })
        this.props.fetchDocumentCategories({ type: 'DocumentTypes' })
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.allDocuments && this.state.allDocuments.length === 0 && this.props.allDocuments && this.props.allDocuments.length > 0) ||
            (prevProps.allDocuments
                && this.props.allDocuments
                && prevProps.allDocuments.length !== this.props.allDocuments.length)) {
            this.setState({
                ...this.state,
                allDocuments: [...this.props.allDocuments]
            })
        }
    }

    openFileDialog = () => {
        this.fileInputRef.current.click();
    }

    onFilesAdded = (e) => {
        console.log(e.target.text)
        let file = e.target.files[0];
        let type = file.type
        if (["image/png", "image/jpeg", "image/jpg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(type)) {
            this.setState({
                ...this.state,
                file: file
            }, () => { this.uploadActionHandler("upload") });
        } else {
            e.target.value = null
            toastMsg("Only images and pdfs are allowed!", true);
        }
        e.target.value = null
    }

    uploadActionHandler = (action) => {
        console.log("action clicked:" + action);

        //click on upload - select a file
        if (action === "upload" && !this.state.file) {
            this.setState({
                showUploadOption: true,
            });
            return;
        }

        // let service = this.props.selectedService;
        // let type = ServiceAction[action].type;
        let request = {}

        if (action === "upload") {
            // this is called on file selection
            request = {
                // type: type,
                // serviceid: service.serviceid,
                file: this.state.file
            }
        } else {
            this.setState({
                showUploadOption: false,
            });
            // request = {
            //     type: 'upload',
            //     // serviceid: service.serviceid
            // }
        }

        this.props.uploadDocument({ ...request, type: 'Upload', caseid: this.props.user.caseid, category: this.state.category, comment: this.state.uploadDocumentComment })
        this.setState({ uploadDocumentComment: null, category: null, showUploadModal: false, file: null })
        // $('#upload-cancel-button').click()

    }

    serachDocuments = (event) => {
        let searchedDocs = []
        console.log(event.target.value)
        this.props.allDocuments.map((document, index) => {
            if (document.title.toLowerCase().includes(event.target.value.toLowerCase()) || document.category.toLowerCase().includes(event.target.value.toLowerCase())) {
                searchedDocs.push(document)
            }
        })
        if (searchedDocs.length > 0) {
            this.setState(prevState => ({ allDocuments: [...searchedDocs] }))
        }
        else {
            this.setState({ allDocuments: null })
        }
        console.log(searchedDocs, 'searchedDocs')
    }

    closeModal = (resetForm) => {
        this.setState({ uploadDocumentComment: null, category: null, showUploadModal: false })
        resetForm({})
        // $('#upload-cancel-button').click()
    }

    render() {

        console.log('docs', this.props.allDocuments)
        console.log('stateVals', this.state.category, this.state.uploadDocumentComment)
        return (
            <main className="main_section">
                <div className="main_cont">
                    <div className="container-fluid fp_pl_0 fp_sub_sidenav_outer">
                        <SideFilterMenu fetchAllDocuments={this.props.fetchAllDocuments} caseid={this.props.user.caseid} fetchFilteredDocuments={this.props.fetchFilteredDocuments} documentCategories={this.props.documentCategories} />
                        <div className="fp_right_content">
                            {/* <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane fade" id="v-pills-tax-report" role="tabpanel" aria-labelledby="v-pills-tax-report-tab">
                                    Tax Report
                                </div>
                                <div className="tab-pane fade show active" id="v-pills-tax-investigation" role="tabpanel" aria-labelledby="v-pills-tax-investigation-tab">
                                    <div className="fb_tabs_inner"> */}
                            <div className="doc_tab_content">
                                <div className="doc_head">
                                    <div className="doc_title">
                                        <h3 className="fp_sec_title">Documents</h3>
                                    </div>
                                    <div className="search_filter">
                                        <div className="input-group cst_input_group">
                                            <input
                                                type="text"
                                                onChange={(event) => this.serachDocuments(event)}
                                                className="form-control input_modifier"
                                                placeholder="Search" />
                                            <div className="input-group-append">
                                                <span className="input-group-text"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                        <button
                                            data-toggle="modal" data-target="#myModal"
                                            onClick={() => this.setState({ showUploadModal: true })}
                                            disabled={this.props.isDocumentUploading ? true : false}
                                            className="btn btn-primary cst_btn">
                                            <img src="/images/icons/icn_down_white.png" className="mr-2 upload_icn" />
                                            {this.props.isDocumentUploading ? 'Uploading...' : 'Upload'}
                                        </button>
                                        <input
                                            ref={this.fileInputRef}
                                            className="FileInput"
                                            type="file"
                                            onChange={this.onFilesAdded}
                                        />
                                        {this.state.showUploadModal
                                            ? <Oux>
                                                <div class="modal-backdrop show" />
                                                <div class="modal show" id="myModal" aria-modal="true" style={{ display: 'block' }}>
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <Formik
                                                                enableReinitialize={true}
                                                                initialValues={{ category: null, comment: null }}
                                                                onSubmit={(values) => {
                                                                    console.log(values)
                                                                    this.setState({ category: values.category, uploadDocumentComment: values.comment ? values.comment : null })
                                                                    this.openFileDialog()
                                                                }}>
                                                                {(formik_props) => {
                                                                    console.log(formik_props)
                                                                    return (
                                                                        <Oux>
                                                                            <div class="modal-header">
                                                                                <h5 class="modal-title">Upload Document</h5>
                                                                                <button class="close close-upload-document-modal" id="upload-cancel-button" onClick={() => this.closeModal(formik_props.resetForm)}>&times;</button>
                                                                            </div>
                                                                            <Form>
                                                                                <div class="modal-body">
                                                                                    <div className="form-group">
                                                                                        <InputField form_class_name="upload-document-form" name="comment" label="Please Provide More Context You Are Trying To Send" labelClassName="comment-label" type="text" />
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <SelectMenu onChange={formik_props.handleChange} form_class_name={formik_props.errors.category && formik_props.touched.category ? "upload-document-form select-field error" : "upload-document-form select-field"} name="category" label="Category" menuItems={this.props.documentCategories ? this.props.documentCategories.DocumentType : null} />
                                                                                        <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{formik_props.touched.category && formik_props.errors.category ? formik_props.errors.category : null}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="modal-footer">
                                                                                    <button type="submit" class="btn btn-primary">Upload</button>
                                                                                    <button type='button' onClick={() => this.closeModal(formik_props.resetForm)} class="btn btn-danger">Cancel</button>
                                                                                </div>
                                                                            </Form>
                                                                        </Oux>
                                                                    )
                                                                }}
                                                            </Formik>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Oux>
                                            : null}
                                    </div>
                                </div>
                                {this.props.isDocumentLoading && (!this.state.allDocuments || this.state.allDocuments.length === 0) ? <Spinner spinnerStyle={{ width: '50px', height: '50px', top: '50%', left: '60%', position: 'absolute' }} />
                                    : (!this.state.allDocuments || this.state.allDocuments.length === 0) && <div class="empty_details">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="empty_img">
                                                    <img src="/images/thumbnails/empty_folder.png" alt="Icon" />
                                                </div>
                                                {
                                                    (!this.props.allDocuments || this.props.allDocuments.length === 0)
                                                        ? <Oux><h3 class="h3_title">No Documents added.</h3>
                                                            <p class="cst_para">Start uploading the documents.</p></Oux>
                                                        : <h3 class="h3_title">No Results found.</h3>
                                                }
                                            </div>
                                        </div>
                                    </div>}

                                <div className="doc_card_block">
                                    {/* <h6 className="h6_title">Oct 1,2019</h6> */}
                                    <div className="row row_equal_ht">
                                        {
                                            this.state.allDocuments
                                                ? this.state.allDocuments.map((document, index) => {
                                                    let documentThumbnail = null
                                                    if (document.extension.toLowerCase() === 'pdf') {
                                                        documentThumbnail = '/images/customs/icn_pdf_placeholder.svg'
                                                    }
                                                    else if (document.extension.toLowerCase() === 'png') {
                                                        documentThumbnail = '/images/customs/icn_png_placeholder.svg'
                                                    }
                                                    else if (document.extension.toLowerCase() === 'jpg' || document.extension.toLowerCase() === 'jpeg') {
                                                        documentThumbnail = '/images/customs/icn_jpg_placeholder.svg'
                                                    }
                                                    else if (document.extension.toLowerCase() === 'doc') {
                                                        documentThumbnail = '/images/customs/icn_doc_placeholder.svg'
                                                    }
                                                    else if (document.extension.toLowerCase() === 'docx') {
                                                        documentThumbnail = '/images/customs/icn_docx_placeholder.svg'
                                                    }
                                                    return (
                                                        <div key={index} className="col-md-6 col-lg-6 col-xl-4" style={{ display: 'flex' }}>
                                                            {this.props.isDocumentUploading ? <Spinner spinnerStyle={{ width: '50px', height: '50px', top: '50%', left: '60%', position: 'absolute' }} /> : null}
                                                            <div className="card doc_card heightDiv" style={{ width: '100%' }}>
                                                                <div className="card_img">
                                                                    <img
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            this.props.downloadDocument({ type: 'Download', caseid: this.props.user.caseid, versionid: document.versionid })
                                                                            this.setState({ selectedDocument: document })
                                                                        }}
                                                                        data-toggle="modal"
                                                                        data-target="#fsModal"
                                                                        className="card-img-top"
                                                                        src={documentThumbnail}
                                                                        alt="Document" />
                                                                </div>
                                                                <div className="card-body">
                                                                    <h5 className="card-title">{document.title}</h5>
                                                                    <h6 className="h6_title">{document.category}</h6>
                                                                    <p className="card-text">Uploaded on {document.createddate} by CTax</p>
                                                                    <a
                                                                        data-toggle="modal"
                                                                        data-target="#fsModal"
                                                                        onClick={() => {
                                                                            this.props.downloadDocument({ type: 'Download', caseid: this.props.user.caseid, versionid: document.versionid })
                                                                            this.setState({ selectedDocument: document })
                                                                        }}
                                                                        className="card-text">Preview Document</a>
                                                                </div>
                                                            </div>
                                                            <ViewDocument document={this.state.selectedDocument} perviewDocument={this.props.perviewDocument} />
                                                        </div>
                                                    )
                                                })
                                                : null
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="tab-pane fade" id="v-pills-tax-preparation" role="tabpanel" aria-labelledby="v-pills-tax-preparation-tab">Tax Preparation</div>
                                <div className="tab-pane fade" id="v-pills-tax-resolution" role="tabpanel" aria-labelledby="v-pills-tax-resolution-tab">Tax Resolution</div>
                                <div className="tab-pane fade" id="v-pills-credit-guard" role="tabpanel" aria-labelledby="v-pills-credit-guard-tab">Credit Guard</div>
                                <div className="tab-pane fade" id="v-pills-business-services" role="tabpanel" aria-labelledby="v-pills-business-services-tab">Business Services</div> */}
                        {/* </div> */}
                    </div>
                    {/* </div> */}
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    allDocuments: state.documentReducer.allDocuments,
    documentCategories: state.documentReducer.documentCategories,
    perviewDocument: state.documentReducer.perviewDocument,
    isDocumentLoading: state.documentReducer.isLoading,
    isDocumentUploading: state.documentReducer.isDocumentUploading
});

const mapStateToDispatch = (dispatch) => ({
    fetchAllDocuments: (credentials) => dispatch(actionCreators.fetchAllDocuments(credentials)),
    fetchFilteredDocuments: (credentials) => dispatch(actionCreators.fetchFilteredDocuments(credentials)),
    fetchDocumentCategories: (credentials) => dispatch(actionCreators.fetchDocumentCategories(credentials)),
    downloadDocument: (credentials) => dispatch(actionCreators.downloadDocument(credentials)),
    uploadDocument: (credentials) => dispatch(actionCreators.uploadDocument(credentials))
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Documents));

const ViewDocument = (props) => {

    let customPdfViewer = "/PDFViewer/web/viewer.html?file="
    let customDocViewer = "https://docs.google.com/viewer?embedded=true&url="

    if (props.perviewDocument) {
        console.log(props.perviewDocument.link)
    }

    // if(props.perviewDocument && props.perviewDocument.link.includes(".pdf"))
    // {
    //     window.loadCustomPdfViewer(props.perviewDocument.link)
    // }

    return (
        // <!-- modal -->
        <div
            id="fsModal"
            class="modal animated bounceIn"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
            style={{ overflow: 'hidden' }}>

            {/* <!-- dialog --> */}
            <div class="modal-dialog" style={{ maxWidth: '90%', margin: '0px auto', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>

                {/* <!-- content --> */}
                <div class="modal-content" style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'transparent', border: '0px', borderRadius: '0px' }}>

                    {/* <!-- header --> */}
                    <div style={{ padding: '0px', margin: '0px', borderBottom: '0px' }} class="modal-header">
                        <h1 id="myModalLabel"
                            class="modal-title"
                            style={{ color: 'white', marginBottom: '0px' }}>
                            {/* {props.document ? props.document.title : null} */}
                        </h1>
                        <h1 style={{ marginBottom: '0px' }} id="myModalLabel"
                            class="modal-title">
                            <button class="btn btn-secondary modal-close-button"
                                data-dismiss="modal" style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}>
                                <i class="fa fa-times-circle fa-2x"></i>
                                {/* <Icon>close</Icon> */}
                            </button>
                        </h1>
                    </div>
                    {/* <!-- header --> */}

                    {/* <!-- body --> */}
                    <div class="modal-body" style={{ padding: '0px', margin: '0px', paddingTop: '10px', height: '90vh' }}>
                        {
                            props.perviewDocument
                                ? props.perviewDocument.link.includes(".pdf")
                                    ? <iframe frameBorder="0" src={customDocViewer + props.perviewDocument.link} width="100%" height="100%" />
                                    // ? <object width="100%" height="550px" data={customPdfViewer + props.perviewDocument.link} type="application/pdf"></object><iframe frameBorder="0" src={customPdfViewer + props.perviewDocument.link} width="100%" height="550px" />
                                    : props.perviewDocument.link.includes(".doc") || props.perviewDocument.link.includes(".docx")
                                        ? <iframe frameBorder="0" src={customDocViewer + props.perviewDocument.link} width="100%" height="100%" />
                                        : <img src={props.perviewDocument.link} style={{ objectFit: "contain" }} width="100%" height="100%" />
                                : <Spinner />
                        }
                    </div>
                    {/* <!-- body --> */}

                    {/* <!-- footer --> */}
                    {/* <div class="modal-footer">
                        <button class="btn btn-secondary"
                            data-dismiss="modal">
                            close
                        </button>
                    </div> */}
                    {/* <!-- footer --> */}

                </div>
                {/* <!-- content --> */}

            </div>
            {/* <!-- dialog --> */}

        </div>
        // <!-- modal -->
    )
}

const SideFilterMenu = (props) => {

    const [error, setError] = useState(null)

    console.log(props.documentCategories)

    const applyFilter = (values, { resetForm }) => {
        document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
        console.log(values, "filterValuesNew")
        let filteredDates = getFilterDates()
        //values.categories.length === 0 && !values -> if condition
        debugger
        if (!values || Object.keys(values).length === 0) {
            // error = "Please Select Something To Filter"
            props.fetchAllDocuments({ caseid: props.caseid, type: 'Listing' })
        }
        else {
            if (!values.startdate && values.enddate && values.enddate < filteredDates.todayDate) {
                setError("End date is lower than start date")
            }
            else if (values.startdate && !values.enddate) {
                setError("Please select end date")
            }
            //values.categories.length !== 0 ||  -> else if condition
            else if (values) {
                setError(null)
                props.fetchFilteredDocuments({
                    category: values.category ? values.category : null,
                    type: 'Filter',
                    startdate:
                        values.startdate
                            ? values.startdate + " 12:00 am"
                            : values.past_week
                                ? filteredDates.pastWeekDate + " 12:00 am"
                                : values.past_month
                                    ? filteredDates.pastMonthDate + " 12:00 am"
                                    : values.past_3_months
                                        ? filteredDates.past3MonthsDate + " 12:00 am"
                                        : values.today
                                            ? filteredDates.todayDate + " 12:00 am"
                                            : null,
                    enddate:
                        values.enddate
                            ? values.enddate + " 11:59 pm"
                            : values.past_week
                                ? filteredDates.todayDate + " 11:59 pm"
                                : values.past_month
                                    ? filteredDates.todayDate + " 11:59 pm"
                                    : values.past_3_months
                                        ? filteredDates.todayDate + " 11:59 pm"
                                        : values.today
                                            ? filteredDates.todayDate + " 11:59 pm"
                                            : null,
                    caseid: props.caseid
                })
            }
        }
    }

    const getFilterDates = () => {
        let date = new Date()
        let todayDate = dateFormat(date)

        date = new Date()
        date.setDate(date.getDate() - 7)
        let pastWeekDate = dateFormat(date)

        date = new Date()
        date.setDate((date.getDate() - 30))
        let pastMonthDate = dateFormat(date)

        date = new Date()
        date.setDate(date.getDate() - 90)
        let past3MonthsDate = dateFormat(date)
        console.log(todayDate, pastWeekDate, pastMonthDate, past3MonthsDate)
        return {
            todayDate, pastWeekDate, pastMonthDate, past3MonthsDate
        }
    }

    const dateFormat = (date) => {
        let year = date.getFullYear()
        let month = (1 + date.getMonth()).toString()
        month = month.length > 1 ? month : '0' + month
        let day = date.getDate().toString()
        day = day.length > 1 ? day : '0' + day
        console.log(month + '/' + day + '/' + year)
        return month + '/' + day + '/' + year
    }

    const checkBoxHandler = (event, setFieldValue) => {
        setFieldValue("startdate", undefined)
        setFieldValue("enddate", undefined)
        if (event.target.id === "today") {
            document.getElementById("past_3_months").checked = false
            document.getElementById("past_week").checked = false
            document.getElementById("past_month").checked = false
            setFieldValue('past_month', undefined)
            setFieldValue('past_week', undefined)
            setFieldValue('past_3_months', undefined)
            setFieldValue('today', event.target.checked)
        }
        else if (event.target.id === "past_week") {
            document.getElementById("today").checked = false
            document.getElementById("past_3_months").checked = false
            document.getElementById("past_month").checked = false
            setFieldValue('past_month', undefined)
            setFieldValue('today', undefined)
            setFieldValue('past_3_months', undefined)
            setFieldValue('past_week', event.target.checked)
        }
        else if (event.target.id === "past_month") {
            document.getElementById("today").checked = false
            document.getElementById("past_week").checked = false
            document.getElementById("past_3_months").checked = false
            setFieldValue('today', undefined)
            setFieldValue('past_week', undefined)
            setFieldValue('past_3_months', undefined)
            setFieldValue('past_month', event.target.checked)
        }
        else if (event.target.id === "past_3_months") {
            console.log(document.getElementById("today"))
            document.getElementById("today").checked = false
            document.getElementById("past_week").checked = false
            document.getElementById("past_month").checked = false
            setFieldValue('past_month', undefined)
            setFieldValue('past_week', undefined)
            setFieldValue('today', undefined)
            setFieldValue('past_3_months', event.target.checked)
        }
    }

    let filteredDates = getFilterDates()

    console.log(filteredDates, 'Filtered Dates')

    return (
        <div className="fp_sub_sidenav fp_sub_sidenav_mob document_nav">
            <h4 className="fb_sm_title">SERVICES</h4>
            <div className="nav flex-column nav-pills fp_sub_sidenav_vTabs doc_services" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                {/* <a className="nav-link" href="#v-pills-tax-report" role="tab">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="allServices" />
                        <label className="custom-control-label" htmlFor="allServices">All Services</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="taxReport" />
                        <label className="custom-control-label" htmlFor="taxReport">Tax Report</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="taxPreparation" />
                        <label className="custom-control-label" htmlFor="taxPreparation">Tax Preparation</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="taxInvestigation" />
                        <label className="custom-control-label" htmlFor="taxInvestigation">Tax Investigation</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="taxResolution" />
                        <label className="custom-control-label" htmlFor="taxResolution">Tax Resolution</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="taxMonitoring" />
                        <label className="custom-control-label" htmlFor="taxMonitoring">Tax Monitoring</label>
                    </div>
                </a> 
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="creditGuard" />
                        <label className="custom-control-label" htmlFor="creditGuard">Credit Guard</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="businessServices" />
                        <label className="custom-control-label" htmlFor="businessServices">Business Services</label>
                    </div>
                </a> */}
                {/* <div className="calc_block">
                    <form className="calendar">
                        <div className="form-group cst_form_group mb-0">
                            <a href="javascript:void(0)">Apply</a>
                        </div>
                        <div className="form-group cst_form_group mb-0">
                        <a href="javascript:void(0)">Clear</a>
                        </div>
                    </form>
                </div> */}
                <Formik
                    initialValues={{}}
                    onSubmit={(values, { resetForm }) => applyFilter(values, { resetForm })}>
                    {(formik_props) => {
                        console.log(formik_props.values)
                        return (
                            <Form>
                                <h4 className="mr-3 fb_sm_title">
                                    <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{error}</span>
                                </h4>
                                {
                                    props.documentCategories
                                        ? props.documentCategories.DocumentType.map((category, index) => {
                                            return (
                                                <a className="nav-link" href="javascript:void(0)" aria-selected="false">
                                                    {/* <div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id={category} />
                                                    <label className="custom-control-label" htmlFor={category}>{category}</label>
                                                </div> */}
                                                    <div className="custom-control custom-checkbox">
                                                        <Field
                                                            type="checkbox"
                                                            id={category}
                                                            name="category"
                                                            onChange={(event) => {
                                                                for (let i = 0; i < props.documentCategories.DocumentType.length; i++) {
                                                                    // if (event.target.checked && event.target.id === props.documentCategories.DocumentType[i]) {
                                                                    //     arrayHelpers.push(category)
                                                                    // }
                                                                    // else {
                                                                    //     let selectedCategory = formik_props.values.categories.includes(props.documentCategories.DocumentType[i])
                                                                    //     if (selectedCategory && event.target.checked) {
                                                                    //         const idx = formik_props.values.categories.indexOf(props.documentCategories.DocumentType[i]);
                                                                    //         arrayHelpers.remove(idx);
                                                                    //     }
                                                                    //     if (!event.target.checked) {
                                                                    //         const idx = formik_props.values.categories.indexOf(props.documentCategories.DocumentType[i]);
                                                                    //         arrayHelpers.remove(idx);
                                                                    //     }
                                                                    // }
                                                                    if (event.target.checked) {
                                                                        formik_props.setFieldValue('category', category)
                                                                    }
                                                                    else {
                                                                        formik_props.setFieldValue('category', null)
                                                                    }
                                                                }
                                                            }}
                                                            className="custom-control-input"
                                                            // disabled={formik_props.values.checkbox2 ? true : false}
                                                            value={category}
                                                            // checked={formik_props.values.categories.includes(category)}
                                                            checked={formik_props.values.category === category ? true : false} />
                                                        <label className="custom-control-label" htmlFor={category}>{category}</label>
                                                    </div>
                                                </a>
                                            )
                                        })
                                        : null
                                }

                                <br />
                                <h4 style={{ marginBottom: '30px' }} className="mr-3 fb_sm_title">
                                    <label className="label_modifier">FROM</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            name="startdate"
                                            inputVariant="standard"
                                            // label="Date Of Birth"
                                            id="custom_datepicker"
                                            className="form-control date-filter"
                                            format="MM/dd/yyyy"
                                            disabled={formik_props.values.today || formik_props.values.past_week || formik_props.values.past_month || formik_props.values.past_3_months}
                                            onChange={(event, date) => {
                                                formik_props.setFieldValue("startdate", date)
                                            }}
                                            maxDate={new Date()}
                                            value={formik_props.values.startdate ? formik_props.values.startdate : null}
                                            InputAdornmentProps={{ position: "start" }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            name="startdate"
                                            inputVariant="standard"
                                            // label="Date Of Birth"
                                            id="custom_datepicker"
                                            className="form-control date-filter"
                                            format="MM/dd/yyyy"
                                            onChange={(event, date) => {
                                                formik_props.setFieldValue("startdate", date)
                                            }}
                                            disabled={formik_props.values.today || formik_props.values.past_week || formik_props.values.past_month || formik_props.values.past_3_months}
                                            value={formik_props.values.startdate}
                                            maxDate={new Date()}
                                            InputAdornmentProps={{ position: "start" }}
                                        />
                                    </MuiPickersUtilsProvider> */}
                                </h4>
                                <h4 style={{ marginBottom: '30px' }} className="mr-3 fb_sm_title">
                                    <label className="label_modifier">TO</label>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            name="enddate"
                                            inputVariant="standard"
                                            // label="Date Of Birth"
                                            id="custom_datepicker"
                                            className="form-control date-filter"
                                            format="MM/dd/yyyy"
                                            disabled={formik_props.values.today || formik_props.values.past_week || formik_props.values.past_month || formik_props.values.past_3_months}
                                            onChange={(event, date) => {
                                                formik_props.setFieldValue("enddate", date)
                                            }}
                                            minDate={formik_props.values.startdate}
                                            maxDate={new Date()}
                                            value={formik_props.values.enddate ? formik_props.values.enddate : null}
                                        // InputAdornmentProps={{ position: "start" }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </h4>
                                <a className="nav-link" href="javascript:void(0)" aria-selected="false">
                                    <div className="custom-control custom-checkbox">
                                        <Field
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={formik_props.values.today}
                                            // value={new Date().toLocaleDateString()}
                                            onChange={(event) => {
                                                checkBoxHandler(event, formik_props.setFieldValue)
                                            }}
                                            id="today" />
                                        <label className="custom-control-label" htmlFor="today">Today</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <Field
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={formik_props.values.past_week}
                                            onChange={(event) => {
                                                checkBoxHandler(event, formik_props.setFieldValue)
                                            }}
                                            id="past_week" />
                                        <label className="custom-control-label" htmlFor="past_week">Past Week</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <Field
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={formik_props.values.past_month}
                                            onChange={(event) => {
                                                checkBoxHandler(event, formik_props.setFieldValue)
                                            }}
                                            id="past_month" />
                                        <label className="custom-control-label" htmlFor="past_month">Past Month</label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <Field
                                            type="checkbox"
                                            className="custom-control-input"
                                            checked={formik_props.values.past_3_months}
                                            onChange={(event) => {
                                                checkBoxHandler(event, formik_props.setFieldValue)
                                            }}
                                            id="past_3_months" />
                                        <label className="custom-control-label" htmlFor="past_3_months">Past 3 Months</label>
                                    </div>
                                </a>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <h4 className="fb_sm_title">
                                        <button className="btn btn-success btn-sm" type="submit">Apply</button>
                                    </h4>
                                    <h4 style={{ float: 'right' }} className="mr-3 fb_sm_title">
                                        <button className="btn btn-danger btn-sm" type="button" onClick={() => {
                                            props.fetchAllDocuments({ caseid: props.caseid, type: 'Listing' })
                                            document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                                            formik_props.resetForm({})
                                        }}>Clear</button>
                                    </h4>
                                </div>
                                {/* <div className="calc_block" >
                                    <div className="calendar">
                                        <div className="form-group cst_form_group mb-0">
                                            <label className="label_modifier">FROM</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="01-01-2019" />

                                        </div>
                                        <div className="form-group cst_form_group mb-0">
                                            <label className="label_modifier">TO</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="10-01-2019" />

                                        </div>
                                    </div>
                                </div> */}
                            </Form>
                        )
                    }}
                </Formik>

                {/* <h4 className="fb_sm_title mt-3">SENT BY</h4>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="communityTax" />
                        <label className="custom-control-label" htmlFor="communityTax">Community Tax</label>
                    </div>
                </a>
                <a className="nav-link" href="#v-pills-tax-investigation" aria-selected="false">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="Me" />
                        <label className="custom-control-label" htmlFor="Me">Me</label>
                    </div>
                </a> */}

            </div>
        </div >
    )
}