import { DocumentsActionType } from '../../actions/usersActions/actionType'

const initialState = {
    isLoading: false,
    error: null,
    allDocuments: null,
    isDocumentUploading: null,
    filteredDocuments: null,
    documentCategories: null,
    perviewDocument: null
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const documentReducer = (state = initialState, action) => {
    switch (action.type) {
        case DocumentsActionType.FETCH_ALLDOCUMENT_PENDING:
            return updateObject(state,
                {
                    // error: action.payload.error,
                    isLoading: true
                });
        case DocumentsActionType.FETCH_ALLDOCUMENT_FULFILLED:
            if (action.payload && action.payload.error) {
                return updateObject(state,
                    {
                        isLoading: false,
                        error: action.payload.error
                    });
            }
            else {
                return updateObject(state,
                    {
                        isLoading: false,
                        allDocuments: action.payload ? action.payload.data : state.allDocuments,
                    });
            }

        case DocumentsActionType.FETCH_ALLDOCUMENT_FILTERED_PENDING:
            return updateObject(state,
                {
                    // error: action.payload.error,
                    isLoading: true
                });
        case DocumentsActionType.FETCH_ALLDOCUMENT_FILTERED_FULFILLED:
            if (action.payload && action.payload.error) {
                return updateObject(state,
                    {
                        isLoading: false,
                        error: action.payload.error
                    });
            }
            else {
                return updateObject(state,
                    {
                        isLoading: false,
                        allDocuments: action.payload ? action.payload.data : state.allDocuments,
                    });
            }

        case DocumentsActionType.FETCH_ALLDOCUMENT_CATEGORY_PENDING:
            return updateObject(state,
                {
                    // error: action.payload.error,
                    isLoading: true
                });
        case DocumentsActionType.FETCH_ALLDOCUMENT_CATEGORY_FULFILLED:
            if (action.payload && action.payload.error) {
                return updateObject(state,
                    {
                        isLoading: false,
                        error: action.payload.error
                    });
            }
            else {
                return updateObject(state,
                    {
                        isLoading: false,
                        documentCategories: action.payload ? action.payload.data : state.documentCategories,
                    });
            }

        case DocumentsActionType.DOWNLOAD_DOCUMENT_PENDING:
            return updateObject(state,
                {
                    // error: action.payload.error,
                    isLoading: true,
                    perviewDocument: null
                });
        case DocumentsActionType.DOWNLOAD_DOCUMENT_FULFILLED:
            if (action.payload && action.payload.error) {
                return updateObject(state,
                    {
                        isLoading: false,
                        error: action.payload.error
                    });
            }
            else {
                return updateObject(state,
                    {
                        isLoading: false,
                        perviewDocument: action.payload ? action.payload : state.perviewDocument,
                    });
            }

        case DocumentsActionType.UPLOAD_DOCUMENT_PENDING:
            return updateObject(state,
                {
                    // error: action.payload.error,
                    isDocumentUploading: true,
                    perviewDocument: null
                });
        case DocumentsActionType.UPLOAD_DOCUMENT_FULFILLED:
            if (action.payload && action.payload.error) {
                return updateObject(state,
                    {
                        isDocumentUploading: false,
                        error: action.payload.error
                    });
            }
            else {
                return updateObject(state,
                    {
                        isDocumentUploading: false,
                        // perviewDocument: action.payload ? action.payload : state.perviewDocument,
                    });
            }
        default: return state;
    }
}