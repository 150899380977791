import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { authReducer } from './users/authReducer';
import { historyReducer } from './users/historyReducer'
import { serviceReducer } from './users/serviceReducer'
import { myAccountReducer } from './myAccount/myAccountReducer'
import {documentReducer} from './users/documentReducer'

const reducers = combineReducers({
        authReducer,
        historyReducer,
        serviceReducer,
        myAccountReducer,
        documentReducer,
        form: formReducer
});

export default reducers;
