import React from 'react'
import './Spinner.css'

const spinner = props => {
    let additionalClasses = props.class;
    return (
        <div className="sk-circle" style={props.spinnerStyle ? props.spinnerStyle : { width: props.width ? props.width : '50px', height: props.height ? props.height : '50px', margin: props.margin ? props.margin : '0px auto' }}>
            <div className={`sk-circle1 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle2 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle3 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle4 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle5 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle6 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle7 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle8 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle9 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle10 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle11 sk-child ${additionalClasses}`}></div>
            <div className={`sk-circle12 sk-child ${additionalClasses}`}></div>
        </div>
    )
}

export default spinner