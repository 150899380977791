import { AuthActionTypes, SubmitTicketTypes, RESET_INITIAL_STATE } from '../../actions/usersActions/actionType';
import storage from '../../../utility/storage';
import { resetLocalStorage } from '../../actions/index'

const refresh_token = storage.get("refresh_token", null);
const id = storage.get("id", null)
const user_id = storage.get("userid", null)
const case_id = storage.get("case_id", null)
const user = storage.get("user", null);
const idToken = storage.get("idToken", null)
const isFreeTaxRedirected = storage.get("isFreeTaxRedirected", null)
const freeTaxNumberVerifiedStatus = storage.get("freeTaxNumberVerifiedStatus", null)
const freeTaxChangePasswordStatus = storage.get("freeTaxChangePasswordStatus", null)
const freeTax8821ContractStatus = storage.get("freeTax8821ContractStatus", null)

export const initialState = {
    user: user,
    refresh_token: refresh_token,
    id: id,
    case_id: case_id,
    user_id: user_id,
    idToken: idToken,
    isloading: false,
    resetPasswordToken: null,
    isLoggedIn: id && user_id && case_id ? true : false,
    error: null,
    mobile_number: null,
    country_code: null,
    isFreeTaxRedirected: isFreeTaxRedirected,
    freeTaxChangePasswordStatus: freeTaxChangePasswordStatus,
    freeTaxNumberVerifiedStatus: freeTaxNumberVerifiedStatus,
    freeTax8821ContractStatus: freeTax8821ContractStatus,
    isOTPLoading: false
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case AuthActionTypes.LOGIN_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.LOGIN_FULFILLED:
            if (action.payload.error) {
                return updateObject(state, {
                    isloading: false,
                    disabled: false,
                    error: action.payload ? action.payload.error : state.error
                });
            }
            else {
                return updateObject(state, {
                    isloading: action.payload.stopLoader ? false : true,
                    disabled: false,
                    isLoggedIn: action.payload.userid ? true : false,
                    user_id: action.payload.userid,
                    refresh_token: action.payload.refreshToken,
                    id: action.payload.id,
                    idToken: action.payload.idToken,
                    case_id: action.payload.caseid
                });
            }

        case AuthActionTypes.GET_USER_PENDING:
            return updateObject(state, { isloading: true, disabled: true });

        case AuthActionTypes.GET_USER_FULFILLED:
            return updateObject(state, {
                isloading: false,
                disabled: false,
                user: action.payload ? action.payload : state.user,
                error: action.payload ? action.payload.error : state.error
            });

        case AuthActionTypes.CHECK_USER_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.CHECK_USER_FULFILLED:
            if (action.payload.error) {
                return updateObject(state, {
                    isloading: action.payload && action.payload.stopLoader ? false : true,
                    disabled: false,
                    error: action.payload ? action.payload.error : null
                });
            }
            else {
                return updateObject(state, {
                    isloading: action.payload && action.payload.stopLoader ? false : true,
                    disabled: false,
                    case_id: action.payload ? action.payload.caseid : state.case_id,
                });
            }

        case AuthActionTypes.CHECK_EMAIL_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.CHECK_EMAIL_FULFILLED:
            return updateObject(state, {
                isloading: false,
                disabled: false,
                error: action.payload ? action.payload.error : null
            });

        case AuthActionTypes.SEND_OTP_PENDING:
            return updateObject(state, { isloading: true, disabled: true, isOTPLoading: true });
        case AuthActionTypes.SEND_OTP_FULFILLED:
            return updateObject(state, {
                isloading: false,
                disabled: false,
                isOTPLoading: false,
                error: action.payload && action.payload.error ? action.payload.error : null
            });


        case AuthActionTypes.SUBMIT_OTP_PENDING:
            return updateObject(state, { isloading: true, disabled: true, isOTPLoading: true });
        case AuthActionTypes.SUBMIT_OTP_FULFILLED:
            return updateObject(state, {
                isloading: action.payload && action.payload.stopLoader ? false : true,
                disabled: false,
                isOTPLoading: false,
                error: action.payload ? action.payload.error : null
            });

        case SubmitTicketTypes.SUBMIT_TICKET_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case SubmitTicketTypes.SUBMIT_TICKET_FULFILLED:
            return updateObject(state, {
                isloading: false,
                disabled: false,
                error: action.payload ? action.payload.error : null
            });

        case AuthActionTypes.SIGNUP_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.SIGNUP_FULFILLED:
            return updateObject(state, {
                isloading: false, disabled: false,
                id: action.payload ? action.payload.id : state.id,
                user_id: action.payload ? action.payload.userid : state.userid,
                isLoggedIn: action.payload.userid && action.payload.id ? true : false,
                error: action.payload ? action.payload.error : state.error
            });

        case AuthActionTypes.FORGOT_PASSWORD_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.FORGOT_PASSWORD_FULFILLED:
            return updateObject(state, { isloading: false, disabled: false, error: action.payload ? action.payload.error : state.error });

        case AuthActionTypes.RESET_PASSWORD_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.RESET_PASSWORD_FULFILLED:
            return updateObject(state, {
                isloading: false,
                disabled: false,
                error: action.payload && action.payload.error ? action.payload.error : null
            });
        case AuthActionTypes.SET_MOBILE_FORM_DATA:
            return updateObject(state, {
                mobile_number: action.payload && action.payload.mobile_number ? action.payload.mobile_number : null,
                country_code: action.payload && action.payload.country_code ? action.payload.country_code : null
            });

        case AuthActionTypes.LOGOUT_PENDING:
            return updateObject(state, {
                isloading: true,
                disabled: true,
                isOTPLoading: true
            });


        case AuthActionTypes.LOGOUT_FULFILLED:
            resetLocalStorage()
            return updateObject(state, {
                refresh_token: null,
                user: null,
                id: null,
                isloading: false,
                isAdmin: false,
                isLoggedIn: false,
                case_id: null,
                user_id: null,
                resetPasswordToken: null,
                idToken: null,
                disabled: false,
                isFreeTaxRedirected: false,
                isOTPLoading: false
            });

        case RESET_INITIAL_STATE:
            return updateObject(state, {
                error: null
            });
        case AuthActionTypes.UPDATE_USER:
            storage.set('user', action.payload);
            return updateObject(state, {
                user: action.payload
            })

        case AuthActionTypes.RESET_MOBILE_FORM_DATA:
            return updateObject(state, {
                mobile_number: action.payload.mobile_number,
                country_code: action.payload.country_code
            })

        case AuthActionTypes.IS_FREETAX_REDIRECTED:
            return updateObject(state, {
                isFreeTaxRedirected: action.payload.flag,
                freeTaxChangePasswordStatus: action.payload.status.freeTaxChangePasswordStatus,
                freeTax8821ContractStatus: action.payload.status.freeTax8821ContractStatus,
                freeTaxNumberVerifiedStatus: action.payload.status.freeTaxNumberVerifiedStatus
            })

        case AuthActionTypes.REFRESH_TOKEN_PENDING:
            return updateObject(state, { isloading: true, disabled: true });
        case AuthActionTypes.REFRESH_TOKEN_FULFILLED:
            if (action.payload.error) {
                return updateObject(state, {
                    isloading: false,
                    disabled: false,
                    error: action.payload ? action.payload.error : state.error
                });
            }
            else {
                return updateObject(state, {
                    isloading: action.payload.stopLoader ? false : true,
                    disabled: false,
                    idToken: action.payload.idtoken,
                });
            }

        default: return state;
    }
}