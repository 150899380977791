import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actionTypes from '../../redux/actions/index'
import FreeTaxChangePassword from '../../components/FreeTaxPage/FreeTaxChangePassword'
import OTPVerification from '../../components/RegisterForm/PhoneVerification/OTPVerification'
import storage from '../../utility/storage'
import Spinner from '../../components/UI/Spinner/Spinner'
import Oux from '../../hoc/Oux/Oux'
import Form8821Contract from '../../components/FreeTaxPage/Form8821Contract'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneVerification from '../../components/RegisterForm/PhoneVerification/PhoneVerification'
import { routes } from '../../utility/constants/constants'

class FreeTax extends Component {

    state = {
        isNextStepLoading: false,
        isDocSigning: false
    }

    componentDidMount = () => {
        if (this.props.history.location.search) {
            let docSigned = new URLSearchParams(this.props.history.location.search, { ignoreQueryPrefix: true }).get('event')
            if (docSigned && docSigned === "signing_complete") {
                this.setState({ isNextStepLoading: true })
                this.props.form8821Status({ type: '8821Flag', id: this.props.id })
                    .then(response => {
                        this.props.getUser({ type: 'GetUser', id: this.props.id })
                            .then(response => {
                                const freeTaxChangePasswordStatus = storage.get("freeTaxChangePasswordStatus", null)
                                const freeTaxNumberVerifiedStatus = storage.get("freeTaxNumberVerifiedStatus", null)
                                let contract8821Status = response.value['8821contract']
                                if (contract8821Status && contract8821Status.toLowerCase() === "no") {
                                    storage.set('freeTax8821ContractStatus', contract8821Status);
                                    this.props.toggleFreeTaxRedirected(true, {
                                        freeTaxNumberVerifiedStatus: freeTaxNumberVerifiedStatus,
                                        freeTax8821ContractStatus: contract8821Status,
                                        freeTaxChangePasswordStatus: freeTaxChangePasswordStatus
                                    })
                                }
                                this.setState({ isNextStepLoading: false })
                            })
                    })
            }
        }
    }

    toggleStepLoader = (flag) => {
        this.setState({ isNextStepLoading: flag })
    }

    signDocument = () => {
        this.setState({ isDocSigning: true })
        this.props.form8821EnvelopeId({ type: '8821', caseid: this.props.caseid })
    }

    navigateUserToHome = () => {
        storage.remove("isFreeTaxRedirected")
        storage.remove('freeTaxNumberVerifiedStatus')
        storage.remove('freeTaxChangePasswordStatus')
        storage.remove('freeTax8821ContractStatus')
        this.props.history.push(routes.HOME)
        this.props.toggleFreeTaxRedirected(false, {
            freeTaxChangePasswordStatus: null,
            freeTax8821ContractStatus: null,
            freeTaxNumberVerifiedStatus: null
        })
    }


    render() {
        let step = null
        let parsedPhoneNumber = null
        let phone = null
        let country_code = null
        if (this.props.user) {
            parsedPhoneNumber = parsePhoneNumberFromString(this.props.user.phone)
            phone = this.props.user.phone
            country_code = this.props.history.location.state && this.props.history.location.state.country_code ? this.props.history.location.state.country_code : "+1"
        }

        let isOTPSent = storage.get('isOTPSent', null)
        if (parsedPhoneNumber) {
            phone = parsedPhoneNumber.nationalNumber
            country_code = parsedPhoneNumber.countryCallingCode
        }
        if ((this.props.isLoading || this.state.isNextStepLoading || !this.props.user) && !this.props.isOTPLoading && !this.state.isDocSigning) {
            step = (
                <Oux>
                    <div class="clearfix"></div>
                    <div className="billing_sc notifications">
                        <div className="empty_details notifications">
                            <Spinner />
                        </div>
                    </div>
                </Oux>
            )
        }
        else if (!this.state.isNextStepLoading && ((this.props.freeTaxChangePasswordStatus && this.props.freeTaxChangePasswordStatus.toLowerCase() !== 'no') || !this.props.freeTaxChangePasswordStatus)) {
            step = (
                <FreeTaxChangePassword
                    history={this.props.history}
                    getUser={this.props.getUser}
                    id={this.props.id}
                    setChangePasswordStatus={this.props.setChangePasswordStatus}
                    toggleFreeTaxRedirected={this.props.toggleFreeTaxRedirected}
                    idToken={this.props.idToken}
                    toggleStepLoader={this.toggleStepLoader}
                    logoutUser={this.props.logoutUser}
                    refresh_token={this.props.refresh_token}
                    sendOTP={this.props.sendOTP}
                    refreshToken={this.props.refreshToken}
                    isLoading={this.props.isLoading}
                    resetPassword={this.props.resetPassword} />
            )
        }
        else if (!this.state.isNextStepLoading && !isOTPSent && ((this.props.freeTaxNumberVerifiedStatus && this.props.freeTaxNumberVerifiedStatus.toLowerCase() !== 'no') || !this.props.freeTaxNumberVerifiedStatus)) {
            step = (
                <PhoneVerification
                    history={this.props.history}
                    getUser={this.props.getUser}
                    user={this.props.user}
                    id={this.props.id}
                    setPhoneVerifiedStatus={this.props.setPhoneVerifiedStatus}
                    toggleFreeTaxRedirected={this.props.toggleFreeTaxRedirected}
                    idToken={this.props.idToken}
                    apiError={this.props.error}
                    mobile_phone_number={phone}
                    toggleStepLoader={this.toggleStepLoader}
                    country_dial_code={country_code}
                    onResendOTP={this.props.onResendOTP}
                    isFreeTaxRedirected={this.props.isFreeTaxRedirected}
                    isLoading={this.props.isLoading}
                    sendOTP={this.props.sendOTP}
                    submitOTP={this.props.submitOTP} />
            )
        } else if (!this.state.isNextStepLoading && ((this.props.freeTaxNumberVerifiedStatus && this.props.freeTaxNumberVerifiedStatus.toLowerCase() !== 'no') || !this.props.freeTaxNumberVerifiedStatus)) {
            step = (
                <OTPVerification
                    history={this.props.history}
                    getUser={this.props.getUser}
                    user={this.props.user}
                    id={this.props.id}
                    setPhoneVerifiedStatus={this.props.setPhoneVerifiedStatus}
                    toggleFreeTaxRedirected={this.props.toggleFreeTaxRedirected}
                    idToken={this.props.idToken}
                    toggleStepLoader={this.toggleStepLoader}
                    apiError={this.props.error}
                    mobile_phone_number={phone}
                    country_dial_code={country_code}
                    onResendOTP={this.props.onResendOTP}
                    isFreeTaxRedirected={this.props.isFreeTaxRedirected}
                    isLoading={this.props.isLoading}
                    sendOTP={this.props.sendOTP}
                    submitOTP={this.props.submitOTP} />
            )
        }
        else {
            step = (
                <Form8821Contract
                    history={this.props.history}
                    getUser={this.props.getUser}
                    user={this.props.user}
                    toggleStepLoader={this.toggleStepLoader}
                    id={this.props.id}
                    setPhoneVerifiedStatus={this.props.setPhoneVerifiedStatus}
                    toggleFreeTaxRedirected={this.props.toggleFreeTaxRedirected}
                    idToken={this.props.idToken}
                    apiError={this.props.error}
                    navigateUserToHome={this.navigateUserToHome}
                    contractSigned={this.props.freeTax8821ContractStatus && this.props.freeTax8821ContractStatus.toLowerCase() === 'no'}
                    signDocument={this.signDocument}
                    onResendOTP={this.props.onResendOTP}
                    logoutUser={this.props.logoutUser}
                    isFreeTaxRedirected={this.props.isFreeTaxRedirected}
                    isLoading={this.props.isLoading || this.state.isDocSigning}
                    sendOTP={this.props.sendOTP}
                    submitOTP={this.props.submitOTP} />
            )
        }
        return step
    }
}

const mapState = state => {
    return {
        isLoading: state.authReducer.isloading,
        error: state.authReducer.error,
        idToken: state.authReducer.idToken,
        refresh_token: state.authReducer.refresh_token,
        id: state.authReducer.id,
        user: state.authReducer.user,
        caseid: state.authReducer.case_id,
        freeTaxChangePasswordStatus: state.authReducer.freeTaxChangePasswordStatus,
        freeTax8821ContractStatus: state.authReducer.freeTax8821ContractStatus,
        freeTaxNumberVerifiedStatus: state.authReducer.freeTaxNumberVerifiedStatus,
        isFreeTaxRedirected: state.authReducer.isFreeTaxRedirected,
        isOTPLoading: state.authReducer.isOTPLoading,
    }
}

const mapDispatch = dispatch => {
    return {
        resetPassword: (credentials) => dispatch(actionTypes.resetPassword(credentials)),
        toggleFreeTaxRedirected: (flag, status) => dispatch(actionTypes.toggleFreeTaxRedirected(flag, status)),
        setChangePasswordStatus: (request) => dispatch(actionTypes.getChangePasswordStatus(request)),
        setPhoneVerifiedStatus: (request) => dispatch(actionTypes.getPhoneVerifiedStatus(request)),
        form8821Status: (request) => dispatch(actionTypes.form8821Status(request)),
        getUser: (credentials) => dispatch(actionTypes.getUser(credentials)),
        sendOTP: (credentials, values) => dispatch(actionTypes.sendOTP(credentials, values)),
        submitOTP: (credentials, values) => dispatch(actionTypes.submitOTP(credentials, values)),
        onResendOTP: (credentials, values) => dispatch(actionTypes.sendOTP(credentials, values)),
        form8821EnvelopeId: (request) => dispatch(actionTypes.form8821EnvelopeId(request)),
        logoutUser: (credentials) => dispatch(actionTypes.logout(credentials)),
        refreshToken: (request) => dispatch(actionTypes.refreshToken(request))
    }
}

export default connect(mapState, mapDispatch)(FreeTax)