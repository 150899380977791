import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../hoc/Layout/Layout';
import { routes } from '../utility/constants/constants';
import Home from '../container/Home/Home';
import Login from '../container/Login/Login'
import Register from '../container/Register/Register'
import Documents from '../container/Documents/Documents'
import Billing from '../container/Billing/Billing'
import Contactus from '../container/Contactus/Contactus'
import Notifications from '../container/Notifications/Notifications'
import MyAccount from '../container/MyAccount/MyAccount';
import ServiceDetail from '../container/ServiceDetail/ServiceDetail';
import ForgotPassword from '../components/LoginForm/ForgotPassword/ForgotPassword'
import PrivacyPolicy from '../container/PrivacyPolicy/PrivacyPolicy'
import TermsAndConditions from '../container/TermsAndConditions/TermsAndConditions';
import storage from '../utility/storage';
import FreeTax from '../container/FreeTax/FreeTax';
import PhoneVerification from '../components/RegisterForm/PhoneVerification/PhoneVerification';
import OTPVerification from '../components/RegisterForm/PhoneVerification/OTPVerification';



const Router = (props) => {
    let routeList = null;
    let user = props.user;
    // debugger
    if (props.isLoggedIn) {
        //when user is logged in
        if (props.isFreeTaxRedirected) {
            routeList = (
                <Switch>
                    <Route exact path={routes.ROOT} render={(props) => <Redirect to={routes.HOME} />} />
                    <Route exact path={routes.HOME} component={FreeTax} />
                    <Route path='*' render={() => <Redirect to={routes.HOME} />} />
                </Switch>
            )
        }
        else {
            routeList = (
                <Switch>
                    <Route exact path={routes.ROOT} render={(props) => <Redirect to={routes.HOME} />} />
                    <Route exact path={routes.HOME} component={Home} />
                    <Route exact path={routes.DOCUMENTS} component={Documents} />
                    <Route exact path={routes.NOTIFICATIONS} component={Notifications} />
                    <Route exact path={routes.BILLING} component={Billing} />
                    <Route exact path={routes.CONTACT_US} component={Contactus} />
                    <Route exact path={routes.MYACCOUNT} component={MyAccount} />
                    <Route exact path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
                    <Route exact path={routes.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
                    <Route exact path={routes.SERVICE_DETAIL} component={ServiceDetail} />
                    <Route path='*' render={() => <Redirect to={routes.HOME} />} />
                </Switch>
            )
        }
    } else {
        //when user is not logged in
        routeList = (
            <Switch>
                <Route exact path={routes.ROOT} component={Login} />
                <Route exact path={routes.LOGIN} component={Login} />
                <Route exact path={routes.REGISTER} component={Register} />
                <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route exact path={routes.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route exact path={routes.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
                <Route path='*' render={(props) => <Redirect to={routes.Login} />} />
            </Switch>
        )
    }

    return (
        <Layout>
            {routeList}
        </Layout >
    )
};

export default Router;

export const NotFound = () => {
    return (
        <h1 className="text-center" style={{ margin: '100px' }}>404. Page not found.</h1>
    );
};

