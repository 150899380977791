import { ServiceActionType, AuthActionTypes } from '../../actions/usersActions/actionType';

const initialState = {
    availableServices: [],
    myServices: [],
    isMyServicesLoaded: false,
    isAvailableServicesLoaded: false,
    isLoading: false,
    error: null,
}

const updateObject = (oldState, updatedProps) => {
    return {
        ...oldState,
        ...updatedProps
    }
}

export const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ServiceActionType.FETCH_ALLSERVICE_PENDING:
            return updateObject(state,
                {
                    error: null,
                    isAvailableServicesLoaded: false
                });
        case ServiceActionType.FETCH_MYSERVICE_PENDING:
            return updateObject(state,
                {
                    error: null,
                    isMyServicesLoaded: false
                });
        case ServiceActionType.FETCH_ALLSERVICE_FULFILLED:
            if (action.payload && action.payload.error) {
                return updateObject(state,
                    {
                        error: action.payload.error,
                        isAvailableServicesLoaded: true,
                    });
            }
            return updateObject(state,
                {
                    availableServices: action.payload,
                    isAvailableServicesLoaded: true,
                });
        case ServiceActionType.FETCH_MYSERVICE_FULFILLED:
            if (action.payload.error) {
                return updateObject(state,
                    {
                        error: action.payload.error,
                        isMyServicesLoaded: true
                    });
            }
            return updateObject(state,
                {
                    myServices: action.payload,
                    isMyServicesLoaded: true
                });

        case ServiceActionType.SERVICE_RESET_DATA:
            return updateObject(state, {
                availableServices: [],
                myServices: [],
                isMyServicesLoaded: false,
                isAvailableServicesLoaded: false,
                isLoading: false,
                error: null,
            });
        default: return state;
    }
}