import axios, { API_VERSION } from '../config';

export const servicesApi = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileServices.php', formData, {
        headers: {
            'Accept': 'application/json'
        },
    });
};


export const servicesRequestApi = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileServicesRequest.php', formData, {
        headers: {
            'Accept': 'application/json'
        },
    });
};


export const fetchDocuSignURL = (request) => {
    const formData = new FormData()
    for (let key in request) {
        formData.append(key, request[key])
    }
    return axios.post(API_VERSION() + 'CTaxMobileDocuSign.php', formData, {
        headers: {
            'Accept': 'application/json'
        },
    });
};
