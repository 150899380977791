import React from 'react'

const awesomeTools = props => {
    return (
        <div class={props.show ? "tab-pane fade show active" : "tab-pane fade"} id="pills-awesome-toolse" role="tabpanel" aria-labelledby="pills-awesome-tools-tab">
            <div class="services_list_block">
                {/* <h3>Get Personal Finance Help</h3> */}
                {/* <div class="row row_m8">
                    <div class="col-xl-4 col-lg-6 col-sm-6 col_p8 mx-auto">
                        <div class="card_services_link">
                            <div class="card services_card other_card finance_card">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item"><a href="#">Taxes</a></li>
                                    <li class="list-group-item"><a href="#">Insurance </a></li>
                                    <li class="list-group-item"><a href="#">Credit</a></li>
                                    <li class="list-group-item"><a href="#">Loans + Finance</a></li>
                                    <li class="list-group-item"><a href="#">Debt Relief</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div class="empty_details notifications">
                    <div class="card">
                        <div class="card-body">
                            <div className="empty_img">
                                <img src="/images/customs/coming_soon.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default awesomeTools