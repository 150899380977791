import React from 'react'
import { Form, Field, Formik } from 'formik'
import { routes } from '../../utility/constants/constants';
import { withRouter } from 'react-router'
import './LoginForm.css'
import Spinner from '../UI/Spinner/Spinner';
import { loginValidateForm } from '../FormValidation/FormValidation'
import $ from 'jquery'
import Oux from '../../hoc/Oux/Oux';
import base64 from 'base-64'

const loginForm = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid',
    }

    const eyeErrorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        background: 'white'
    }

    const routetoSignup = () => {
        props.history.push(routes.REGISTER);
    }
    let initialValues = null
    if (props.history.location.search) {
        let userEmail = base64.decode(new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('email'))
        let userPassword = base64.decode(new URLSearchParams(props.history.location.search, { ignoreQueryPrefix: true }).get('password'))
        initialValues = {
            email: userEmail,
            password: userPassword
        }
    }

    return (
        <Oux>
            <div className="container-login100">
                <div className="wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
                    <Formik
                        initialValues={initialValues ? initialValues : {}}
                        onSubmit={(values) => props.loginUser({ email: values.email, password: values.password, type: 'SignIn' })}
                        validationSchema={loginValidateForm} >
                        {(formik_props) => {
                            const errors = formik_props.errors;
                            const touched = formik_props.touched;
                            return (
                                <div className="theme_form_outer">
                                    <h1>Log In</h1>
                                    <center><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.apiError ? (props.apiError.message === 'EMAIL_NOT_FOUND' || props.apiError.message === 'INVALID_EMAIL') ? 'Invalid Email Please Check!' : null : null}</span></center>
                                    <center><span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{props.apiError ? props.apiError.message === 'INVALID_PASSWORD' ? 'Invalid Password Please Check!' : null : null}</span></center>
                                    <Form className="theme_form">
                                        <div className="form-group">
                                            <Field type="email" style={errors.email && touched.email ? errorStyle : null} name="email" className="form-control theme_input" placeholder="Email Address" />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.email && touched.email && errors.email}</span>
                                        </div>
                                        <div className="form-group">
                                            {/* <Field type="password" style={errors.password && touched.password ? errorStyle : null} name="password" className="form-control theme_input" placeholder="Password" />
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.password && touched.password && errors.password}</span> */}
                                            <div className="input-group cst_input_group">
                                                <Field
                                                    type='password'
                                                    name="password"
                                                    className="form-control input_modifier"
                                                    placeholder="Password"
                                                    id="password"
                                                    style={errors.password && touched.password ? { ...errorStyle, borderRight: 'none' } : { borderRight: 'none' }} />
                                                <div className="input-group-append login-form-eye-icon">
                                                    <span style={errors.password && touched.password ? eyeErrorStyle : { borderLeft: 'none', background: 'white' }} className="input-group-text" id="basic-addon2">
                                                        <i
                                                            id="password-input"
                                                            style={{ cursor: 'pointer', color: '#1E7995' }}
                                                            className='fa fa-eye-slash'
                                                            onClick={(event) => {
                                                                if ($('#password').attr('type') === 'password') {
                                                                    $('#password').attr('type', 'text');
                                                                    $('#password-input').addClass('fa-eye').removeClass('fa-eye-slash');
                                                                }
                                                                else {
                                                                    $('#password').attr('type', 'password');
                                                                    $('#password-input').addClass('fa-eye-slash').removeClass('fa-eye');
                                                                }
                                                            }}></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.password && touched.password && errors.password}</span>
                                        </div>
                                        <a style={{ textDecoration: 'underline' }} onClick={() => props.history.push(routes.FORGOT_PASSWORD)} href="javascript:void(0)" className="theme_link form-submit-btn">I forgot my password</a>
                                        <br />
                                        <br />
                                        {props.isLoading
                                            ? <Spinner width='25px' height='25px' margin='0 auto' />
                                            : <button type="submit" id="login_submit_button" disabled={!formik_props.dirty || !formik_props.isValid} className="btn btn-primary btn-block btn_submit globel_btn">Log In</button>}
                                        <div className="form_bottom_info text-center">
                                            Need to create an account? <a onClick={routetoSignup} className="theme_link form-submit-btn">Sign Up</a>
                                        </div>
                                        {/* <div className="form_bottom_info text-center">
                                            Having Trouble Logging In? <a onClick={() => props.history.push(routes.FORGOT_PASSWORD)} href="javascript:void(0)" className="theme_link form-submit-btn">Forgot Password</a>
                                        </div> */}
                                    </Form>
                                </div>
                            )
                        }}
                    </Formik>
                </div>
                <div class="clearfix"></div>
                <div className="login100-more">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                            <div className="logo_img">
                                <img src="images/icons/logo_lg.svg" alt="FinancePal" />
                            </div>
                            <div className="cst_checkbox_block">
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Easy and intuitive to use</span>
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Save time and money</span>
                                <span><img src="images/icons/icn_tic.svg" className="mr-2" alt="" />Helpful tools to manage your taxes and finances</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Oux>
    )
}

export default withRouter(loginForm)