import React from 'react';
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actions/index'
import MyAccountMenu from '../../components/MyAccount/MyAccount';
import ChangePassword from '../../components/MyAccount/ChangePassword/ChangePassword';
import { routes } from '../../utility/constants/constants';
import BillingInformation from '../../components/MyAccount/BillingInformation/BillingInformation';
import BusinessInformation from '../../components/MyAccount/BusinessInformation/BusinessInformation';
import PersonalInformation from '../../components/MyAccount/PersonalInformation/PersonalInformation';

class MyAccount extends React.Component {

    state = {
        isLoading: false
    }

    componentDidMount() {
        let baseUrl = `${this.props.history.location.pathname}`
        let hashUrl = `${this.props.history.location.hash}`
        let currentUrl = baseUrl + hashUrl
        if (currentUrl === routes.MYACCOUNT) {
            this.props.history.push(routes.MYACCOUNT + routes.PERSONAL_INFORMATION)
        }
    }

    render() {
        let step = null;
        let baseUrl = `${this.props.history.location.pathname}`
        let hashUrl = `${this.props.history.location.hash}`
        let currentUrl = baseUrl + hashUrl
        
        console.log(this.props.currentUrl)
        if (currentUrl === baseUrl + routes.CHANGE_PASSWORD) {
            step = <ChangePassword
                apiError={this.props.error}
                user={this.props.user}
                isLoading={this.props.isLoading}
                loginUser={this.props.loginUser} />
        }
        else if (currentUrl === baseUrl + routes.BILLING_INFORMATION) {
            step = <BillingInformation
                caseid={this.props.user.caseid}
                saveUserBillingDetails={this.props.saveUserBillingDetails}
                userBillingDetails={this.props.userBillingDetails}
                isLoading={this.props.isLoading}
                isBillingApiError={this.props.isBillingApiError}
                isMyAccountLoading={this.props.isMyAccountLoading}
                getUserBillingDetails={this.props.getUserBillingDetails}
                isSubmitLoading={this.props.isSubmitLoading}
                getUserBillingDetails={this.props.getUserBillingDetails} />
        }
        else if (currentUrl === baseUrl + routes.BUSINESS_INFORMATION) {

            step = <BusinessInformation
                caseid={this.props.user.caseid}
                updateBusinessInfo={this.props.updateBusinessInfo}
                getUserBusiness={this.props.getUserBusiness}
                isLoading={this.props.isMyAccountLoading}
                deleteBusinessInfo={this.props.deleteBusinessInfo}
                isSubmitLoading={this.props.isSubmitLoading}
                businessUser={this.props.businessUser} />
        }
        else if (currentUrl === baseUrl + routes.PERSONAL_INFORMATION) {
            step = <PersonalInformation
                id={this.props.id}
                isLoading={this.props.isLoading}
                getUser={this.props.getUser}
                isPersonalInfoSubmitLoading={this.props.isMyAccountLoading}
                user={this.props.user}
                updatePersonalInfo={this.props.updatePersonalInfo} />
        }
        else {
            step = <Redirect to={baseUrl + routes.PERSONAL_INFORMATION} />
        }
        return (
            <main className="main_section">
                <div className="main_cont">
                    <div className="container-fluid fp_pl_0 fp_sub_sidenav_outer">
                        <MyAccountMenu historyProps={this.props.history} logoutUser={this.props.logoutUser} />
                        <div class="fp_right_content my_account_con">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div>{step}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    id: state.authReducer.id,
    isLoading: state.authReducer.isloading,
    isMyAccountLoading: state.myAccountReducer.isloading,
    isSubmitLoading: state.myAccountReducer.isSubmitButtonLoading,
    error: state.authReducer.error,
    businessUser: state.myAccountReducer.businessUser,
    userBillingDetails: state.myAccountReducer.userBillingDetails,
    isBillingApiError: state.myAccountReducer.isBillingApiError
});

const mapStateToDispatch = (dispatch) => ({
    logoutUser: (credentials) => dispatch(actionTypes.logout(credentials)),
    getUser: (credentials) => dispatch(actionTypes.getUser(credentials)),
    loginUser: (credentials, values) => dispatch(actionTypes.login(credentials, values)),
    updatePersonalInfo: (credentials) => dispatch(actionTypes.updatePersonalInfo(credentials)),
    getUserBusiness: (credentials) => dispatch(actionTypes.getUserBusiness(credentials)),
    updateBusinessInfo: (credentials) => dispatch(actionTypes.updateBusinessInfo(credentials)),
    getUserBillingDetails: (credentials) => dispatch(actionTypes.getUserBillingDetails(credentials)),
    saveUserBillingDetails: (credentials) => dispatch(actionTypes.saveUserBillingDetails(credentials)),
    deleteBusinessInfo: (credentials) => dispatch(actionTypes.deleteBusinessInfo(credentials))
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(MyAccount));