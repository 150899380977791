import React, { useEffect } from 'react'
import { Form, Formik, Field } from 'formik'
import { InputField } from '../../UI/FormInputFields/FormInputFields'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import './PersonalInformation.css'
import { validatePersonalInformation } from '../../FormValidation/FormValidation'
import Spinner from '../../UI/Spinner/Spinner'
import { dateFormat } from '../../../utility/constants/constants';

const PersonalInformation = props => {

    const errorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftWidth: '5px',
        borderLeftStyle: 'solid'
    }

    const selectErrorStyle = {
        borderColor: 'rgb(240, 77, 83)',
        borderLeftStyle: 'solid'
    }

    console.log(props.user, 'PersonalInfo')

    let birthdate = props.user && props.user.birthdate ? new Date(props.user.birthdate) : null

    useEffect(() => {
        // do something on route change
        // for my example, close a drawer
        console.log(props)
        props.getUser({ type: 'GetUser', id: props.id })
    }, [])
    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                props.user
                    ? {
                        first_name: props.user.first_name,
                        last_name: props.user.last_name,
                        primary_address_street: props.user.primary_address_street,
                        primary_address_city: props.user.primary_address_city,
                        primary_address_state: props.user.primary_address_state,
                        primary_address_postalcode: props.user.primary_address_postalcode,
                        phone_number: props.user.phone ? props.user.phone : props.user.phone_home,
                        email: props.user.email,
                        ssn: props.user.ssn,
                        birthdate: birthdate,
                        employment_status: props.user.occupation,
                        spouse_first_name: props.user.spouse_first_name,
                        spouse_last_name: props.user.spouse_last_name,
                        spouse_ssn: props.user.spouse_ssn,
                        spouse_birthdate: props.user.spouse_birthdate,
                        // spouse_work_phone: props.user.spouse_work_phone,
                        spouse_email: props.user.spouse_email,
                        spouse_primary_cell_phone: props.user.spouse_primary_cell_phone,
                        spouse_occupation: props.user.spouse_occupation,
                        spouse_address: props.user.spouse_primary_address_street,
                        spouse_city: props.user.spouse_primary_address_city,
                        spouse_state: props.user.spouse_primary_address_state,
                        spouse_zip_code: props.user.spouse_primary_address_postalcode,
                    }
                    : {}
            }
            onSubmit={(values) => {
                console.log(props.user.caseid)
                if (values.birthdate && values.birthdate !== props.user.birthdate) {
                    let formatted_date = null
                    formatted_date = dateFormat(values.birthdate, 'YYYY-MM-DD')
                    values = {
                        ...values,
                        birthdate: formatted_date
                    }
                }
                if (values.spouse_birthdate && values.spouse_birthdate !== props.user.spouse_birthdate) {
                    let formatted_date = null
                    formatted_date = dateFormat(values.spouse_birthdate, 'YYYY-MM-DD')
                    values = {
                        ...values,
                        spouse_birthdate: formatted_date
                    }
                }
                props.updatePersonalInfo({ ...values, type: 'UpdateUser', caseid: props.user.caseid, id: props.id })
            }}
            validationSchema={validatePersonalInformation}>
            {(formik_props) => {
                console.log(formik_props.errors)
                let errors = formik_props.errors
                let touched = formik_props.touched
                console.log(formik_props.values)
                return (
                    <div class="tab-pane fade show active personal_info_block" id="v-pills-Personal-Information"
                        role="tabpanel" aria-labelledby="v-pills-Personal-Information-tab">
                        <Form>
                            {props.isLoading ? <Spinner spinnerStyle={{ width: '50px', height: '50px', top: '50%', left: '60%', position: 'absolute' }} /> : null}
                            {/* <h1 class="fp_sec_title">Personal Information</h1> */}
                            <div style={props.isLoading ? { filter: 'blur(5px)' } : null} class="fb_tabs_inner fp_tabs_cont contact_us business_information doc_tab_content">
                                <div class="fp_tabs_inner_40 fp_tabs_cell">
                                    <div class="fp_invs_inner_block mt-0">
                                        <h1 class="fp_sec_title">Personal Details</h1>
                                    </div>
                                    <div class="personal_info">
                                        {/* <h6 class="h6_title">Personal Details</h6>
                                        <div class="upload_image">
                                            <div class="outer_circle">
                                                <div class="inner_circle">
                                                    <a href="javascript:void(0)">
                                                        <img src="images/icons/icn_upload_gray.png" alt="Image" class="mb-2" />
                                                        <span class="circle_title">Drag and drop<br /> photo here</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="form_info">
                                            {/* <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{formik_props.errors.upload_photo && formik_props.touched.upload_photo && formik_props.errors.upload_photo}</span>
                                            <div class="input-group mb-3 cst_input_group">
                                                <Field
                                                    name="upload_photo"
                                                    type="text"
                                                    class="form-control input_modifier"
                                                    placeholder="Upload Photo"
                                                    style={formik_props.errors.upload_photo && formik_props.touched.upload_photo ? errorStyle : null} />
                                                <div class="input-group-append">
                                                    <span style={formik_props.errors.upload_photo && formik_props.touched.upload_photo ? selectErrorStyle : null} class="input-group-text" id="basic-addon2">Select</span>
                                                </div>
                                            </div> */}
                                            <div class="form-group">
                                                <InputField form_class_name="personal-information-form" name="first_name" label="First Name" type="text" />
                                                {/* <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.first_name && touched.first_name && errors.first_name}</span> */}
                                            </div>
                                            <div class="form-group">
                                                <InputField form_class_name="personal-information-form" name="last_name" label="Last Name" type="text" />
                                                {/* <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.last_name && touched.last_name && errors.last_name}</span> */}
                                            </div>
                                            <div class="form-group">
                                                <InputField form_class_name="personal-information-form" name="primary_address_street" label="Address" type="text" />
                                            </div>
                                            {/* <div class="form-group">
                                                <InputField form_class_name="personal-information-form" name="address_line_2" label="Address Line 2" type="text" />
                                            </div> */}
                                            <div class="form-group">
                                                <InputField form_class_name="personal-information-form" name="primary_address_city" label="City" type="text" />
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <InputField form_class_name="personal-information-form" name="primary_address_state" label="State" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <InputField form_class_name="personal-information-form" name="primary_address_postalcode" label="Zip Code" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <InputField disabled={true} form_class_name="personal-information-form" name="phone_number" label="Phone Number" type="text" />
                                            </div>
                                            <div class="form-group">
                                                <InputField disabled={true} form_class_name="personal-information-form" name="email" label="Email Address" type="text" />
                                            </div>
                                            <div class="form-group">
                                                <InputField form_class_name="personal-information-form" name="ssn" label="Social Security Number (SSN)" type="text" />
                                            </div>
                                            <div class="form-group" style={errors.birthdate && touched.birthdate ? errorStyle : null}>
                                                {/* <InputField form_class_name="personal-information-form" name="date_of_birth" label="Date Of Birth" type="text" /> */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        autoOk
                                                        variant="inline"
                                                        name="birthdate"
                                                        inputVariant="outlined"
                                                        label="Date Of Birth"
                                                        id="custom_datepicker"
                                                        className="form-control theme_input personal-information-form"
                                                        value={formik_props.values.birthdate}
                                                        format="MM/dd/yyyy"
                                                        maxDate={new Date()}
                                                        InputAdornmentProps={{ position: "start" }}
                                                        onChange={date => {
                                                            formik_props.setFieldValue("birthdate", date)
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        {/* <span style={{ color: 'rgb(221, 39, 38)', fontSize: '13px' }}>{errors.birthdate && touched.birthdate && errors.birthdate}</span> */}
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="fp_tabs_inner_60 fp_tabs_cell">
                                    <div class="fp_inner_user_pro border-0">
                                        <div class="fp_media_user_pro_block" style={{ marginTop: '0px' }}>
                                            <h1 class="fp_sec_title">Spouse Details</h1>
                                            <div class="form_info">
                                                <div class="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_first_name" label="First Name" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_last_name" label="Last Name" type="text" />
                                                </div>
                                                {/* <div className="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_street" label="Address" type="text" />
                                                </div>
                                                <div className="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_city" label="City" type="text" />
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <InputField form_class_name="personal-information-form" name="spouse_state" label="State" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <InputField form_class_name="personal-information-form" name="spouse_postalcode" label="Zip Code" type="text" />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div class="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_email" label="Spouse Email" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_primary_cell_phone" label="Spouse Phone" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_occupation" label="Spouse Occupation" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    <InputField form_class_name="personal-information-form" name="spouse_ssn" label="Social Security Number (SSN)" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    {/* <InputField form_class_name="personal-information-form" name="spouse_date_of_birth" label="Date Of Birth" type="text" /> */}
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk
                                                            variant="inline"
                                                            name="spouse_birthdate"
                                                            inputVariant="outlined"
                                                            label="Date Of Birth"
                                                            id="custom_datepicker"
                                                            maxDate={new Date()}
                                                            className="form-control theme_input personal-information-form"
                                                            value={formik_props.values.spouse_birthdate}
                                                            format="MM/dd/yyyy"
                                                            InputAdornmentProps={{ position: "start" }}
                                                            onChange={date => {
                                                                formik_props.setFieldValue("spouse_birthdate", date)
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div class="btn_block mt-4">
                                                    {props.isPersonalInfoSubmitLoading ? <Spinner width='25px' height='25px' margin='0 auto' /> : <button onClick={formik_props.handleSubmit} type="submit" class="btn btn-primary cst_btn btn-block">Save</button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>

                )
            }}
        </Formik>
    )
}

export default PersonalInformation