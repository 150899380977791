import React from 'react';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { APIType, routes, ServiceAction } from '../../utility/constants/constants';
import { servicesApi, servicesRequestApi } from '../../api/servicesAPI';
import * as action from '../../redux/actions';
import { toastMsg, serviceRoute } from '../../utility/utility';
import ServiceWrapper from '../Home/ServiceWrapper';
import ServiceSectionDetail from './ServiceSectionDetail';
import { formatServiceName } from '../../utility/utility';

class ServiceDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedService: null,
            serviceStatusData: null,
        }
    }

    componentDidMount = () => {
        if (this.props.myServices.length > 0) {
            this.validateRoute(this.props.history.location.pathname);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.myServices.length === 0 && this.props.myServices.length > 0) {
            this.validateRoute(this.props.history.location.pathname);
        }
    }

    // validateRoute()
    // send the path and get the service name
    // used on service detail

    validateRoute = (path) => {
        let pathComponents = path.split("/");
        let lastPath = pathComponents.splice(-1).toString();
        let serviceName = lastPath.split("_").join(" ");
        let service = this.props.myServices.filter(ele => ele.name.toLowerCase() === serviceName)

        if (service.length > 0) {
            this.setState((state, props) => ({
                ...state,
                selectedService: service[0]
            }));
            this.fetchServiceStatus(service[0]);
        } else {
            //invalid route
            this.props.history.push(routes.HOME);
        }
    }

    //end

    fetchServiceStatus = (service) => {
        let request = {
            type: APIType.OptServiceStatus,
            caseid: this.props.user.caseid,
            serviceid: service.serviceid
        }
        servicesApi(request).then(res => {
            console.log(res);
            this.setState({
                ...this.state,
                serviceStatusData: res.data
            });
        }).catch(e => {
            toastMsg(e.message, true);
        });
    }

    reloadService = (service) => {
        this.setState({
            ...this.state,
            selectedService: service,
            serviceStatusData: null
        });
        this.fetchServiceStatus(service);
    }

    //myServiceHandler - called on selection of service from the submenu
    myServiceHandler = (service) => {
        if (service.serviceid === this.state.selectedService.serviceid) {
            return;
        }
        console.log("my service:" + JSON.stringify(service));
        this.reloadService(service);
    }
    //end

    render() {
        let myserviceMenuContent = [];

        if (this.props.myServices && this.props.myServices.length > 0) {
            myserviceMenuContent = this.props.myServices.map((ele, index) => {

                return <NavLink to={serviceRoute(ele.name)}
                    onClick={() => {
                        document.getElementsByClassName("fp_sub_sidenav_mob")[0].classList.toggle("active")
                        this.myServiceHandler(ele)
                    }}
                    key={index} className="nav-link"
                    id={`v-pills-${formatServiceName(ele.name)}-tab`}
                >
                    <span className="nav_link_text">{ele.name}</span> <i className="fa fa-angle-right"></i>
                </NavLink>
            })
        }

        let serviceData = this.state.serviceStatusData && this.state.serviceStatusData
        return (
            <main className="main_section">
                <div className="main_cont">
                    <div className="container-fluid fp_pl_0 fp_sub_sidenav_outer">
                        <div className="fp_sub_sidenav fp_sub_sidenav_mob">
                            <h4 style={{ fontSize: '11px', marginLeft: '14px' }} className="fb_sm_title back-button">
                                <a href="javascript:void(0)" onClick={() => this.props.history.push(routes.HOME)}>
                                    <img className="hover-back-icon" src="/images/icons/icn_back-button_hover.svg" width="14px" />
                                    <img className="back-icon" src="/images/icons/icn_back-button.svg" width="14px" />
                                    &nbsp;Go Back To MY SERVICES
                                </a>
                            </h4>
                            <div className="nav flex-column nav-pills fp_sub_sidenav_vTabs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                {myserviceMenuContent}
                            </div>
                        </div>
                        <div className="fp_right_content">
                            <div className="tab-content" id="v-pills-tabContent">
                                <ServiceSectionDetail
                                    serviceData={serviceData}
                                    service={this.state.selectedService}
                                    reloadService={this.reloadService}
                                    {...this.state} />
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}


const mapStateToProps = (state) => ({
});

const mapStateToDispatch = (dispatch) => ({
});

export default connect(mapStateToProps, mapStateToDispatch)(ServiceWrapper(ServiceDetail));
