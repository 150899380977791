import * as accountActions from './actionType';
import * as API from '../../../api/myAccountApi';
import storage from '../../../utility/storage';
import { toastMsg } from '../../../utility/utility';
import { routes } from '../../../utility/constants/constants';
import store from '../../../redux/store/store';
import * as actionType from '../index'
import localStorage from '../../../utility/storage';

function getHistory() {
    const storeState = store.getState();
    const history = storeState.historyReducer.history;
    return history;
}

export const updatePersonalInfo = (credentials, values) => dispatch => dispatch({
    type: accountActions.UPDATE_PERSONAL_INFO,
    payload: API.updatePersonalInfo(credentials)
        .then(response => {
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                toastMsg("Personal Info Updated Successfully!")
                dispatch(actionType.getUser({type:'GetUser', id:storage.get("id", null)}))
            }
            else{
                // toastMsg("Error Updating Personal Info Please Try Again!")
            }
            console.log(response)
            return response.data
        }).catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const fetchBillingPaymentSchedule = (credentials) => dispatch => dispatch({
    type: accountActions.BillingActionTypes.FETCH_BILLING_SCHEDULE,
    payload: API.fetchBillingPaymentSchedule(credentials)
        .then(response=>{
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                // toastMsg("Billing Details Fetched Successfully")
            }
            else{
                // toastMsg("No Billing Data Found")
            }
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const createBillingPayment = (credentials) => dispatch => dispatch({
    type: accountActions.BillingActionTypes.BILLING_PAYMENT_CREATE,
    payload: API.createBillingPayment(credentials)
        .then(response=>{
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                toastMsg("Payment Done Successfully")
            }
            else{
                // toastMsg("Please Try Again")
            }
            return response.data
        }).catch(error => { 
            console.log(error)
            return error
        })
})

export const getUserBusiness = (credentials) => dispatch => dispatch({
    type: accountActions.BusinessActionTypes.GET_USER_BUSINESS,
    payload: API.getUserBusiness(credentials)
        .then(response=>{
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                // toastMsg("Bill Paid Successfully")
            }
            else{
                // toastMsg("Please Try Again")
            }
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const deleteBusinessInfo = (credentials) => dispatch => dispatch({
    type: accountActions.BusinessActionTypes.DELETE_USER_BUSINESS,
    payload: API.deleteBusinessInfo(credentials)
        .then(response=>{
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                dispatch(actionType.getUserBusiness({type:'GetUserBusiness', caseid:storage.get("case_id", null)}))
                toastMsg("Business Info Deleted Successfully")
            }
            else{
                // toastMsg("Error Deleting Business Info. Please Try Again!")
            }
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const updateBusinessInfo = (credentials, values) => dispatch => dispatch({
    type: accountActions.BusinessActionTypes.UPDATE_USER_BUSINESS,
    payload: API.updateBusinessInfo(credentials)
        .then(response => {
            console.log(response)
            dispatch(actionType.getUserBusiness({type:'GetUserBusiness', caseid:storage.get("case_id", null)}))
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                toastMsg("Business Info Updated Successfully!")
            }
            else{
                // toastMsg("Error Updating Business Info Please Try Again!")
            }
            return response.data
        }).catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const getUserBillingDetails = (credentials) => dispatch => dispatch({
    type: accountActions.BillingActionTypes.GET_USER_BILLING_DETAILS,
    payload: API.getBillingDetailsInfo(credentials)
        .then(response=>{
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                // toastMsg("Bill Paid Successfully")
            }
            else{
                // toastMsg("Billing Details Data Not Found")
            }
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})

export const saveUserBillingDetails = (credentials, values) => dispatch => dispatch({
    type: accountActions.BillingActionTypes.SAVE_USER_BILLING_DETAILS,
    payload: API.saveUserBillingDetails(credentials)
        .then(response => {
            console.log(response)
            dispatch(actionType.getUserBillingDetails({type:'CaseAccountGet', caseid:storage.get('case_id', null)}))
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                toastMsg("Billing Details Added Successfully!")
                return {...response.data, isBillingApiError: false}
            }
            else{
                // toastMsg("Error Adding Billing Info Please Try Again!")
                return {...response.data, isBillingApiError: true}
            }
        }).catch(error => {
            console.log(error);
            // errorHandler(error);
            return error;
        })
});

export const getUserNotifications = (credentials) => dispatch => dispatch({
    type: accountActions.NotificationActionTypes.GET_USER_NOTIFICATIONS,
    payload: API.getUserNotifications(credentials)
        .then(response=>{
            if(response.data.result === 'Success' || response.data.result === 'Success!')
            {
                // toastMsg("Bill Paid Successfully")
            }
            else{
                // toastMsg("Please Try Again")
            }
            return response.data
        }).catch(error => {
            console.log(error)
            return error
        })
})