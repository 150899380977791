import React from 'react';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import NotificationsPage from '../../components/Notifications/Notifications';
import * as actionTypes from '../../redux/actions/index'
import $ from 'jquery'
import { formatServiceName } from '../../utility/utility';

class Notifications extends React.Component {

    state = {
        selectedNotification: null,
        selectedNotificationIndex: null,
        showRightContent: false
    }

    setSelectedNotification = (notification, index) => {
        // let a = $(document).height() - $(".timeline-item").height()
        // $('html, body').animate({ scrollTop: 250 }, 1000, 'swing');
        // $('html, body').animate({
        //     scrollTop: $('#notifications_fp_inner_user_pro').offset().top - 20 //#DIV_ID is an example. Use the id of your destination on the page
        // }, 'slow');

        if (notification.serviceid && notification.servicename) {
            this.props.history.push(
                `/service/${formatServiceName(notification.servicename)}`
            );
            return;
        }

        window.scroll(0, 0);
        this.setState({
            selectedNotification: notification,
            showRightContent: true,
            selectedNotificationIndex: index
        })
    }

    closeRightContent = () => {
        this.setState({ showRightContent: false })
    }

    componentDidMount() {
        this.props.getUserNotifications({ type: 'AppNotification', caseid: this.props.user.caseid })
    }

    render() {
        return (
            <main className="main_section">
                <div className="main_cont">
                    <NotificationsPage
                        closeRightContent={this.closeRightContent}
                        showRightContent={this.state.showRightContent}
                        setSelectedNotification={this.setSelectedNotification}
                        selectedNotification={this.state.selectedNotification}
                        isLoading={this.props.isLoading}
                        selectedNotificationIndex={this.state.selectedNotificationIndex}
                        userNotifications={this.props.userNotifications} />
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user,
    userNotifications: state.myAccountReducer.userNotifications,
    isLoading: state.myAccountReducer.isloading
});

const mapStateToDispatch = (dispatch) => ({
    getUserNotifications: (credentials) => dispatch(actionTypes.getUserNotifications(credentials))
});

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Notifications));


